/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'skip-start': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 4a.5.5 0 011 0v3.248l6.267-3.636c.52-.302 1.233.043 1.233.696v7.384c0 .653-.713.998-1.233.696L5 8.752V12a.5.5 0 01-1 0zm7.5.633L5.696 8l5.804 3.367z"/>',
    },
});
