/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-angry-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 16A8 8 0 108 0a8 8 0 000 16M4.053 4.276a.5.5 0 01.67-.223l2 1a.5.5 0 01.166.76c.071.206.111.44.111.687C7 7.328 6.552 8 6 8s-1-.672-1-1.5c0-.408.109-.778.285-1.049l-1.009-.504a.5.5 0 01-.223-.67zm.232 8.157a.5.5 0 01-.183-.683A4.5 4.5 0 018 9.5a4.5 4.5 0 013.898 2.25.5.5 0 11-.866.5A3.5 3.5 0 008 10.5a3.5 3.5 0 00-3.032 1.75.5.5 0 01-.683.183M10 8c-.552 0-1-.672-1-1.5 0-.247.04-.48.11-.686a.502.502 0 01.166-.761l2-1a.5.5 0 11.448.894l-1.009.504c.176.27.285.64.285 1.049 0 .828-.448 1.5-1 1.5"/>',
    },
});
