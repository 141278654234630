/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'pc-display-horizontal': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1.5 0A1.5 1.5 0 000 1.5v7A1.5 1.5 0 001.5 10H6v1H1a1 1 0 00-1 1v3a1 1 0 001 1h14a1 1 0 001-1v-3a1 1 0 00-1-1h-5v-1h4.5A1.5 1.5 0 0016 8.5v-7A1.5 1.5 0 0014.5 0zm0 1h13a.5.5 0 01.5.5v7a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-7a.5.5 0 01.5-.5M12 12.5a.5.5 0 111 0 .5.5 0 01-1 0m2 0a.5.5 0 111 0 .5.5 0 01-1 0M1.5 12h5a.5.5 0 010 1h-5a.5.5 0 010-1M1 14.25a.25.25 0 01.25-.25h5.5a.25.25 0 110 .5h-5.5a.25.25 0 01-.25-.25"/>',
    },
});
