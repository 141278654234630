/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sign-no-left-turn-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 13.292A8 8 0 0113.293 2L9.195 6.099A2.5 2.5 0 008.5 6H7V4.534a.25.25 0 00-.41-.192L4.23 6.308a.25.25 0 000 .384l2.36 1.966.026.02zm.708.708A8 8 0 0014 2.707l-3.885 3.884C10.656 7.05 11 7.735 11 8.5V11h-1V8.5c0-.489-.234-.923-.596-1.197l-6.696 6.696z"/><path pid="1" d="M8.293 7L7 8.293V7z"/>',
    },
});
