/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'symmetry-vertical': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7 2.5a.5.5 0 00-.939-.24l-6 11A.5.5 0 00.5 14h6a.5.5 0 00.5-.5zm2.376-.484a.5.5 0 01.563.245l6 11A.5.5 0 0115.5 14h-6a.5.5 0 01-.5-.5v-11a.5.5 0 01.376-.484M10 4.46V13h4.658z"/>',
    },
});
