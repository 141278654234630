/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'plus-slash-minus': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1.854 14.854l13-13a.5.5 0 00-.708-.708l-13 13a.5.5 0 00.708.708M4 1a.5.5 0 01.5.5v2h2a.5.5 0 010 1h-2v2a.5.5 0 01-1 0v-2h-2a.5.5 0 010-1h2v-2A.5.5 0 014 1m5 11a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5A.5.5 0 019 12"/>',
    },
});
