/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    shadows: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 8A8 8 0 110 8a8 8 0 0116 0m-8 7a.5.5 0 010-1h3.5q.048 0 .093.009A7 7 0 0012.9 13H8a.5.5 0 010-1h5.745q.331-.474.581-1H8a.5.5 0 010-1h6.71a7 7 0 00.22-1H8a.5.5 0 010-1h7q0-.51-.07-1H8a.5.5 0 010-1h6.71a7 7 0 00-.384-1H8a.5.5 0 010-1h5.745a7 7 0 00-.846-1H8a.5.5 0 010-1h3.608A7 7 0 108 15"/>',
    },
});
