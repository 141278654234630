/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'heart-pulse-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1.475 9C2.702 10.84 4.779 12.871 8 15c3.221-2.129 5.298-4.16 6.525-6H12a.5.5 0 01-.464-.314l-1.457-3.642-1.598 5.593a.5.5 0 01-.945.049L5.889 6.568l-1.473 2.21A.5.5 0 014 9z"/><path pid="1" d="M.88 8C-2.427 1.68 4.41-2 7.823 1.143q.09.083.176.171a3 3 0 01.176-.17C11.59-2 18.426 1.68 15.12 8h-2.783l-1.874-4.686a.5.5 0 00-.945.049L7.921 8.956 6.464 5.314a.5.5 0 00-.88-.091L3.732 8z"/>',
    },
});
