/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    router: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.525 3.025a3.5 3.5 0 014.95 0 .5.5 0 10.707-.707 4.5 4.5 0 00-6.364 0 .5.5 0 00.707.707"/><path pid="1" d="M6.94 4.44a1.5 1.5 0 012.12 0 .5.5 0 00.708-.708 2.5 2.5 0 00-3.536 0 .5.5 0 00.707.707zM2.5 11a.5.5 0 110-1 .5.5 0 010 1m4.5-.5a.5.5 0 101 0 .5.5 0 00-1 0m2.5.5a.5.5 0 110-1 .5.5 0 010 1m1.5-.5a.5.5 0 101 0 .5.5 0 00-1 0m2 0a.5.5 0 101 0 .5.5 0 00-1 0"/><path pid="2" d="M2.974 2.342a.5.5 0 10-.948.316L3.806 8H1.5A1.5 1.5 0 000 9.5v2A1.5 1.5 0 001.5 13H2a.5.5 0 00.5.5h2A.5.5 0 005 13h6a.5.5 0 00.5.5h2a.5.5 0 00.5-.5h.5a1.5 1.5 0 001.5-1.5v-2A1.5 1.5 0 0014.5 8h-2.306l1.78-5.342a.5.5 0 10-.948-.316L11.14 8H4.86zM14.5 9a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5z"/><path pid="3" d="M8.5 5.5a.5.5 0 11-1 0 .5.5 0 011 0"/>',
    },
});
