/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-image-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 0h8a2 2 0 012 2v8.293l-2.73-2.73a1 1 0 00-1.52.127l-1.889 2.644-1.769-1.062a1 1 0 00-1.222.15L2 12.292V2a2 2 0 012-2m4.002 5.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0"/><path pid="1" d="M10.564 8.27L14 11.708V14a2 2 0 01-2 2H4a2 2 0 01-2-2v-.293l3.578-3.577 2.56 1.536 2.426-3.395z"/>',
    },
});
