/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'fuel-pump-diesel': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.5 2a.5.5 0 00-.5.5v5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5zM4 14V9h1.796q.75 0 1.237.293t.725.85Q8 10.7 8 11.487q0 .792-.242 1.355a1.8 1.8 0 01-.732.861Q6.54 14 5.796 14zm1.666-4.194h-.692v3.385h.692q.343 0 .595-.103a1 1 0 00.412-.315q.162-.213.241-.528.084-.314.083-.74 0-.565-.144-.94a1.1 1.1 0 00-.436-.569q-.293-.19-.75-.19z"/><path pid="1" d="M3 0a2 2 0 00-2 2v13H.5a.5.5 0 000 1h11a.5.5 0 000-1H11v-4a1 1 0 011 1v.5a1.5 1.5 0 003 0V8h.5a.5.5 0 00.5-.5V4.324c0-.616 0-1.426-.294-2.081a1.97 1.97 0 00-.794-.907Q14.345.999 13.5 1a.5.5 0 000 1c.436 0 .716.086.9.195a.97.97 0 01.394.458c.147.328.19.746.201 1.222H13.5a.5.5 0 00-.5.5V7.5a.5.5 0 00.5.5h.5v4.5a.5.5 0 01-1 0V12a2 2 0 00-2-2V2a2 2 0 00-2-2zm7 2v13H2V2a1 1 0 011-1h6a1 1 0 011 1"/>',
    },
});
