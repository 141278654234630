/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    ubuntu: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.273 9.53a2.273 2.273 0 100-4.546 2.273 2.273 0 000 4.547zm9.467-4.984a2.273 2.273 0 100-4.546 2.273 2.273 0 000 4.546M7.4 13.108a5.54 5.54 0 01-3.775-2.88 3.27 3.27 0 01-1.944.24 7.4 7.4 0 005.328 4.465c.53.113 1.072.169 1.614.166a3.25 3.25 0 01-.666-1.9 6 6 0 01-.557-.091m3.828 2.285a2.273 2.273 0 100-4.546 2.273 2.273 0 000 4.546m3.163-3.108a7.44 7.44 0 00.373-8.726 3.3 3.3 0 01-1.278 1.498 5.57 5.57 0 01-.183 5.535 3.26 3.26 0 011.088 1.693M2.098 3.998a3.3 3.3 0 011.897.486 5.54 5.54 0 014.464-2.388c.037-.67.277-1.313.69-1.843a7.47 7.47 0 00-7.051 3.745"/>',
    },
});
