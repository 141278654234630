/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'alarm-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6 .5a.5.5 0 01.5-.5h3a.5.5 0 010 1H9v1.07a7.001 7.001 0 013.274 12.474l.601.602a.5.5 0 01-.707.708l-.746-.746A6.97 6.97 0 018 16a6.97 6.97 0 01-3.422-.892l-.746.746a.5.5 0 01-.707-.708l.602-.602A7.001 7.001 0 017 2.07V1h-.5A.5.5 0 016 .5m2.5 5a.5.5 0 00-1 0v3.362l-1.429 2.38a.5.5 0 10.858.515l1.5-2.5A.5.5 0 008.5 9zM.86 5.387A2.5 2.5 0 114.387 1.86 8.04 8.04 0 00.86 5.387M11.613 1.86a2.5 2.5 0 113.527 3.527 8.04 8.04 0 00-3.527-3.527"/>',
    },
});
