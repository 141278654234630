/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-vcard-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 4a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2zm9 1.5a.5.5 0 00.5.5h4a.5.5 0 000-1h-4a.5.5 0 00-.5.5M9 8a.5.5 0 00.5.5h4a.5.5 0 000-1h-4A.5.5 0 009 8m1 2.5a.5.5 0 00.5.5h3a.5.5 0 000-1h-3a.5.5 0 00-.5.5m-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 002 13h6.96q.04-.245.04-.5M7 6a2 2 0 10-4 0 2 2 0 004 0"/>',
    },
});
