/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-raw': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2v-1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM1.597 11.85H0v3.999h.782v-1.491h.71l.7 1.491h1.651l.313-1.028h1.336l.314 1.028h.84L5.31 11.85h-.925l-1.329 3.96-.783-1.572A1.18 1.18 0 003 13.116q0-.384-.167-.668a1.1 1.1 0 00-.478-.44 1.7 1.7 0 00-.758-.158m-.815 1.913v-1.292h.7a.74.74 0 01.507.17q.194.17.194.49 0 .315-.194.474-.19.158-.518.158zm4.063-1.148l.489 1.617H4.32l.49-1.617zm4.006.445l-.74 2.789h-.73L6.326 11.85h.855l.601 2.903h.038l.706-2.903h.683l.706 2.903h.04l.596-2.903h.858l-1.055 3.999h-.73l-.74-2.789H8.85z"/>',
    },
});
