/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'music-player-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 12a1 1 0 100-2 1 1 0 000 2"/><path pid="1" d="M4 0a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2zm1 2h6a1 1 0 011 1v2.5a1 1 0 01-1 1H5a1 1 0 01-1-1V3a1 1 0 011-1m3 12a3 3 0 110-6 3 3 0 010 6"/>',
    },
});
