/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'window-dash': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.5 5a.5.5 0 100-1 .5.5 0 000 1M4 5a.5.5 0 100-1 .5.5 0 000 1m2-.5a.5.5 0 11-1 0 .5.5 0 011 0"/><path pid="1" d="M0 4a2 2 0 012-2h11a2 2 0 012 2v4a.5.5 0 01-1 0V7H1v5a1 1 0 001 1h5.5a.5.5 0 010 1H2a2 2 0 01-2-2zm1 2h13V4a1 1 0 00-1-1H2a1 1 0 00-1 1z"/><path pid="2" d="M16 12.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0m-5.5 0a.5.5 0 00.5.5h3a.5.5 0 000-1h-3a.5.5 0 00-.5.5"/>',
    },
});
