/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'taxi-front': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4.862 5.276L3.906 7.19a.51.51 0 00.497.731c.91-.073 2.35-.17 3.597-.17s2.688.097 3.597.17a.51.51 0 00.497-.731l-.956-1.913A.5.5 0 0010.691 5H5.309a.5.5 0 00-.447.276M4 10a1 1 0 11-2 0 1 1 0 012 0m10 0a1 1 0 11-2 0 1 1 0 012 0m-9 0a1 1 0 011-1h4a1 1 0 110 2H6a1 1 0 01-1-1"/><path pid="1" d="M6 1a1 1 0 00-1 1v1h-.181A2.5 2.5 0 002.52 4.515l-.792 1.848a.8.8 0 01-.38.404c-.5.25-.855.715-.965 1.262L.05 9.708a2.5 2.5 0 00-.049.49v.413c0 .814.39 1.543 1 1.997V14.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1.338c1.292.048 2.745.088 4 .088s2.708-.04 4-.088V14.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1.892c.61-.454 1-1.183 1-1.997v-.413q0-.248-.049-.49l-.335-1.68a1.8 1.8 0 00-.964-1.261.8.8 0 01-.381-.404l-.792-1.848A2.5 2.5 0 0011.181 3H11V2a1 1 0 00-1-1zM4.819 4h6.362a1.5 1.5 0 011.379.91l.792 1.847a1.8 1.8 0 00.853.904c.222.112.381.32.43.564l.336 1.679q.03.146.029.294v.413a1.48 1.48 0 01-1.408 1.484c-1.555.07-3.786.155-5.592.155s-4.037-.084-5.592-.155A1.48 1.48 0 011 10.611v-.413q0-.148.03-.294l.335-1.68a.8.8 0 01.43-.563c.383-.19.685-.511.853-.904l.792-1.848A1.5 1.5 0 014.82 4z"/>',
    },
});
