/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'nvme-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6 7H5v2h1zm6 0H9v2h3z"/><path pid="1" d="M2 4a.5.5 0 00-.5.5h-1A.5.5 0 000 5v1a.5.5 0 00.5.5h1a.25.25 0 010 .5h-1a.5.5 0 00-.5.5V11a.5.5 0 00.5.5h1a.5.5 0 00.5.5h13.5a.5.5 0 00.5-.5V9a.5.5 0 00-.5-.5.5.5 0 010-1A.5.5 0 0016 7V4.5a.5.5 0 00-.5-.5zm2 2.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zm4 0a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5z"/>',
    },
});
