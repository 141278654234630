/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'house-add': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.707 1.5a1 1 0 00-1.414 0L.646 8.146a.5.5 0 00.708.708L2 8.207V13.5A1.5 1.5 0 003.5 15h4a.5.5 0 100-1h-4a.5.5 0 01-.5-.5V7.207l5-5 6.646 6.647a.5.5 0 00.708-.708L13 5.793V2.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v1.293z"/><path pid="1" d="M16 12.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0m-3.5-2a.5.5 0 00-.5.5v1h-1a.5.5 0 000 1h1v1a.5.5 0 101 0v-1h1a.5.5 0 100-1h-1v-1a.5.5 0 00-.5-.5"/>',
    },
});
