/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'ticket-detailed': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 5.5a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5m0 5a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5M5 7a1 1 0 000 2h6a1 1 0 100-2z"/><path pid="1" d="M0 4.5A1.5 1.5 0 011.5 3h13A1.5 1.5 0 0116 4.5V6a.5.5 0 01-.5.5 1.5 1.5 0 000 3 .5.5 0 01.5.5v1.5a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 010 11.5V10a.5.5 0 01.5-.5 1.5 1.5 0 100-3A.5.5 0 010 6zM1.5 4a.5.5 0 00-.5.5v1.05a2.5 2.5 0 010 4.9v1.05a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-1.05a2.5 2.5 0 010-4.9V4.5a.5.5 0 00-.5-.5z"/>',
    },
});
