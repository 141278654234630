/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cash-stack': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1 3a1 1 0 011-1h12a1 1 0 011 1zm7 8a2 2 0 100-4 2 2 0 000 4"/><path pid="1" d="M0 5a1 1 0 011-1h14a1 1 0 011 1v8a1 1 0 01-1 1H1a1 1 0 01-1-1zm3 0a2 2 0 01-2 2v4a2 2 0 012 2h10a2 2 0 012-2V7a2 2 0 01-2-2z"/>',
    },
});
