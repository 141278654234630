/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    regex: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M3.05 3.05a7 7 0 000 9.9.5.5 0 01-.707.707 8 8 0 010-11.314.5.5 0 11.707.707m9.9-.707a.5.5 0 01.707 0 8 8 0 010 11.314.5.5 0 01-.707-.707 7 7 0 000-9.9.5.5 0 010-.707M6 11a1 1 0 11-2 0 1 1 0 012 0m5-6.5a.5.5 0 00-1 0v2.117L8.257 5.57a.5.5 0 00-.514.858L9.528 7.5 7.743 8.571a.5.5 0 10.514.858L10 8.383V10.5a.5.5 0 101 0V8.383l1.743 1.046a.5.5 0 00.514-.858L11.472 7.5l1.785-1.071a.5.5 0 10-.514-.858L11 6.617z"/>',
    },
});
