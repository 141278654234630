/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'list-columns-reverse': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M0 .5A.5.5 0 01.5 0h2a.5.5 0 010 1h-2A.5.5 0 010 .5m4 0a.5.5 0 01.5-.5h10a.5.5 0 010 1h-10A.5.5 0 014 .5m-4 2A.5.5 0 01.5 2h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5m4 0a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5m-4 2A.5.5 0 01.5 4h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5m4 0a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5m-4 2A.5.5 0 01.5 6h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5m4 0a.5.5 0 01.5-.5h8a.5.5 0 010 1h-8a.5.5 0 01-.5-.5m-4 2A.5.5 0 01.5 8h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5m4 0a.5.5 0 01.5-.5h8a.5.5 0 010 1h-8a.5.5 0 01-.5-.5m-4 2a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5m4 0a.5.5 0 01.5-.5h10a.5.5 0 010 1h-10a.5.5 0 01-.5-.5m-4 2a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5m4 0a.5.5 0 01.5-.5h6a.5.5 0 010 1h-6a.5.5 0 01-.5-.5m-4 2a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5m4 0a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5"/>',
    },
});
