/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'eye-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10.5 8a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0"/><path pid="1" d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7"/>',
    },
});
