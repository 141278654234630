/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'caret-right': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6 12.796V3.204L11.481 8zm.659.753l5.48-4.796a1 1 0 000-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 001.659.753"/>',
    },
});
