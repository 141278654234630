/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    newspaper: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 2.5A1.5 1.5 0 011.5 1h11A1.5 1.5 0 0114 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 00.5-.5v-9a.5.5 0 011 0v9a1.5 1.5 0 01-1.5 1.5H1.497A1.497 1.497 0 010 13.5zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 00-.5-.5h-11a.5.5 0 00-.5.5v11c0 .278.223.5.497.5z"/><path pid="1" d="M2 3h10v2H2zm0 3h4v3H2zm0 4h4v1H2zm0 2h4v1H2zm5-6h2v1H7zm3 0h2v1h-2zM7 8h2v1H7zm3 0h2v1h-2zm-3 2h2v1H7zm3 0h2v1h-2zm-3 2h2v1H7zm3 0h2v1h-2z"/>',
    },
});
