/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sign-turn-right-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098zM9 8.466V7H7.5A1.5 1.5 0 006 8.5V11H5V8.5A2.5 2.5 0 017.5 6H9V4.534a.25.25 0 01.41-.192l2.36 1.966c.12.1.12.284 0 .384L9.41 8.658A.25.25 0 019 8.466"/>',
    },
});
