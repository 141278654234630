/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'life-preserver': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 16A8 8 0 108 0a8 8 0 000 16m6.43-5.228a7.03 7.03 0 01-3.658 3.658l-1.115-2.788a4 4 0 001.985-1.985zM5.228 14.43a7.03 7.03 0 01-3.658-3.658l2.788-1.115a4 4 0 001.985 1.985zm9.202-9.202l-2.788 1.115a4 4 0 00-1.985-1.985l1.115-2.788a7.03 7.03 0 013.658 3.658m-8.087-.87a4 4 0 00-1.985 1.985L1.57 5.228A7.03 7.03 0 015.228 1.57zM8 11a3 3 0 110-6 3 3 0 010 6"/>',
    },
});
