/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-code': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.646 5.646a.5.5 0 11.708.708L5.707 8l1.647 1.646a.5.5 0 01-.708.708l-2-2a.5.5 0 010-.708zm2.708 0a.5.5 0 10-.708.708L10.293 8 8.646 9.646a.5.5 0 00.708.708l2-2a.5.5 0 000-.708z"/><path pid="1" d="M2 2a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2zm10-1H4a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V2a1 1 0 00-1-1"/>',
    },
});
