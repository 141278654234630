/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'dice-1': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<circle pid="0" cx="8" cy="8" r="1.5"/><path pid="1" d="M13 1a2 2 0 012 2v10a2 2 0 01-2 2H3a2 2 0 01-2-2V3a2 2 0 012-2zM3 0a3 3 0 00-3 3v10a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3z"/>',
    },
});
