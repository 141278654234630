/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'chat-dots-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 8c0 3.866-3.582 7-8 7a9 9 0 01-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7M5 8a1 1 0 10-2 0 1 1 0 002 0m4 0a1 1 0 10-2 0 1 1 0 002 0m3 1a1 1 0 100-2 1 1 0 000 2"/>',
    },
});
