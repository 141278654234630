/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    bezier: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M0 10.5A1.5 1.5 0 011.5 9h1A1.5 1.5 0 014 10.5v1A1.5 1.5 0 012.5 13h-1A1.5 1.5 0 010 11.5zm1.5-.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm10.5.5A1.5 1.5 0 0113.5 9h1a1.5 1.5 0 011.5 1.5v1a1.5 1.5 0 01-1.5 1.5h-1a1.5 1.5 0 01-1.5-1.5zm1.5-.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM6 4.5A1.5 1.5 0 017.5 3h1A1.5 1.5 0 0110 4.5v1A1.5 1.5 0 018.5 7h-1A1.5 1.5 0 016 5.5zM7.5 4a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z"/><path pid="1" d="M6 4.5H1.866a1 1 0 100 1h2.668A6.52 6.52 0 001.814 9H2.5q.186 0 .358.043a5.52 5.52 0 013.185-3.185A1.5 1.5 0 016 5.5zm3.957 1.358A1.5 1.5 0 0010 5.5v-1h4.134a1 1 0 110 1h-2.668a6.52 6.52 0 012.72 3.5H13.5q-.185 0-.358.043a5.52 5.52 0 00-3.185-3.185"/>',
    },
});
