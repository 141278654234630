/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'truck-front-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.5 0A2.5 2.5 0 001 2.5v9c0 .818.393 1.544 1 2v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V14h6v1.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2c.607-.456 1-1.182 1-2v-9A2.5 2.5 0 0012.5 0zM3 3a1 1 0 011-1h8a1 1 0 011 1v3.9c0 .625-.562 1.092-1.17.994C10.925 7.747 9.208 7.5 8 7.5s-2.925.247-3.83.394A1.008 1.008 0 013 6.9zm1 9a1 1 0 110-2 1 1 0 010 2m8 0a1 1 0 110-2 1 1 0 010 2m-5-2h2a1 1 0 110 2H7a1 1 0 110-2"/>',
    },
});
