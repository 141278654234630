/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sign-no-parking': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 16A8 8 0 108 0a8 8 0 000 16m5.29-3.416L9.63 8.923C10.5 8.523 11 7.66 11 6.586c0-1.482-.955-2.584-2.538-2.584H5.5v.79L3.416 2.71a7 7 0 019.874 9.874m-.706.707A7 7 0 012.71 3.417l2.79 2.79V12h1.283V9.164h1.674zM8.726 8.019L6.777 6.07v-.966H8.27c.893 0 1.419.539 1.419 1.482 0 .769-.35 1.273-.963 1.433m-1.949-.534l.59.59h-.59z"/>',
    },
});
