/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cake2-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.899.804l.595-.792.598.79A.747.747 0 014 1.806v4.886q-.532-.09-1-.201V1.813a.747.747 0 01-.1-1.01zM13 1.806v4.685a15 15 0 01-1 .201v-4.88a.747.747 0 01-.1-1.007l.595-.792.598.79A.746.746 0 0113 1.806m-3 0a.746.746 0 00.092-1.004l-.598-.79-.595.792A.747.747 0 009 1.813v5.17q.512-.02 1-.055zm-3 0v5.176q-.512-.018-1-.054V1.813a.747.747 0 01-.1-1.01l.595-.79.598.789A.747.747 0 017 1.806"/><path pid="1" d="M4.5 6.988V4.226a23 23 0 011-.114V7.16c0 .131.101.24.232.25l.231.017q.498.037 1.02.055l.258.01a.25.25 0 00.26-.25V4.003a29 29 0 011 0V7.24a.25.25 0 00.258.25l.259-.009q.52-.018 1.019-.055l.231-.017a.25.25 0 00.232-.25V4.112q.518.047 1 .114v2.762a.25.25 0 00.292.246l.291-.049q.547-.091 1.033-.208l.192-.046a.25.25 0 00.192-.243V4.621c.672.184 1.251.409 1.677.678.415.261.823.655.823 1.2V13.5c0 .546-.408.94-.823 1.201-.44.278-1.043.51-1.745.696-1.41.376-3.33.603-5.432.603s-4.022-.227-5.432-.603c-.702-.187-1.305-.418-1.745-.696C.408 14.44 0 14.046 0 13.5v-7c0-.546.408-.94.823-1.201.426-.269 1.005-.494 1.677-.678v2.067c0 .116.08.216.192.243l.192.046q.486.116 1.033.208l.292.05a.25.25 0 00.291-.247M1 8.82v1.659a1.935 1.935 0 002.298.43.935.935 0 011.08.175l.348.349a2 2 0 002.615.185l.059-.044a1 1 0 011.2 0l.06.044a2 2 0 002.613-.185l.348-.348a.94.94 0 011.082-.175c.781.39 1.718.208 2.297-.426V8.833l-.68.907a.94.94 0 01-1.17.276 1.94 1.94 0 00-2.236.363l-.348.348a1 1 0 01-1.307.092l-.06-.044a2 2 0 00-2.399 0l-.06.044a1 1 0 01-1.306-.092l-.35-.35a1.935 1.935 0 00-2.233-.362.935.935 0 01-1.168-.277z"/>',
    },
});
