/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'list-task': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M2 2.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V3a.5.5 0 00-.5-.5zM3 3H2v1h1z"/><path pid="1" d="M5 3.5a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5M5.5 7a.5.5 0 000 1h9a.5.5 0 000-1zm0 4a.5.5 0 000 1h9a.5.5 0 000-1z"/><path pid="2" fill-rule="evenodd" d="M1.5 7a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H2a.5.5 0 01-.5-.5zM2 7h1v1H2zm0 3.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm1 .5H2v1h1z"/>',
    },
});
