/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    mask: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.225 1.227A7.5 7.5 0 0110.5 8a7.5 7.5 0 01-4.275 6.773 7 7 0 100-13.546M4.187.966a8 8 0 117.627 14.069A8 8 0 014.186.964z"/>',
    },
});
