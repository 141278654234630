/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    flower2: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 16a4 4 0 004-4 4 4 0 000-8 4 4 0 00-8 0 4 4 0 100 8 4 4 0 004 4m3-12q0 .11-.03.247c-.544.241-1.091.638-1.598 1.084A3 3 0 008 5c-.494 0-.96.12-1.372.331-.507-.446-1.054-.843-1.597-1.084A1 1 0 015 4a3 3 0 016 0m-.812 6.052A3 3 0 0011 8a3 3 0 00-.812-2.052c.215-.18.432-.346.647-.487C11.34 5.131 11.732 5 12 5a3 3 0 110 6c-.268 0-.66-.13-1.165-.461a7 7 0 01-.647-.487m-3.56.617a3 3 0 002.744 0c.507.446 1.054.842 1.598 1.084q.03.137.03.247a3 3 0 11-6 0q0-.11.03-.247c.544-.242 1.091-.638 1.598-1.084m-.816-4.721A3 3 0 005 8c0 .794.308 1.516.812 2.052a7 7 0 01-.647.487C4.66 10.869 4.268 11 4 11a3 3 0 010-6c.268 0 .66.13 1.165.461.215.141.432.306.647.487M8 9a1 1 0 110-2 1 1 0 010 2"/>',
    },
});
