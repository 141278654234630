/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'suitcase2-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.5 0a.5.5 0 00-.5.5V3H4.5A1.5 1.5 0 003 4.5v9a1.5 1.5 0 001.003 1.416A1 1 0 106 15h4a1 1 0 101.996-.084A1.5 1.5 0 0013 13.5v-9A1.5 1.5 0 0011.5 3H10V.5a.5.5 0 00-.5-.5zM9 3H7V1h2zM4 7V6h8v1z"/>',
    },
});
