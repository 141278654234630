/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-video2': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10 9.05a2.5 2.5 0 100-5 2.5 2.5 0 000 5"/><path pid="1" d="M2 1a2 2 0 00-2 2v9a2 2 0 002 2h12a2 2 0 002-2V3a2 2 0 00-2-2zM1 3a1 1 0 011-1h2v2H1zm4 10V2h9a1 1 0 011 1v9c0 .285-.12.543-.31.725C14.15 11.494 12.822 10 10 10c-3.037 0-4.345 1.73-4.798 3zm-4-2h3v2H2a1 1 0 01-1-1zm3-1H1V8h3zm0-3H1V5h3z"/>',
    },
});
