/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'grid-3x2-gap': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 4v2H2V4zm1 7V9a1 1 0 00-1-1H2a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1m0-5V4a1 1 0 00-1-1H2a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1m5 5V9a1 1 0 00-1-1H7a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1m0-5V4a1 1 0 00-1-1H7a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1M9 4v2H7V4zm5 0h-2v2h2zM4 9v2H2V9zm5 0v2H7V9zm5 0v2h-2V9zm-3-5a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1zm1 4a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1V9a1 1 0 00-1-1z"/>',
    },
});
