/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    fan: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10 3c0 1.313-.304 2.508-.8 3.4a2 2 0 00-1.484-.38c-.28-.982-.91-2.04-1.838-2.969a8 8 0 00-.491-.454A6 6 0 018 2c.691 0 1.355.117 1.973.332Q10 2.661 10 3m0 5q0 .11-.012.217c1.018-.019 2.2-.353 3.331-1.006a8 8 0 00.57-.361 6 6 0 00-2.53-3.823 9 9 0 01-.145.64c-.34 1.269-.944 2.346-1.656 3.079.277.343.442.78.442 1.254m-.137.728a2 2 0 01-1.07 1.109c.525.87 1.405 1.725 2.535 2.377q.3.174.605.317a6 6 0 002.053-4.111q-.311.11-.641.199c-1.264.339-2.493.356-3.482.11zM8 10c-.45 0-.866-.149-1.2-.4-.494.89-.796 2.082-.796 3.391q0 .346.027.678A6 6 0 008 14c.94 0 1.83-.216 2.623-.602a8 8 0 01-.497-.458c-.925-.926-1.555-1.981-1.836-2.96Q8.149 10 8 10M6 8q0-.12.014-.239c-1.02.017-2.205.351-3.34 1.007a8 8 0 00-.568.359 6 6 0 002.525 3.839 8 8 0 01.148-.653c.34-1.267.94-2.342 1.65-3.075A2 2 0 016 8m-3.347-.632c1.267-.34 2.498-.355 3.488-.107.196-.494.583-.89 1.07-1.1-.524-.874-1.406-1.733-2.541-2.388a8 8 0 00-.594-.312 6 6 0 00-2.06 4.106q.309-.11.637-.199M8 9a1 1 0 100-2 1 1 0 000 2"/><path pid="1" d="M8 15A7 7 0 118 1a7 7 0 010 14m0 1A8 8 0 108 0a8 8 0 000 16"/>',
    },
});
