/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-lock': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 5a1 1 0 011 1v1H7V6a1 1 0 011-1m2 2.076V6a2 2 0 10-4 0v1.076c-.54.166-1 .597-1 1.224v2.4c0 .816.781 1.3 1.5 1.3h3c.719 0 1.5-.484 1.5-1.3V8.3c0-.627-.46-1.058-1-1.224M6.105 8.125A.64.64 0 016.5 8h3a.64.64 0 01.395.125c.085.068.105.133.105.175v2.4c0 .042-.02.107-.105.175A.64.64 0 019.5 11h-3a.64.64 0 01-.395-.125C6.02 10.807 6 10.742 6 10.7V8.3c0-.042.02-.107.105-.175"/><path pid="1" d="M4 0a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2zm0 1h8a1 1 0 011 1v12a1 1 0 01-1 1H4a1 1 0 01-1-1V2a1 1 0 011-1"/>',
    },
});
