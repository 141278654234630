/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    eye: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 011.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0114.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 011.172 8z"/><path pid="1" d="M8 5.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5M4.5 8a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0"/>',
    },
});
