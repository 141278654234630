/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'clipboard2-plus-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10 .5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5.5.5 0 01-.5.5.5.5 0 00-.5.5V2a.5.5 0 00.5.5h5A.5.5 0 0011 2v-.5a.5.5 0 00-.5-.5.5.5 0 01-.5-.5"/><path pid="1" d="M4.085 1H3.5A1.5 1.5 0 002 2.5v12A1.5 1.5 0 003.5 16h9a1.5 1.5 0 001.5-1.5v-12A1.5 1.5 0 0012.5 1h-.585q.084.236.085.5V2a1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 014 2v-.5q.001-.264.085-.5M8.5 6.5V8H10a.5.5 0 010 1H8.5v1.5a.5.5 0 01-1 0V9H6a.5.5 0 010-1h1.5V6.5a.5.5 0 011 0"/>',
    },
});
