/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'peace-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M14 13.292A8 8 0 008.5.015v7.778zm-.708.708L8.5 9.206v6.778a7.97 7.97 0 004.792-1.986zM7.5 15.985V9.207L2.708 14A7.97 7.97 0 007.5 15.985M2 13.292A8 8 0 017.5.015v7.778z"/>',
    },
});
