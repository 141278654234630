/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'shield-slash': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M1.093 3.093c-.465 4.275.885 7.46 2.513 9.589a11.8 11.8 0 002.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7 7 0 001.048-.625 11.3 11.3 0 001.733-1.525l-.745-.745a10.3 10.3 0 01-1.578 1.392c-.346.244-.652.42-.893.533q-.18.085-.293.118a1 1 0 01-.101.025 1 1 0 01-.1-.025 2 2 0 01-.294-.118 6 6 0 01-.893-.533 10.7 10.7 0 01-2.287-2.233C3.053 10.228 1.879 7.594 2.06 4.06zM3.98 1.98l-.852-.852A59 59 0 015.072.559C6.157.266 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 011.044 1.262c.483 3.626-.332 6.491-1.551 8.616l-.77-.77c1.042-1.915 1.72-4.469 1.29-7.702a.48.48 0 00-.33-.39c-.65-.213-1.75-.56-2.836-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524a50 50 0 00-1.357.39zm9.666 12.374l-13-13 .708-.708 13 13z"/>',
    },
});
