/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'mic-mute': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4 4 0 0012 8V7a.5.5 0 011 0zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a5 5 0 01-2.43.923V15h3a.5.5 0 010 1h-7a.5.5 0 010-1h3v-2.025A5 5 0 013 8V7a.5.5 0 011 0v1a4 4 0 004 4m3-9v4.879l-1-1V3a2 2 0 00-3.997-.118l-.845-.845A3.001 3.001 0 0111 3"/><path pid="1" d="M9.486 10.607l-.748-.748A2 2 0 016 8v-.878l-1-1V8a3 3 0 004.486 2.607m-7.84-9.253l12 12 .708-.708-12-12z"/>',
    },
});
