/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    easel3: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M8.5 13.134V12h5a1.5 1.5 0 001.5-1.5V2h.5a.5.5 0 000-1H.5a.5.5 0 000 1H1v8.5A1.5 1.5 0 002.5 12h5v1.134a1 1 0 101 0M2 2v8.5a.5.5 0 00.5.5h11a.5.5 0 00.5-.5V2z"/>',
    },
});
