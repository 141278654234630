/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'caret-down': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.204 5h9.592L8 10.481zm-.753.659l4.796 5.48a1 1 0 001.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 00-.753 1.659"/>',
    },
});
