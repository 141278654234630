/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'box-arrow-down-left': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M7.364 12.5a.5.5 0 00.5.5H14.5a1.5 1.5 0 001.5-1.5v-10A1.5 1.5 0 0014.5 0h-10A1.5 1.5 0 003 1.5v6.636a.5.5 0 101 0V1.5a.5.5 0 01.5-.5h10a.5.5 0 01.5.5v10a.5.5 0 01-.5.5H7.864a.5.5 0 00-.5.5"/><path pid="1" fill-rule="evenodd" d="M0 15.5a.5.5 0 00.5.5h5a.5.5 0 000-1H1.707l8.147-8.146a.5.5 0 00-.708-.708L1 14.293V10.5a.5.5 0 00-1 0z"/>',
    },
});
