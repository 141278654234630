/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'input-cursor': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10 5h4a1 1 0 011 1v4a1 1 0 01-1 1h-4v1h4a2 2 0 002-2V6a2 2 0 00-2-2h-4zM6 5V4H2a2 2 0 00-2 2v4a2 2 0 002 2h4v-1H2a1 1 0 01-1-1V6a1 1 0 011-1z"/><path pid="1" fill-rule="evenodd" d="M8 1a.5.5 0 01.5.5v13a.5.5 0 01-1 0v-13A.5.5 0 018 1"/>',
    },
});
