/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'bag-heart-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11.5 4v-.5a3.5 3.5 0 10-7 0V4H1v10a2 2 0 002 2h10a2 2 0 002-2V4zM8 1a2.5 2.5 0 012.5 2.5V4h-5v-.5A2.5 2.5 0 018 1m0 6.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132"/>',
    },
});
