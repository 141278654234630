/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    virus: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 0a1 1 0 011 1v1.402c0 .511.677.693.933.25l.7-1.214a1 1 0 011.733 1l-.701 1.214c-.256.443.24.939.683.683l1.214-.701a1 1 0 011 1.732l-1.214.701c-.443.256-.262.933.25.933H15a1 1 0 110 2h-1.402c-.512 0-.693.677-.25.933l1.214.701a1 1 0 11-1 1.732l-1.214-.7c-.443-.257-.939.24-.683.682l.701 1.214a1 1 0 11-1.732 1l-.701-1.214c-.256-.443-.933-.262-.933.25V15a1 1 0 11-2 0v-1.402c0-.512-.677-.693-.933-.25l-.701 1.214a1 1 0 01-1.732-1l.7-1.214c.257-.443-.24-.939-.682-.683l-1.214.701a1 1 0 11-1-1.732l1.214-.701c.443-.256.261-.933-.25-.933H1a1 1 0 110-2h1.402c.511 0 .693-.677.25-.933l-1.214-.701a1 1 0 111-1.732l1.214.701c.443.256.939-.24.683-.683l-.701-1.214a1 1 0 011.732-1l.701 1.214c.256.443.933.261.933-.25V1a1 1 0 011-1m2 5a1 1 0 10-2 0 1 1 0 002 0M6 7a1 1 0 10-2 0 1 1 0 002 0m1 4a1 1 0 100-2 1 1 0 000 2m5-3a1 1 0 10-2 0 1 1 0 002 0"/>',
    },
});
