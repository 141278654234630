/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    camera: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M15 12a1 1 0 01-1 1H2a1 1 0 01-1-1V6a1 1 0 011-1h1.172a3 3 0 002.12-.879l.83-.828A1 1 0 016.827 3h2.344a1 1 0 01.707.293l.828.828A3 3 0 0012.828 5H14a1 1 0 011 1zM2 4a2 2 0 00-2 2v6a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1.172a2 2 0 01-1.414-.586l-.828-.828A2 2 0 009.172 2H6.828a2 2 0 00-1.414.586l-.828.828A2 2 0 013.172 4z"/><path pid="1" d="M8 11a2.5 2.5 0 110-5 2.5 2.5 0 010 5m0 1a3.5 3.5 0 100-7 3.5 3.5 0 000 7M3 6.5a.5.5 0 11-1 0 .5.5 0 011 0"/>',
    },
});
