/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-cs': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2H8v-1h4a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM3.629 15.29a1.2 1.2 0 01-.112-.449h.765a.58.58 0 00.255.384q.105.073.249.114t.32.041q.245 0 .412-.07a.56.56 0 00.255-.193.5.5 0 00.085-.29.39.39 0 00-.152-.326q-.153-.12-.463-.193l-.618-.143a1.7 1.7 0 01-.54-.214 1 1 0 01-.35-.367 1.1 1.1 0 01-.124-.524q0-.366.19-.639.191-.272.528-.422t.776-.149q.458 0 .78.152.324.153.5.41.18.255.2.566h-.75a.56.56 0 00-.12-.258.6.6 0 00-.246-.181.9.9 0 00-.37-.068q-.324 0-.512.152a.47.47 0 00-.185.384q0 .18.144.3a1 1 0 00.404.175l.621.143q.325.075.566.211t.375.358.134.56q0 .37-.187.656a1.2 1.2 0 01-.54.439q-.351.158-.858.158a2.2 2.2 0 01-.665-.09 1.4 1.4 0 01-.477-.252 1.1 1.1 0 01-.29-.375m-2.72-2.23a1.7 1.7 0 00-.103.633v.495q0 .369.102.627a.83.83 0 00.299.392.85.85 0 00.478.132.86.86 0 00.4-.088.7.7 0 00.273-.249.8.8 0 00.118-.363h.764v.076a1.27 1.27 0 01-.225.674q-.205.29-.551.454a1.8 1.8 0 01-.785.164q-.54 0-.914-.217a1.4 1.4 0 01-.572-.626Q0 14.756 0 14.188v-.498q0-.569.196-.979a1.44 1.44 0 01.572-.633q.378-.222.91-.222.33 0 .607.097.281.093.49.272a1.32 1.32 0 01.465.964v.073h-.764a.85.85 0 00-.12-.38.7.7 0 00-.273-.261.8.8 0 00-.398-.097.8.8 0 00-.475.138.87.87 0 00-.302.398z"/>',
    },
});
