/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cloud-plus-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 2a5.53 5.53 0 00-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m.5 4v1.5H10a.5.5 0 010 1H8.5V10a.5.5 0 01-1 0V8.5H6a.5.5 0 010-1h1.5V6a.5.5 0 011 0"/>',
    },
});
