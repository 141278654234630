/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-square': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11 6a3 3 0 11-6 0 3 3 0 016 0"/><path pid="1" d="M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2zm12 1a1 1 0 011 1v12a1 1 0 01-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 01-1-1V2a1 1 0 011-1z"/>',
    },
});
