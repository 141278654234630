/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'volume-up-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11.536 14.01A8.47 8.47 0 0014.026 8a8.47 8.47 0 00-2.49-6.01l-.708.707A7.48 7.48 0 0113.025 8c0 2.071-.84 3.946-2.197 5.303z"/><path pid="1" d="M10.121 12.596A6.48 6.48 0 0012.025 8a6.48 6.48 0 00-1.904-4.596l-.707.707A5.48 5.48 0 0111.025 8a5.48 5.48 0 01-1.61 3.89z"/><path pid="2" d="M8.707 11.182A4.5 4.5 0 0010.025 8a4.5 4.5 0 00-1.318-3.182L8 5.525A3.5 3.5 0 019.025 8 3.5 3.5 0 018 10.475zM6.717 3.55A.5.5 0 017 4v8a.5.5 0 01-.812.39L3.825 10.5H1.5A.5.5 0 011 10V6a.5.5 0 01.5-.5h2.325l2.363-1.89a.5.5 0 01.529-.06"/>',
    },
});
