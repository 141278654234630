/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'folder-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.828 3h3.982a2 2 0 011.992 2.181l-.637 7A2 2 0 0113.174 14H2.825a2 2 0 01-1.991-1.819l-.637-7a2 2 0 01.342-1.31L.5 3a2 2 0 012-2h3.672a2 2 0 011.414.586l.828.828A2 2 0 009.828 3m-8.322.12q.322-.119.684-.12h5.396l-.707-.707A1 1 0 006.172 2H2.5a1 1 0 00-1 .981z"/>',
    },
});
