/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-java': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM1.521 15.175a1.3 1.3 0 01-.082-.466h.765a.6.6 0 00.073.27.5.5 0 00.454.246q.285 0 .422-.164.138-.165.138-.466V11.85h.79v2.725q0 .66-.357 1.005-.354.345-.984.345a1.6 1.6 0 01-.568-.094 1.1 1.1 0 01-.408-.266 1.1 1.1 0 01-.243-.39m3.972-.354l-.314 1.028h-.8l1.342-3.999h.926l1.336 3.999h-.84l-.314-1.028zm1.178-.59l-.49-1.616h-.035l-.49 1.617zm2.342 1.618h.952l1.327-3.999h-.878l-.888 3.138h-.038L8.59 11.85h-.917zm3.087-1.028l-.314 1.028h-.8l1.342-3.999h.926l1.336 3.999h-.84l-.314-1.028zm1.178-.59l-.49-1.616h-.035l-.49 1.617z"/>',
    },
});
