/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'bag-dash': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M5.5 10a.5.5 0 01.5-.5h4a.5.5 0 010 1H6a.5.5 0 01-.5-.5"/><path pid="1" d="M8 1a2.5 2.5 0 012.5 2.5V4h-5v-.5A2.5 2.5 0 018 1m3.5 3v-.5a3.5 3.5 0 10-7 0V4H1v10a2 2 0 002 2h10a2 2 0 002-2V4zM2 5h12v9a1 1 0 01-1 1H3a1 1 0 01-1-1z"/>',
    },
});
