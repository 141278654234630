/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'window-fullscreen': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3 3.5a.5.5 0 11-1 0 .5.5 0 011 0m1.5 0a.5.5 0 11-1 0 .5.5 0 011 0m1 .5a.5.5 0 100-1 .5.5 0 000 1"/><path pid="1" d="M.5 1a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h15a.5.5 0 00.5-.5v-13a.5.5 0 00-.5-.5zM1 5V2h14v3zm0 1h14v8H1z"/>',
    },
});
