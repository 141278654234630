/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-minus': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.5 8a.5.5 0 01.5-.5h4a.5.5 0 010 1H6a.5.5 0 01-.5-.5"/><path pid="1" d="M4 0a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2zm0 1h8a1 1 0 011 1v12a1 1 0 01-1 1H4a1 1 0 01-1-1V2a1 1 0 011-1"/>',
    },
});
