/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'heart-half': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 2.748v11.047c3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 01.176-.17C12.72-3.042 23.333 4.867 8 15"/>',
    },
});
