/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'signpost-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.293.707A1 1 0 007 1.414V4H2a1 1 0 00-1 1v4a1 1 0 001 1h5v6h2v-6h3.532a1 1 0 00.768-.36l1.933-2.32a.5.5 0 000-.64L13.3 4.36a1 1 0 00-.768-.36H9V1.414A1 1 0 007.293.707"/>',
    },
});
