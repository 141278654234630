/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    prescription: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.5 6a.5.5 0 00-.5.5v4a.5.5 0 001 0V9h.293l2 2-1.147 1.146a.5.5 0 00.708.708L9 11.707l1.146 1.147a.5.5 0 00.708-.708L9.707 11l1.147-1.146a.5.5 0 00-.708-.708L9 10.293 7.695 8.987A1.5 1.5 0 007.5 6zM6 7h1.5a.5.5 0 010 1H6z"/><path pid="1" d="M2 1a1 1 0 011-1h10a1 1 0 011 1v2a1 1 0 01-1 1v10.5a1.5 1.5 0 01-1.5 1.5h-7A1.5 1.5 0 013 14.5V4a1 1 0 01-1-1zm2 3v10.5a.5.5 0 00.5.5h7a.5.5 0 00.5-.5V4zM3 3h10V1H3z"/>',
    },
});
