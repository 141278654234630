/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'browser-edge': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.482 9.341c-.069.062-.17.153-.17.309 0 .162.107.325.3.456.877.613 2.521.54 2.592.538h.002c.667 0 1.32-.18 1.894-.519A3.84 3.84 0 0016 6.819c.018-1.316-.44-2.218-.666-2.664l-.04-.08C13.963 1.487 11.106 0 8 0A8 8 0 00.473 5.29C1.488 4.048 3.183 3.262 5 3.262c2.83 0 5.01 1.885 5.01 4.797h-.004v.002c0 .338-.168.832-.487 1.244l.006-.006z"/><path pid="1" d="M.01 7.753a8.14 8.14 0 00.753 3.641 8 8 0 006.495 4.564 5 5 0 01-.785-.377h-.01l-.12-.075a5.5 5.5 0 01-1.56-1.463A5.543 5.543 0 016.81 5.8l.01-.004.025-.012c.208-.098.62-.292 1.167-.285q.194.001.384.033a4 4 0 00-.993-.698l-.01-.005C6.348 4.282 5.199 4.263 5 4.263c-2.44 0-4.824 1.634-4.99 3.49m10.263 7.912q.133-.04.265-.084-.153.047-.307.086z"/><path pid="2" d="M10.228 15.667a5 5 0 00.303-.086l.082-.025a8.02 8.02 0 004.162-3.3.25.25 0 00-.331-.35q-.322.168-.663.294a6.4 6.4 0 01-2.243.4c-2.957 0-5.532-2.031-5.532-4.644q.003-.203.046-.399a4.54 4.54 0 00-.46 5.898l.003.005c.315.441.707.821 1.158 1.121h.003l.144.09c.877.55 1.721 1.078 3.328.996"/>',
    },
});
