/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'duffle-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.007 4.097q.011-.146.027-.298c.05-.464.141-.979.313-1.45.169-.465.432-.933.853-1.249 1.115-.836 2.485-.836 3.6 0 .42.316.684.784.853 1.25.171.47.263.985.313 1.449q.016.15.027.298c1.401.194 2.65.531 3.525 1.012 2.126 1.169 1.446 6.095 1.089 8.018a.954.954 0 01-.95.772H1.343a.954.954 0 01-.95-.772c-.357-1.923-1.037-6.85 1.09-8.018.873-.48 2.123-.818 3.524-1.012M4.05 5.633a22 22 0 00-1.565.352l-.091.026-.034.01a.5.5 0 00.282.959l.005-.002.02-.005.08-.023a21 21 0 011.486-.334A21 21 0 018 6.25c1.439 0 2.781.183 3.767.367a21 21 0 011.567.356l.02.005.004.001a.5.5 0 00.283-.959h-.003l-.006-.002-.025-.007a15 15 0 00-.43-.113 22 22 0 00-1.226-.265A22 22 0 008 5.25c-1.518 0-2.926.192-3.95.383M6.8 1.9c-.203.153-.377.42-.513.791a5.3 5.3 0 00-.265 1.292 35 35 0 011.374-.076c.866-.022 1.742.003 2.584.076a5.3 5.3 0 00-.266-1.292c-.135-.372-.309-.638-.513-.791-.76-.57-1.64-.57-2.4 0z"/>',
    },
});
