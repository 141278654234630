/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'wrench-adjustable-circle': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.496 8a4.5 4.5 0 01-1.703 3.526L9.497 8.5l2.959-1.11q.04.3.04.61"/><path pid="1" d="M16 8A8 8 0 110 8a8 8 0 0116 0m-1 0a7 7 0 10-13.202 3.249l1.988-1.657a4.5 4.5 0 017.537-4.623L7.497 6.5l1 2.5 1.333 3.11c-.56.251-1.18.39-1.833.39a4.5 4.5 0 01-1.592-.29L4.747 14.2A7 7 0 0015 8m-8.295.139a.25.25 0 00-.288-.376l-1.5.5.159.474.808-.27-.595.894a.25.25 0 00.287.376l.808-.27-.595.894a.25.25 0 00.287.376l1.5-.5-.159-.474-.808.27.596-.894a.25.25 0 00-.288-.376l-.808.27z"/>',
    },
});
