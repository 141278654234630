/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    toggles2: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.465 10H12a2 2 0 110 4H9.465c.34-.588.535-1.271.535-2s-.195-1.412-.535-2"/><path pid="1" d="M6 15a3 3 0 100-6 3 3 0 000 6m0 1a4 4 0 110-8 4 4 0 010 8m.535-10a4 4 0 01-.409-1H4a1 1 0 010-2h2.126q.138-.534.41-1H4a2 2 0 100 4z"/><path pid="2" d="M14 4a4 4 0 11-8 0 4 4 0 018 0"/>',
    },
});
