/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    brilliance: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 16A8 8 0 118 0a8 8 0 010 16M1 8a7 7 0 007 7 3.5 3.5 0 100-7 3.5 3.5 0 110-7 7 7 0 00-7 7"/>',
    },
});
