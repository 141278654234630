/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    kanban: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M13.5 1a1 1 0 011 1v12a1 1 0 01-1 1h-11a1 1 0 01-1-1V2a1 1 0 011-1zm-11-1a2 2 0 00-2 2v12a2 2 0 002 2h11a2 2 0 002-2V2a2 2 0 00-2-2z"/><path pid="1" d="M6.5 3a1 1 0 011-1h1a1 1 0 011 1v3a1 1 0 01-1 1h-1a1 1 0 01-1-1zm-4 0a1 1 0 011-1h1a1 1 0 011 1v7a1 1 0 01-1 1h-1a1 1 0 01-1-1zm8 0a1 1 0 011-1h1a1 1 0 011 1v10a1 1 0 01-1 1h-1a1 1 0 01-1-1z"/>',
    },
});
