/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'chat-quote': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.678 11.894a1 1 0 01.287.801 11 11 0 01-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 01.71-.074A8 8 0 008 14c3.996 0 7-2.807 7-6s-3.004-6-7-6-7 2.808-7 6c0 1.468.617 2.83 1.678 3.894m-.493 3.905a22 22 0 01-.713.129c-.2.032-.352-.176-.273-.362a10 10 0 00.244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9 9 0 01-2.347-.306c-.52.263-1.639.742-3.468 1.105"/><path pid="1" d="M7.066 6.76A1.665 1.665 0 004 7.668a1.667 1.667 0 002.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 00.6.58c1.486-1.54 1.293-3.214.682-4.112zm4 0A1.665 1.665 0 008 7.668a1.667 1.667 0 002.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 00.6.58c1.486-1.54 1.293-3.214.682-4.112z"/>',
    },
});
