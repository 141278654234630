/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    folder: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M.54 3.87L.5 3a2 2 0 012-2h3.672a2 2 0 011.414.586l.828.828A2 2 0 009.828 3h3.982a2 2 0 011.992 2.181l-.637 7A2 2 0 0113.174 14H2.826a2 2 0 01-1.991-1.819l-.637-7a2 2 0 01.342-1.31zM2.19 4a1 1 0 00-.996 1.09l.637 7a1 1 0 00.995.91h10.348a1 1 0 00.995-.91l.637-7A1 1 0 0013.81 4zm4.69-1.707A1 1 0 006.172 2H2.5a1 1 0 00-1 .981l.006.139q.323-.119.684-.12h5.396z"/>',
    },
});
