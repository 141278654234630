/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'grip-vertical': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7 2a1 1 0 11-2 0 1 1 0 012 0m3 0a1 1 0 11-2 0 1 1 0 012 0M7 5a1 1 0 11-2 0 1 1 0 012 0m3 0a1 1 0 11-2 0 1 1 0 012 0M7 8a1 1 0 11-2 0 1 1 0 012 0m3 0a1 1 0 11-2 0 1 1 0 012 0m-3 3a1 1 0 11-2 0 1 1 0 012 0m3 0a1 1 0 11-2 0 1 1 0 012 0m-3 3a1 1 0 11-2 0 1 1 0 012 0m3 0a1 1 0 11-2 0 1 1 0 012 0"/>',
    },
});
