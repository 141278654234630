/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sign-no-left-turn': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 8a8 8 0 1016 0A8 8 0 000 8m3.416 5.29l5.988-5.987c.362.274.596.708.596 1.197V11h1V8.5c0-.765-.344-1.45-.885-1.908l3.176-3.176a7 7 0 01-9.874 9.874zm-.707-.706a7 7 0 019.874-9.874L9.196 6.097A2.5 2.5 0 008.5 6H7V4.534a.25.25 0 00-.41-.192L4.23 6.308a.25.25 0 000 .384l2.36 1.966.026.02zM8.293 7L7 8.293V7z"/>',
    },
});
