/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'piggy-bank-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.964 1.527c-2.977 0-5.571 1.704-6.32 4.125h-.55A1 1 0 00.11 6.824l.254 1.46a1.5 1.5 0 001.478 1.243h.263c.3.513.688.978 1.145 1.382l-.729 2.477a.5.5 0 00.48.641h2a.5.5 0 00.471-.332l.482-1.351c.635.173 1.31.267 2.011.267.707 0 1.388-.095 2.028-.272l.543 1.372a.5.5 0 00.465.316h2a.5.5 0 00.478-.645l-.761-2.506C13.81 9.895 14.5 8.559 14.5 7.069q0-.218-.02-.431c.261-.11.508-.266.705-.444.315.306.815.306.815-.417 0 .223-.5.223-.461-.026a1 1 0 00.09-.255.7.7 0 00-.202-.645.58.58 0 00-.707-.098.74.74 0 00-.375.562c-.024.243.082.48.32.654a2 2 0 01-.259.153c-.534-2.664-3.284-4.595-6.442-4.595m7.173 3.876a.6.6 0 01-.098.21l-.044-.025c-.146-.09-.157-.175-.152-.223a.24.24 0 01.117-.173c.049-.027.08-.021.113.012a.2.2 0 01.064.199m-8.999-.65a.5.5 0 11-.276-.96A7.6 7.6 0 017.964 3.5c.763 0 1.497.11 2.18.315a.5.5 0 11-.287.958A6.6 6.6 0 007.964 4.5c-.64 0-1.255.09-1.826.254zM5 6.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0"/>',
    },
});
