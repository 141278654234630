/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cloud-moon': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7 8a3.5 3.5 0 013.5 3.555.5.5 0 00.625.492A1.503 1.503 0 0113 13.5a1.5 1.5 0 01-1.5 1.5H3a2 2 0 11.1-3.998.5.5 0 00.509-.375A3.5 3.5 0 017 8m4.473 3a4.5 4.5 0 00-8.72-.99A3 3 0 003 16h8.5a2.5 2.5 0 000-5z"/><path pid="1" d="M11.286 1.778a.5.5 0 00-.565-.755 4.595 4.595 0 00-3.18 5.003 5.5 5.5 0 011.055.209A3.6 3.6 0 019.83 2.617a4.593 4.593 0 004.31 5.744 3.58 3.58 0 01-2.241.634q.244.477.394 1a4.59 4.59 0 003.624-2.04.5.5 0 00-.565-.755 3.593 3.593 0 01-4.065-5.422z"/>',
    },
});
