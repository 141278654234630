/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    mortarboard: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.211 2.047a.5.5 0 00-.422 0l-7.5 3.5a.5.5 0 00.025.917l7.5 3a.5.5 0 00.372 0L14 7.14V13a1 1 0 00-1 1v2h3v-2a1 1 0 00-1-1V6.739l.686-.275a.5.5 0 00.025-.917zM8 8.46L1.758 5.965 8 3.052l6.242 2.913z"/><path pid="1" d="M4.176 9.032a.5.5 0 00-.656.327l-.5 1.7a.5.5 0 00.294.605l4.5 1.8a.5.5 0 00.372 0l4.5-1.8a.5.5 0 00.294-.605l-.5-1.7a.5.5 0 00-.656-.327L8 10.466zm-.068 1.873l.22-.748 3.496 1.311a.5.5 0 00.352 0l3.496-1.311.22.748L8 12.46z"/>',
    },
});
