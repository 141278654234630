/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'volume-off': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10.717 3.55A.5.5 0 0111 4v8a.5.5 0 01-.812.39L7.825 10.5H5.5A.5.5 0 015 10V6a.5.5 0 01.5-.5h2.325l2.363-1.89a.5.5 0 01.529-.06M10 5.04L8.312 6.39A.5.5 0 018 6.5H6v3h2a.5.5 0 01.312.11L10 10.96z"/>',
    },
});
