/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    boombox: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.5 5a.5.5 0 100-1 .5.5 0 000 1m2 0a.5.5 0 100-1 .5.5 0 000 1m7.5-.5a.5.5 0 11-1 0 .5.5 0 011 0m1.5.5a.5.5 0 100-1 .5.5 0 000 1m-7-1a.5.5 0 000 1h3a.5.5 0 000-1zm5.5 6.5a.5.5 0 11-1 0 .5.5 0 011 0"/><path pid="1" d="M11.5 13a2.5 2.5 0 100-5 2.5 2.5 0 000 5m0-1a1.5 1.5 0 110-3 1.5 1.5 0 010 3M5 10.5a.5.5 0 11-1 0 .5.5 0 011 0"/><path pid="2" d="M7 10.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0m-1 0a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0"/><path pid="3" d="M14 0a.5.5 0 01.5.5V2h.5a1 1 0 011 1v11a1 1 0 01-1 1H1a1 1 0 01-1-1V3a1 1 0 011-1h12.5V.5A.5.5 0 0114 0M1 3v3h14V3zm14 4H1v7h14z"/>',
    },
});
