/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    backpack3: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4.04 7.43a4 4 0 017.92 0 .5.5 0 11-.99.14 3 3 0 00-5.94 0 .5.5 0 11-.99-.14M4 9.5a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5zm1 .5v3h6v-3h-1v.5a.5.5 0 01-1 0V10z"/><path pid="1" d="M6 2.341V2a2 2 0 114 0v.341c.465.165.904.385 1.308.653l.416-1.247a1 1 0 011.748-.284l.77 1.027a1 1 0 01.15.917l-.803 2.407C13.854 6.49 14 7.229 14 8v5.5a2.5 2.5 0 01-2.5 2.5h-7A2.5 2.5 0 012 13.5V8c0-.771.146-1.509.41-2.186l-.802-2.407a1 1 0 01.15-.917l.77-1.027a1 1 0 011.748.284l.416 1.247A6 6 0 016 2.34zM7 2v.083a6 6 0 012 0V2a1 1 0 10-2 0m5.941 2.595l.502-1.505-.77-1.027-.532 1.595q.447.427.8.937M3.86 3.658l-.532-1.595-.77 1.027.502 1.505q.352-.51.8-.937M8 3a5 5 0 00-5 5v5.5A1.5 1.5 0 004.5 15h7a1.5 1.5 0 001.5-1.5V8a5 5 0 00-5-5"/>',
    },
});
