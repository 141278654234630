/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    backpack2: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4.04 7.43a4 4 0 017.92 0 .5.5 0 11-.99.14 3 3 0 00-5.94 0 .5.5 0 11-.99-.14"/><path pid="1" fill-rule="evenodd" d="M4 9.5a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5zm1 .5v3h6v-3h-1v.5a.5.5 0 01-1 0V10z"/><path pid="2" d="M6 2.341V2a2 2 0 114 0v.341c2.33.824 4 3.047 4 5.659v1.191l1.17.585a1.5 1.5 0 01.83 1.342V13.5a1.5 1.5 0 01-1.5 1.5h-1c-.456.607-1.182 1-2 1h-7a2.5 2.5 0 01-2-1h-1A1.5 1.5 0 010 13.5v-2.382a1.5 1.5 0 01.83-1.342L2 9.191V8a6 6 0 014-5.659M7 2v.083a6 6 0 012 0V2a1 1 0 00-2 0M3 13.5A1.5 1.5 0 004.5 15h7a1.5 1.5 0 001.5-1.5V8A5 5 0 003 8zm-1-3.19l-.724.362a.5.5 0 00-.276.447V13.5a.5.5 0 00.5.5H2zm12 0V14h.5a.5.5 0 00.5-.5v-2.382a.5.5 0 00-.276-.447L14 10.309z"/>',
    },
});
