/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sign-no-right-turn-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M14 13.292A8 8 0 002.707 2l4.097 4.098Q7.137 6.001 7.5 6H9V4.534a.25.25 0 01.41-.192l2.36 1.966c.12.1.12.284 0 .384L9.41 8.658l-.026.02zm-.708.708A8 8 0 012 2.707l3.885 3.884A2.5 2.5 0 005 8.5V11h1V8.5c0-.489.234-.923.596-1.197l6.696 6.696z"/><path pid="1" d="M7.707 7L9 8.293V7z"/>',
    },
});
