/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'pie-chart': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.5 1.018a7 7 0 00-4.79 11.566L7.5 7.793zm1 0V7.5h6.482A7 7 0 008.5 1.018M14.982 8.5H8.207l-4.79 4.79A7 7 0 0014.982 8.5M0 8a8 8 0 1116 0A8 8 0 010 8"/>',
    },
});
