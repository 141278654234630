/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    cake: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.994.013l-.595.79a.747.747 0 00.101 1.01V4H5a2 2 0 00-2 2v3H2a2 2 0 00-2 2v4a1 1 0 001 1h14a1 1 0 001-1v-4a2 2 0 00-2-2h-1V6a2 2 0 00-2-2H8.5V1.806A.747.747 0 008.592.802zM4 6a1 1 0 011-1h6a1 1 0 011 1v.414a.9.9 0 01-.646-.268 1.914 1.914 0 00-2.708 0 .914.914 0 01-1.292 0 1.914 1.914 0 00-2.708 0A.9.9 0 014 6.414zm0 1.414c.49 0 .98-.187 1.354-.56a.914.914 0 011.292 0c.748.747 1.96.747 2.708 0a.914.914 0 011.292 0c.374.373.864.56 1.354.56V9H4zM1 11a1 1 0 011-1h12a1 1 0 011 1v.793l-.354.354a.914.914 0 01-1.293 0 1.914 1.914 0 00-2.707 0 .914.914 0 01-1.292 0 1.914 1.914 0 00-2.708 0 .914.914 0 01-1.292 0 1.914 1.914 0 00-2.708 0 .914.914 0 01-1.292 0L1 11.793zm11.646 1.854a1.915 1.915 0 002.354.279V15H1v-1.867c.737.452 1.715.36 2.354-.28a.914.914 0 011.292 0c.748.748 1.96.748 2.708 0a.914.914 0 011.292 0c.748.748 1.96.748 2.707 0a.914.914 0 011.293 0z"/>',
    },
});
