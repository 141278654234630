/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'globe-central-south-asia': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 0a8 8 0 100 16A8 8 0 008 0M4.882 1.731a.48.48 0 00.14.291.487.487 0 01-.126.78l-.291.146a.7.7 0 00-.188.135l-.48.48a1 1 0 01-1.023.242l-.02-.007a1 1 0 00-.462-.04 7 7 0 012.45-2.027m-3 9.674l.86-.216a1 1 0 00.758-.97v-.184a1 1 0 01.445-.832l.04-.026a1 1 0 00.152-1.54L3.121 6.621a.414.414 0 01.542-.624l1.09.818a.5.5 0 00.523.047.5.5 0 01.724.447v.455a.8.8 0 00.131.433l.795 1.192a1 1 0 01.116.238l.73 2.19a1 1 0 00.949.683h.058a1 1 0 00.949-.684l.73-2.189a1 1 0 01.116-.238l.791-1.187A.45.45 0 0111.743 8c.16 0 .306.084.392.218.557.875 1.63 2.282 2.365 2.282l.04-.001a7.003 7.003 0 01-12.658.905z"/>',
    },
});
