/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sort-up': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.5 12.5a.5.5 0 01-1 0V3.707L1.354 4.854a.5.5 0 11-.708-.708l2-1.999.007-.007a.5.5 0 01.7.006l2 2a.5.5 0 11-.707.708L3.5 3.707zm3.5-9a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5M7.5 6a.5.5 0 000 1h5a.5.5 0 000-1zm0 3a.5.5 0 000 1h3a.5.5 0 000-1zm0 3a.5.5 0 000 1h1a.5.5 0 000-1z"/>',
    },
});
