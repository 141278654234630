/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'heptagon-half': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.779.052a.5.5 0 01.442 0l6.015 2.97a.5.5 0 01.267.34l1.485 6.676a.5.5 0 01-.093.415l-4.162 5.354a.5.5 0 01-.395.193H4.662a.5.5 0 01-.395-.193L.105 10.453a.5.5 0 01-.093-.415l1.485-6.676a.5.5 0 01.267-.34zM8 15h3.093l3.868-4.975-1.383-6.212L8 1.058z"/>',
    },
});
