/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'optical-audio': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 10a1 1 0 100-2 1 1 0 000 2"/><path pid="1" d="M4.5 9a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0M8 6.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5"/><path pid="2" d="M2 14.5a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-3.05a2.5 2.5 0 000-4.9V4.5a.5.5 0 00-.146-.354l-2-2A.5.5 0 0011.5 2h-7a.5.5 0 00-.354.146l-2 2A.5.5 0 002 4.5v2.05a2.5 2.5 0 000 4.9zm1-.5v-3a.5.5 0 00-.5-.5 1.5 1.5 0 110-3A.5.5 0 003 7V4.707L4.707 3h6.586L13 4.707V7a.5.5 0 00.5.5 1.5 1.5 0 010 3 .5.5 0 00-.5.5v3z"/>',
    },
});
