/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'chat-right-dots-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 2a2 2 0 00-2-2H2a2 2 0 00-2 2v8a2 2 0 002 2h9.586a1 1 0 01.707.293l2.853 2.853a.5.5 0 00.854-.353zM5 6a1 1 0 11-2 0 1 1 0 012 0m4 0a1 1 0 11-2 0 1 1 0 012 0m3 1a1 1 0 110-2 1 1 0 010 2"/>',
    },
});
