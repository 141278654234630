/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    '7-circle': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1 8a7 7 0 1014 0A7 7 0 001 8m15 0A8 8 0 110 8a8 8 0 0116 0M5.37 5.11V4.001h5.308V5.15L7.42 12H6.025l3.317-6.82v-.07H5.369z"/>',
    },
});
