/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    quote: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12 12a1 1 0 001-1V8.558a1 1 0 00-1-1h-1.388q0-.527.062-1.054.093-.558.31-.992t.559-.683q.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 00-1.085.992 4.9 4.9 0 00-.62 1.458A7.7 7.7 0 009 7.558V11a1 1 0 001 1zm-6 0a1 1 0 001-1V8.558a1 1 0 00-1-1H4.612q0-.527.062-1.054.094-.558.31-.992.217-.434.559-.683.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 00-1.085.992 4.9 4.9 0 00-.62 1.458A7.7 7.7 0 003 7.558V11a1 1 0 001 1z"/>',
    },
});
