/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-grimace': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7 6.25c0 .69-.448 1.25-1 1.25s-1-.56-1-1.25S5.448 5 6 5s1 .56 1 1.25m3 1.25c.552 0 1-.56 1-1.25S10.552 5 10 5s-1 .56-1 1.25.448 1.25 1 1.25m2.98 3.25A1.5 1.5 0 0111.5 12h-7a1.5 1.5 0 01-1.48-1.747v-.003A1.5 1.5 0 014.5 9h7a1.5 1.5 0 011.48 1.747zm-8.48.75h.25v-.75H3.531a1 1 0 00.969.75m7 0a1 1 0 00.969-.75H11.25v.75zm.969-1.25a1 1 0 00-.969-.75h-.25v.75zM4.5 9.5a1 1 0 00-.969.75H4.75V9.5zm1.75 2v-.75h-1v.75zm.5 0h1v-.75h-1zm1.5 0h1v-.75h-1zm1.5 0h1v-.75h-1zm1-2h-1v.75h1zm-1.5 0h-1v.75h1zm-1.5 0h-1v.75h1zm-1.5 0h-1v.75h1z"/><path pid="1" d="M8 16A8 8 0 108 0a8 8 0 000 16m0-1A7 7 0 118 1a7 7 0 010 14"/>',
    },
});
