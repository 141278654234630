/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    crosshair: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5.5a.5.5 0 00-1 0v.518A7 7 0 001.018 7.5H.5a.5.5 0 000 1h.518A7 7 0 007.5 14.982v.518a.5.5 0 001 0v-.518A7 7 0 0014.982 8.5h.518a.5.5 0 000-1h-.518A7 7 0 008.5 1.018zm-6.48 7A6 6 0 017.5 2.02v.48a.5.5 0 001 0v-.48a6 6 0 015.48 5.48h-.48a.5.5 0 000 1h.48a6 6 0 01-5.48 5.48v-.48a.5.5 0 00-1 0v.48A6 6 0 012.02 8.5h.48a.5.5 0 000-1zM8 10a2 2 0 100-4 2 2 0 000 4"/>',
    },
});
