/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'usb-micro-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.707 4A1 1 0 002 4.293L.293 6A1 1 0 000 6.707V11a1 1 0 001 1h14a1 1 0 001-1V6.707A1 1 0 0015.707 6L14 4.293A1 1 0 0013.293 4zM4.5 7h7a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5"/>',
    },
});
