/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cloud-slash-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M3.112 5.112a3 3 0 00-.17.613C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13H11zm11.372 7.372L4.937 2.937A5.5 5.5 0 018 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773a3.2 3.2 0 01-1.516 2.711m-.838 1.87l-12-12 .708-.708 12 12z"/>',
    },
});
