/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    link: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.354 5.5H4a3 3 0 000 6h3a3 3 0 002.83-4H9q-.13 0-.25.031A2 2 0 017 10.5H4a2 2 0 110-4h1.535c.218-.376.495-.714.82-1z"/><path pid="1" d="M9 5.5a3 3 0 00-2.83 4h1.098A2 2 0 019 6.5h3a2 2 0 110 4h-1.535a4 4 0 01-.82 1H12a3 3 0 100-6z"/>',
    },
});
