/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    thunderbolt: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.5 7a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z"/><path pid="1" d="M1 3a1 1 0 00-1 1v7.293A1 1 0 00.293 12L2 13.707a1 1 0 00.707.293h10.586a1 1 0 00.707-.293L15.707 12a1 1 0 00.293-.707V4a1 1 0 00-1-1zm0 1h14v7.293L13.293 13H2.707L1 11.293z"/>',
    },
});
