/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'hospital-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6 0a1 1 0 00-1 1v1a1 1 0 00-1 1v4H1a1 1 0 00-1 1v7a1 1 0 001 1h6v-2.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5V16h6a1 1 0 001-1V8a1 1 0 00-1-1h-3V3a1 1 0 00-1-1V1a1 1 0 00-1-1zm2.5 5.034v1.1l.953-.55.5.867L9 7l.953.55-.5.866-.953-.55v1.1h-1v-1.1l-.953.55-.5-.866L7 7l-.953-.55.5-.866.953.55v-1.1zM2.25 9h.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-.5A.25.25 0 012 9.75v-.5A.25.25 0 012.25 9m0 2h.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25M2 13.25a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25zM13.25 9h.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25M13 11.25a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25zm.25 1.75h.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25"/>',
    },
});
