/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    receipt: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1.92.506a.5.5 0 01.434.14L3 1.293l.646-.647a.5.5 0 01.708 0L5 1.293l.646-.647a.5.5 0 01.708 0L7 1.293l.646-.647a.5.5 0 01.708 0L9 1.293l.646-.647a.5.5 0 01.708 0l.646.647.646-.647a.5.5 0 01.708 0l.646.647.646-.647a.5.5 0 01.801.13l.5 1A.5.5 0 0115 2v12a.5.5 0 01-.053.224l-.5 1a.5.5 0 01-.8.13L13 14.707l-.646.647a.5.5 0 01-.708 0L11 14.707l-.646.647a.5.5 0 01-.708 0L9 14.707l-.646.647a.5.5 0 01-.708 0L7 14.707l-.646.647a.5.5 0 01-.708 0L5 14.707l-.646.647a.5.5 0 01-.708 0L3 14.707l-.646.647a.5.5 0 01-.801-.13l-.5-1A.5.5 0 011 14V2a.5.5 0 01.053-.224l.5-1a.5.5 0 01.367-.27m.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 01.707 0l.646.647.646-.646a.5.5 0 01.708 0l.646.646.646-.646a.5.5 0 01.708 0l.646.646.646-.646a.5.5 0 01.708 0l.646.646.646-.646a.5.5 0 01.708 0l.646.646.646-.646a.5.5 0 01.708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 01-.707 0L12 1.707l-.646.647a.5.5 0 01-.708 0L10 1.707l-.646.647a.5.5 0 01-.708 0L8 1.707l-.646.647a.5.5 0 01-.708 0L6 1.707l-.646.647a.5.5 0 01-.708 0L4 1.707l-.646.647a.5.5 0 01-.708 0z"/><path pid="1" d="M3 4.5a.5.5 0 01.5-.5h6a.5.5 0 110 1h-6a.5.5 0 01-.5-.5m0 2a.5.5 0 01.5-.5h6a.5.5 0 110 1h-6a.5.5 0 01-.5-.5m0 2a.5.5 0 01.5-.5h6a.5.5 0 110 1h-6a.5.5 0 01-.5-.5m0 2a.5.5 0 01.5-.5h6a.5.5 0 010 1h-6a.5.5 0 01-.5-.5m8-6a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5m0 2a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5m0 2a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5m0 2a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5"/>',
    },
});
