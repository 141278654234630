/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-aac': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2v-1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zm-5.808 8.554a1.7 1.7 0 00-.103.633v.495q0 .369.103.627a.83.83 0 00.299.393.85.85 0 00.477.131.9.9 0 00.402-.088.7.7 0 00.272-.248.8.8 0 00.117-.364h.765v.076a1.27 1.27 0 01-.226.674q-.204.29-.55.454a1.8 1.8 0 01-.785.164q-.54 0-.915-.216a1.4 1.4 0 01-.57-.627q-.195-.408-.194-.976v-.498q0-.568.196-.978.195-.411.571-.633.378-.223.912-.223.327 0 .606.097.28.093.49.272a1.33 1.33 0 01.465.964v.073h-.765a.85.85 0 00-.12-.38.7.7 0 00-.272-.261.8.8 0 00-.399-.097.8.8 0 00-.474.138.87.87 0 00-.302.398M.8 15.925l.313-1.028H2.45l.314 1.028h.84l-1.335-3.999h-.926l-1.342 4zm1.002-3.234l.489 1.617H1.277l.49-1.617zm2.63 3.234l.313-1.028H6.08l.313 1.028h.841L5.9 11.926h-.926l-1.341 4zm1.001-3.234l.49 1.617H4.909l.49-1.617z"/>',
    },
});
