/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'door-open': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1"/><path pid="1" d="M10.828.122A.5.5 0 0111 .5V1h.5A1.5 1.5 0 0113 2.5V15h1.5a.5.5 0 010 1h-13a.5.5 0 010-1H3V1.5a.5.5 0 01.43-.495l7-1a.5.5 0 01.398.117M11.5 2H11v13h1V2.5a.5.5 0 00-.5-.5M4 1.934V15h6V1.077z"/>',
    },
});
