/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cup-hot-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M.5 6a.5.5 0 00-.488.608l1.652 7.434A2.5 2.5 0 004.104 16h5.792a2.5 2.5 0 002.44-1.958l.131-.59a3 3 0 001.3-5.854l.221-.99A.5.5 0 0013.5 6zM13 12.5a2 2 0 01-.316-.025l.867-3.898A2.001 2.001 0 0113 12.5"/><path pid="1" d="M4.4.8l-.003.004-.014.019a4 4 0 00-.204.31 2 2 0 00-.141.267c-.026.06-.034.092-.037.103v.004a.6.6 0 00.091.248c.075.133.178.272.308.445l.01.012c.118.158.26.347.37.543.112.2.22.455.22.745 0 .188-.065.368-.119.494a3 3 0 01-.202.388 5 5 0 01-.253.382l-.018.025-.005.008-.002.002A.5.5 0 013.6 4.2l.003-.004.014-.019a4 4 0 00.204-.31 2 2 0 00.141-.267c.026-.06.034-.092.037-.103a.6.6 0 00-.09-.252A4 4 0 003.6 2.8l-.01-.012a5 5 0 01-.37-.543A1.53 1.53 0 013 1.5c0-.188.065-.368.119-.494.059-.138.134-.274.202-.388a6 6 0 01.253-.382l.025-.035A.5.5 0 014.4.8m3 0l-.003.004-.014.019a4 4 0 00-.204.31 2 2 0 00-.141.267c-.026.06-.034.092-.037.103v.004a.6.6 0 00.091.248c.075.133.178.272.308.445l.01.012c.118.158.26.347.37.543.112.2.22.455.22.745 0 .188-.065.368-.119.494a3 3 0 01-.202.388 5 5 0 01-.253.382l-.018.025-.005.008-.002.002A.5.5 0 016.6 4.2l.003-.004.014-.019a4 4 0 00.204-.31 2 2 0 00.141-.267c.026-.06.034-.092.037-.103a.6.6 0 00-.09-.252A4 4 0 006.6 2.8l-.01-.012a5 5 0 01-.37-.543A1.53 1.53 0 016 1.5c0-.188.065-.368.119-.494.059-.138.134-.274.202-.388a6 6 0 01.253-.382l.025-.035A.5.5 0 017.4.8m3 0l-.003.004-.014.019a4 4 0 00-.204.31 2 2 0 00-.141.267c-.026.06-.034.092-.037.103v.004a.6.6 0 00.091.248c.075.133.178.272.308.445l.01.012c.118.158.26.347.37.543.112.2.22.455.22.745 0 .188-.065.368-.119.494a3 3 0 01-.202.388 5 5 0 01-.252.382l-.019.025-.005.008-.002.002A.5.5 0 019.6 4.2l.003-.004.014-.019a4 4 0 00.204-.31 2 2 0 00.141-.267c.026-.06.034-.092.037-.103a.6.6 0 00-.09-.252A4 4 0 009.6 2.8l-.01-.012a5 5 0 01-.37-.543A1.53 1.53 0 019 1.5c0-.188.065-.368.119-.494.059-.138.134-.274.202-.388a6 6 0 01.253-.382l.025-.035A.5.5 0 0110.4.8"/>',
    },
});
