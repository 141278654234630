/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'textarea-t': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1.5 2.5A1.5 1.5 0 013 1h10a1.5 1.5 0 011.5 1.5v3.563a2 2 0 010 3.874V13.5A1.5 1.5 0 0113 15H3a1.5 1.5 0 01-1.5-1.5V9.937a2 2 0 010-3.874zm1 3.563a2 2 0 010 3.874V13.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V9.937a2 2 0 010-3.874V2.5A.5.5 0 0013 2H3a.5.5 0 00-.5.5zM2 7a1 1 0 100 2 1 1 0 000-2m12 0a1 1 0 100 2 1 1 0 000-2"/><path pid="1" d="M11.434 4H4.566L4.5 5.994h.386c.21-1.252.612-1.446 2.173-1.495l.343-.011v6.343c0 .537-.116.665-1.049.748V12h3.294v-.421c-.938-.083-1.054-.21-1.054-.748V4.488l.348.01c1.56.05 1.963.244 2.173 1.496h.386z"/>',
    },
});
