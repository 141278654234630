/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-grimace-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 8A8 8 0 110 8a8 8 0 0116 0M7 6.25C7 5.56 6.552 5 6 5s-1 .56-1 1.25.448 1.25 1 1.25 1-.56 1-1.25m3 1.25c.552 0 1-.56 1-1.25S10.552 5 10 5s-1 .56-1 1.25.448 1.25 1 1.25m1.5 4.5a1.5 1.5 0 001.48-1.25v-.003a1.5 1.5 0 000-.497A1.5 1.5 0 0011.5 9h-7a1.5 1.5 0 00-1.48 1.25v.003a1.5 1.5 0 000 .497A1.5 1.5 0 004.5 12zm-7.969-1.25a1 1 0 00.969.75h.25v-.75zm8.938 0a1 1 0 01-.969.75h-.25v-.75zM11.5 9.5a1 1 0 01.969.75H11.25V9.5zm-7.969.75A1 1 0 014.5 9.5h.25v.75zM5.25 11.5h1v-.75h-1zm2.5 0h-1v-.75h1zm1.5 0h-1v-.75h1zm1.5 0h-1v-.75h1zm-1-2h1v.75h-1zm-1.5 0h1v.75h-1zm-1.5 0h1v.75h-1zm-1.5 0h1v.75h-1z"/>',
    },
});
