/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'window-desktop': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.5 11a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z"/><path pid="1" d="M2.375 1A2.366 2.366 0 000 3.357v9.286A2.366 2.366 0 002.375 15h11.25A2.366 2.366 0 0016 12.643V3.357A2.366 2.366 0 0013.625 1zM1 3.357C1 2.612 1.611 2 2.375 2h11.25C14.389 2 15 2.612 15 3.357V4H1zM1 5h14v7.643c0 .745-.611 1.357-1.375 1.357H2.375A1.366 1.366 0 011 12.643z"/>',
    },
});
