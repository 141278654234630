/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'node-minus': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M11 4a4 4 0 100 8 4 4 0 000-8M6.025 7.5a5 5 0 110 1H4A1.5 1.5 0 012.5 10h-1A1.5 1.5 0 010 8.5v-1A1.5 1.5 0 011.5 6h1A1.5 1.5 0 014 7.5zM1.5 7a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM8 8a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5A.5.5 0 018 8"/>',
    },
});
