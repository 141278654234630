/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    valentine2: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 6.493c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132M4.25 3a.25.25 0 00-.25.25v1.5a.25.25 0 00.5 0V3.5h1.25a.25.25 0 000-.5zm6 0a.25.25 0 100 .5h1.25v1.25a.25.25 0 10.5 0v-1.5a.25.25 0 00-.25-.25zM4.5 12.25a.25.25 0 10-.5 0v1.5c0 .138.112.25.25.25h1.5a.25.25 0 000-.5H4.5zm7.5 0a.25.25 0 10-.5 0v1.25h-1.25a.25.25 0 100 .5h1.5a.25.25 0 00.25-.25z"/><path pid="1" fill-rule="evenodd" d="M2 1.994v-.042a1 1 0 01.9-.995l9-.9A1 1 0 0113 1a1 1 0 011 1v13a1 1 0 01-1 1H3a1 1 0 01-1-1zM3 2v13h10V2z"/>',
    },
});
