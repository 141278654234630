/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'unlock-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11 1a2 2 0 00-2 2v4a2 2 0 012 2v5a2 2 0 01-2 2H3a2 2 0 01-2-2V9a2 2 0 012-2h5V3a3 3 0 016 0v4a.5.5 0 01-1 0V3a2 2 0 00-2-2"/>',
    },
});
