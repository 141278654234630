/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    cast: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.646 9.354l-3.792 3.792a.5.5 0 00.353.854h7.586a.5.5 0 00.354-.854L8.354 9.354a.5.5 0 00-.708 0"/><path pid="1" d="M11.414 11H14.5a.5.5 0 00.5-.5v-7a.5.5 0 00-.5-.5h-13a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h3.086l-1 1H1.5A1.5 1.5 0 010 10.5v-7A1.5 1.5 0 011.5 2h13A1.5 1.5 0 0116 3.5v7a1.5 1.5 0 01-1.5 1.5h-2.086z"/>',
    },
});
