/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'circle-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<circle pid="0" cx="8" cy="8" r="8"/>',
    },
});
