/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'shield-slash-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M1.093 3.093c-.465 4.275.885 7.46 2.513 9.589a11.8 11.8 0 002.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7 7 0 001.048-.625 11.3 11.3 0 001.733-1.525zm12.215 8.215L3.128 1.128A61 61 0 015.073.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 011.044 1.262c.483 3.626-.332 6.491-1.551 8.616m.338 3.046l-13-13 .708-.708 13 13z"/>',
    },
});
