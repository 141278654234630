/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    basket: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.757 1.071a.5.5 0 01.172.686L3.383 6h9.234L10.07 1.757a.5.5 0 11.858-.514L13.783 6H15a1 1 0 011 1v1a1 1 0 01-1 1v4.5a2.5 2.5 0 01-2.5 2.5h-9A2.5 2.5 0 011 13.5V9a1 1 0 01-1-1V7a1 1 0 011-1h1.217L5.07 1.243a.5.5 0 01.686-.172zM2 9v4.5A1.5 1.5 0 003.5 15h9a1.5 1.5 0 001.5-1.5V9zM1 7v1h14V7zm3 3a.5.5 0 01.5.5v3a.5.5 0 01-1 0v-3A.5.5 0 014 10m2 0a.5.5 0 01.5.5v3a.5.5 0 01-1 0v-3A.5.5 0 016 10m2 0a.5.5 0 01.5.5v3a.5.5 0 01-1 0v-3A.5.5 0 018 10m2 0a.5.5 0 01.5.5v3a.5.5 0 01-1 0v-3a.5.5 0 01.5-.5m2 0a.5.5 0 01.5.5v3a.5.5 0 01-1 0v-3a.5.5 0 01.5-.5"/>',
    },
});
