/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    record2: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 12a4 4 0 110-8 4 4 0 010 8m0 1A5 5 0 108 3a5 5 0 000 10"/><path pid="1" d="M10 8a2 2 0 11-4 0 2 2 0 014 0"/>',
    },
});
