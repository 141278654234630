/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'qr-code-scan': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 .5A.5.5 0 01.5 0h3a.5.5 0 010 1H1v2.5a.5.5 0 01-1 0zm12 0a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v3a.5.5 0 01-1 0V1h-2.5a.5.5 0 01-.5-.5M.5 12a.5.5 0 01.5.5V15h2.5a.5.5 0 010 1h-3a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5m15 0a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-3a.5.5 0 010-1H15v-2.5a.5.5 0 01.5-.5M4 4h1v1H4z"/><path pid="1" d="M7 2H2v5h5zM3 3h3v3H3zm2 8H4v1h1z"/><path pid="2" d="M7 9H2v5h5zm-4 1h3v3H3zm8-6h1v1h-1z"/><path pid="3" d="M9 2h5v5H9zm1 1v3h3V3zM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8zm2 2H9V9h1zm4 2h-1v1h-2v1h3zm-4 2v-1H8v1z"/><path pid="4" d="M12 9h2V8h-2z"/>',
    },
});
