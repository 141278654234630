/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    bank2: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.277.084a.5.5 0 00-.554 0l-7.5 5A.5.5 0 00.5 6h1.875v7H1.5a.5.5 0 000 1h13a.5.5 0 100-1h-.875V6H15.5a.5.5 0 00.277-.916zM12.375 6v7h-1.25V6zm-2.5 0v7h-1.25V6zm-2.5 0v7h-1.25V6zm-2.5 0v7h-1.25V6zM8 4a1 1 0 110-2 1 1 0 010 2M.5 15a.5.5 0 000 1h15a.5.5 0 100-1z"/>',
    },
});
