/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'bag-dash-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M10.5 3.5a2.5 2.5 0 00-5 0V4h5zm1 0V4H15v10a2 2 0 01-2 2H3a2 2 0 01-2-2V4h3.5v-.5a3.5 3.5 0 117 0M6 9.5a.5.5 0 000 1h4a.5.5 0 000-1z"/>',
    },
});
