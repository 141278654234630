/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'ticket-perforated-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 4.5A1.5 1.5 0 011.5 3h13A1.5 1.5 0 0116 4.5V6a.5.5 0 01-.5.5 1.5 1.5 0 000 3 .5.5 0 01.5.5v1.5a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 010 11.5V10a.5.5 0 01.5-.5 1.5 1.5 0 100-3A.5.5 0 010 6zm4-1v1h1v-1zm1 3v-1H4v1zm7 0v-1h-1v1zm-1-2h1v-1h-1zm-6 3H4v1h1zm7 1v-1h-1v1zm-7 1H4v1h1zm7 1v-1h-1v1zm-8 1v1h1v-1zm7 1h1v-1h-1z"/>',
    },
});
