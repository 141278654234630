/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'layer-backward': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.354 15.854a.5.5 0 01-.708 0l-3-3a.5.5 0 010-.708l1-1a.5.5 0 01.708 0l.646.647V4H1a1 1 0 01-1-1V1a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H9v7.793l.646-.647a.5.5 0 01.708 0l1 1a.5.5 0 010 .708z"/><path pid="1" d="M1 9a1 1 0 01-1-1V6a1 1 0 011-1h4.5a.5.5 0 010 1H1v2h4.5a.5.5 0 010 1zm9.5 0a.5.5 0 010-1H15V6h-4.5a.5.5 0 010-1H15a1 1 0 011 1v2a1 1 0 01-1 1z"/>',
    },
});
