/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-jpg': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2h-1v-1h1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zm-4.34 8.132q.114.23.14.492h-.776a.8.8 0 00-.097-.249.7.7 0 00-.17-.19.7.7 0 00-.237-.126 1 1 0 00-.299-.044q-.428 0-.665.302-.234.301-.234.85v.498q0 .351.097.615a.9.9 0 00.304.413.87.87 0 00.519.146 1 1 0 00.457-.096.67.67 0 00.272-.264q.09-.164.091-.363v-.255H8.24v-.59h1.576v.798q0 .29-.097.55a1.3 1.3 0 01-.293.458 1.4 1.4 0 01-.495.313q-.296.111-.697.111a2 2 0 01-.753-.132 1.45 1.45 0 01-.533-.377 1.6 1.6 0 01-.32-.58 2.5 2.5 0 01-.105-.745v-.506q0-.543.2-.95.201-.406.582-.633.384-.228.926-.228.357 0 .636.1.28.1.48.275t.314.407zM0 14.786q0 .246.082.465.083.22.243.39.165.17.407.267.246.093.569.093.63 0 .984-.345.357-.346.358-1.005v-2.725h-.791v2.745q0 .303-.138.466t-.422.164a.5.5 0 01-.454-.246.6.6 0 01-.073-.27H0zm4.92-2.86H3.322v4h.791v-1.343h.803q.43 0 .732-.172.305-.177.463-.475.162-.302.161-.677 0-.374-.158-.677a1.2 1.2 0 00-.46-.477q-.3-.18-.732-.179zm.546 1.333a.8.8 0 01-.085.381.57.57 0 01-.238.24.8.8 0 01-.375.082H4.11v-1.406h.66q.327 0 .512.182.185.181.185.521z"/>',
    },
});
