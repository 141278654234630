/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    projector: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M14 7.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0M2.5 6a.5.5 0 000 1h4a.5.5 0 000-1zm0 2a.5.5 0 000 1h4a.5.5 0 000-1z"/><path pid="1" d="M0 6a2 2 0 012-2h12a2 2 0 012 2v3a2 2 0 01-2 2 1 1 0 01-1 1h-1a1 1 0 01-1-1H5a1 1 0 01-1 1H3a1 1 0 01-1-1 2 2 0 01-2-2zm2-1a1 1 0 00-1 1v3a1 1 0 001 1h12a1 1 0 001-1V6a1 1 0 00-1-1z"/>',
    },
});
