/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    stickies: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1.5 0A1.5 1.5 0 000 1.5V13a1 1 0 001 1V1.5a.5.5 0 01.5-.5H14a1 1 0 00-1-1z"/><path pid="1" d="M3.5 2A1.5 1.5 0 002 3.5v11A1.5 1.5 0 003.5 16h6.086a1.5 1.5 0 001.06-.44l4.915-4.914A1.5 1.5 0 0016 9.586V3.5A1.5 1.5 0 0014.5 2zM3 3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5V9h-4.5A1.5 1.5 0 009 10.5V15H3.5a.5.5 0 01-.5-.5zm7 11.293V10.5a.5.5 0 01.5-.5h4.293z"/>',
    },
});
