/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    textarea: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1.5 2.5A1.5 1.5 0 013 1h10a1.5 1.5 0 011.5 1.5v3.563a2 2 0 010 3.874V13.5A1.5 1.5 0 0113 15H3a1.5 1.5 0 01-1.5-1.5V9.937a2 2 0 010-3.874zm1 3.563a2 2 0 010 3.874V13.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V9.937a2 2 0 010-3.874V2.5A.5.5 0 0013 2H3a.5.5 0 00-.5.5zM2 7a1 1 0 100 2 1 1 0 000-2m12 0a1 1 0 100 2 1 1 0 000-2"/>',
    },
});
