/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    magnet: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 1a7 7 0 00-7 7v3h4V8a3 3 0 016 0v3h4V8a7 7 0 00-7-7m7 11h-4v3h4zM5 12H1v3h4zM0 8a8 8 0 1116 0v8h-6V8a2 2 0 10-4 0v8H0z"/>',
    },
});
