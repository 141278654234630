/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'suit-club-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11.5 12.5a3.5 3.5 0 01-2.684-1.254 20 20 0 001.582 2.907c.231.35-.02.847-.438.847H6.04c-.419 0-.67-.497-.438-.847a20 20 0 001.582-2.907 3.5 3.5 0 11-2.538-5.743 3.5 3.5 0 116.708 0A3.5 3.5 0 1111.5 12.5"/>',
    },
});
