/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'distribute-vertical': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M1 1.5a.5.5 0 00.5.5h13a.5.5 0 000-1h-13a.5.5 0 00-.5.5m0 13a.5.5 0 00.5.5h13a.5.5 0 000-1h-13a.5.5 0 00-.5.5"/><path pid="1" d="M2 7a1 1 0 011-1h10a1 1 0 011 1v2a1 1 0 01-1 1H3a1 1 0 01-1-1z"/>',
    },
});
