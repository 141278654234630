/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'device-ssd': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4.75 4a.75.75 0 00-.75.75v3.5c0 .414.336.75.75.75h6.5a.75.75 0 00.75-.75v-3.5a.75.75 0 00-.75-.75zM5 8V5h6v3zm0-5.5a.5.5 0 11-1 0 .5.5 0 011 0m7 0a.5.5 0 11-1 0 .5.5 0 011 0M4.5 11a.5.5 0 100-1 .5.5 0 000 1m7 0a.5.5 0 100-1 .5.5 0 000 1"/><path pid="1" d="M2 2a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2zm11 12V2a1 1 0 00-1-1H4a1 1 0 00-1 1v12a1 1 0 001 1v-2a1 1 0 011-1h6a1 1 0 011 1v2a1 1 0 001-1m-7.25 1v-2H5v2zm1.75 0v-2h-.75v2zm1.75 0v-2H8.5v2zM11 13h-.75v2H11z"/>',
    },
});
