/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'arrows-collapse-vertical': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 15a.5.5 0 01-.5-.5v-13a.5.5 0 011 0v13a.5.5 0 01-.5.5M0 8a.5.5 0 01.5-.5h3.793L3.146 6.354a.5.5 0 11.708-.708l2 2a.5.5 0 010 .708l-2 2a.5.5 0 01-.708-.708L4.293 8.5H.5A.5.5 0 010 8m11.707.5l1.147 1.146a.5.5 0 01-.708.708l-2-2a.5.5 0 010-.708l2-2a.5.5 0 01.708.708L11.707 7.5H15.5a.5.5 0 010 1z"/>',
    },
});
