/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cart-plus': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9 5.5a.5.5 0 00-1 0V7H6.5a.5.5 0 000 1H8v1.5a.5.5 0 001 0V8h1.5a.5.5 0 000-1H9z"/><path pid="1" d="M.5 1a.5.5 0 000 1h1.11l.401 1.607 1.498 7.985A.5.5 0 004 12h1a2 2 0 100 4 2 2 0 000-4h7a2 2 0 100 4 2 2 0 000-4h1a.5.5 0 00.491-.408l1.5-8A.5.5 0 0014.5 3H2.89l-.405-1.621A.5.5 0 002 1zm3.915 10L3.102 4h10.796l-1.313 7zM6 14a1 1 0 11-2 0 1 1 0 012 0m7 0a1 1 0 11-2 0 1 1 0 012 0"/>',
    },
});
