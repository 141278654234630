/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    stoplights: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 5a1.5 1.5 0 100-3 1.5 1.5 0 000 3m0 4a1.5 1.5 0 100-3 1.5 1.5 0 000 3m1.5 2.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0"/><path pid="1" d="M4 2a2 2 0 012-2h4a2 2 0 012 2h2c-.167.5-.8 1.6-2 2v2h2c-.167.5-.8 1.6-2 2v2h2c-.167.5-.8 1.6-2 2v1a2 2 0 01-2 2H6a2 2 0 01-2-2v-1c-1.2-.4-1.833-1.5-2-2h2V8c-1.2-.4-1.833-1.5-2-2h2V4c-1.2-.4-1.833-1.5-2-2zm2-1a1 1 0 00-1 1v11a1 1 0 001 1h4a1 1 0 001-1V2a1 1 0 00-1-1z"/>',
    },
});
