/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'music-note': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9 13c0 1.105-1.12 2-2.5 2S4 14.105 4 13s1.12-2 2.5-2 2.5.895 2.5 2"/><path pid="1" fill-rule="evenodd" d="M9 3v10H8V3z"/><path pid="2" d="M8 2.82a1 1 0 01.804-.98l3-.6A1 1 0 0113 2.22V4L8 5z"/>',
    },
});
