/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'headset-vr': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 1.248c1.857 0 3.526.641 4.65 1.794a5 5 0 012.518 1.09C13.907 1.482 11.295 0 8 0 4.75 0 2.12 1.48.844 4.122a5 5 0 012.289-1.047C4.236 1.872 5.974 1.248 8 1.248"/><path pid="1" d="M12 12a4 4 0 01-2.786-1.13l-.002-.002a1.6 1.6 0 00-.276-.167A2.2 2.2 0 008 10.5c-.414 0-.729.103-.935.201a1.6 1.6 0 00-.277.167l-.002.002A4 4 0 114 4h8a4 4 0 010 8"/>',
    },
});
