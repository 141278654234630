/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'clipboard2-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.5 0a.5.5 0 01.5.5.5.5 0 00.5.5.5.5 0 01.5.5V2a.5.5 0 01-.5.5h-5A.5.5 0 015 2v-.5a.5.5 0 01.5-.5.5.5 0 00.5-.5.5.5 0 01.5-.5z"/><path pid="1" d="M3.5 1h.585A1.5 1.5 0 004 1.5V2a1.5 1.5 0 001.5 1.5h5A1.5 1.5 0 0012 2v-.5q-.001-.264-.085-.5h.585A1.5 1.5 0 0114 2.5v12a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 012 14.5v-12A1.5 1.5 0 013.5 1"/>',
    },
});
