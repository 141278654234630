/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-heart-eyes-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 0a8 8 0 100 16A8 8 0 008 0M4.756 4.566c.763-1.424 4.02-.12.952 3.434-4.496-1.596-2.35-4.298-.952-3.434m6.559 5.448a.5.5 0 01.548.736A4.5 4.5 0 017.965 13a4.5 4.5 0 01-3.898-2.25.5.5 0 01.548-.736h.005l.017.005.067.015.252.055c.215.046.515.108.857.169.693.124 1.522.242 2.152.242s1.46-.118 2.152-.242a27 27 0 001.109-.224l.067-.015.017-.004.005-.002zm-.07-5.448c1.397-.864 3.543 1.838-.953 3.434-3.067-3.554.19-4.858.952-3.434z"/>',
    },
});
