/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cassette-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1.5 2A1.5 1.5 0 000 3.5v9A1.5 1.5 0 001.5 14h.191l1.862-3.724A.5.5 0 014 10h8a.5.5 0 01.447.276L14.31 14h.191a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0014.5 2zM4 7a1 1 0 110-2 1 1 0 010 2m8 0a1 1 0 110-2 1 1 0 010 2M6 6a1 1 0 011-1h2a1 1 0 010 2H7a1 1 0 01-1-1"/><path pid="1" d="M13.191 14l-1.5-3H4.309l-1.5 3z"/>',
    },
});
