/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    bing: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.35 5.046a.615.615 0 00-.54.575c-.009.13-.006.14.289.899.67 1.727.833 2.142.86 2.2q.101.215.277.395c.089.092.148.141.247.208.176.117.262.15.944.351.664.197 1.026.327 1.338.482.405.201.688.43.866.7.128.195.242.544.291.896.02.137.02.44 0 .564-.041.27-.124.495-.252.684-.067.1-.044.084.055-.039.278-.346.562-.938.707-1.475a4.42 4.42 0 00-2.14-5.028 70 70 0 00-.888-.465l-.53-.277-.353-.184c-.16-.082-.266-.138-.345-.18-.368-.192-.523-.27-.568-.283a1 1 0 00-.194-.03z"/><path pid="1" d="M9.152 11.493a3 3 0 00-.135.083 320 320 0 00-1.513.934l-.8.496c-.012.01-.587.367-.876.543a1.9 1.9 0 01-.732.257c-.12.017-.349.017-.47 0a1.9 1.9 0 01-.884-.358 2.5 2.5 0 01-.365-.364 1.9 1.9 0 01-.34-.76 1 1 0 00-.027-.121c-.005-.006.004.092.022.22.018.132.057.324.098.489a4.1 4.1 0 002.487 2.796c.359.142.72.23 1.114.275.147.016.566.023.72.011a4.1 4.1 0 001.956-.661l.235-.149.394-.248.258-.163 1.164-.736c.51-.32.663-.433.9-.665.099-.097.248-.262.255-.283.002-.005.028-.046.059-.091a1.64 1.64 0 00.25-.682c.02-.124.02-.427 0-.565a3 3 0 00-.213-.758c-.15-.314-.47-.6-.928-.83a2 2 0 00-.273-.12c-.006 0-.433.26-.948.58l-1.113.687z"/><path pid="2" d="M3.004 12.184l.03.129c.089.402.245.693.515.963a1.82 1.82 0 001.312.543c.361 0 .673-.09.994-.287l.472-.29.373-.23V5.334c0-1.537-.003-2.45-.008-2.521a1.82 1.82 0 00-.535-1.177c-.097-.096-.18-.16-.427-.33L4.183.24c-.239-.163-.258-.175-.33-.2a.63.63 0 00-.842.464c-.009.042-.01.603-.01 3.646l.003 8.035z"/>',
    },
});
