/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-badge-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 2a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2zm4.5 0a.5.5 0 000 1h3a.5.5 0 000-1zM8 11a3 3 0 100-6 3 3 0 000 6m5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 001 1h8a1 1 0 001-1z"/>',
    },
});
