/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'search-heart': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.5 4.482c1.664-1.673 5.825 1.254 0 5.018-5.825-3.764-1.664-6.69 0-5.018"/><path pid="1" d="M13 6.5a6.47 6.47 0 01-1.258 3.844q.06.044.115.098l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 01-.1-.115h.002A6.5 6.5 0 1113 6.5M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11"/>',
    },
});
