/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    hr: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12 3H4a1 1 0 00-1 1v2.5H2V4a2 2 0 012-2h8a2 2 0 012 2v2.5h-1V4a1 1 0 00-1-1M2 9.5h1V12a1 1 0 001 1h8a1 1 0 001-1V9.5h1V12a2 2 0 01-2 2H4a2 2 0 01-2-2zm-1.5-2a.5.5 0 000 1h15a.5.5 0 000-1z"/>',
    },
});
