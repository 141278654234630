/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'envelope-arrow-down': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 4a2 2 0 012-2h12a2 2 0 012 2v4.5a.5.5 0 01-1 0V5.383l-7 4.2-1.326-.795-5.64 3.47A1 1 0 002 13h5.5a.5.5 0 010 1H2a2 2 0 01-2-1.99zm1 7.105l4.708-2.897L1 5.383zM1 4v.217l7 4.2 7-4.2V4a1 1 0 00-1-1H2a1 1 0 00-1 1"/><path pid="1" d="M12.5 16a3.5 3.5 0 100-7 3.5 3.5 0 000 7m.354-1.646a.5.5 0 01-.722-.016l-1.149-1.25a.5.5 0 11.737-.676l.28.305V11a.5.5 0 011 0v1.793l.396-.397a.5.5 0 01.708.708z"/>',
    },
});
