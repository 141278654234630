/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sort-alpha-up-alt': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.96 7H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645z"/><path pid="1" fill-rule="evenodd" d="M10.082 12.629L9.664 14H8.598l1.789-5.332h1.234L13.402 14h-1.12l-.419-1.371zm1.57-.785L11 9.688h-.047l-.652 2.156z"/><path pid="2" d="M4.5 13.5a.5.5 0 01-1 0V3.707L2.354 4.854a.5.5 0 11-.708-.708l2-1.999.007-.007a.5.5 0 01.7.006l2 2a.5.5 0 11-.707.708L4.5 3.707z"/>',
    },
});
