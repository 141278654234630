/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'postcard-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11 8h2V6h-2z"/><path pid="1" d="M0 4a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2zm8.5.5a.5.5 0 00-1 0v7a.5.5 0 001 0zM2 5.5a.5.5 0 00.5.5H6a.5.5 0 000-1H2.5a.5.5 0 00-.5.5M2.5 7a.5.5 0 000 1H6a.5.5 0 000-1zM2 9.5a.5.5 0 00.5.5H6a.5.5 0 000-1H2.5a.5.5 0 00-.5.5m8-4v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5"/>',
    },
});
