/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sign-no-right-turn': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 8A8 8 0 110 8a8 8 0 0116 0m-3.416 5.29L6.596 7.304A1.5 1.5 0 006 8.5V11H5V8.5c0-.765.344-1.45.885-1.908L2.709 3.416a7 7 0 009.874 9.874zm.707-.706A7 7 0 003.417 2.71l3.388 3.388Q7.137 6.001 7.5 6H9V4.534a.25.25 0 01.41-.192l2.36 1.966c.12.1.12.284 0 .384L9.41 8.658l-.026.02zM7.707 7L9 8.293V7z"/>',
    },
});
