/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    peace: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.5 1.018a7 7 0 00-4.79 11.566L7.5 7.793zm1 0v6.775l4.79 4.79A7 7 0 008.5 1.018m4.084 12.273L8.5 9.207v5.775a6.97 6.97 0 004.084-1.691M7.5 14.982V9.207l-4.084 4.084A6.97 6.97 0 007.5 14.982M0 8a8 8 0 1116 0A8 8 0 010 8"/>',
    },
});
