/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-arms-up': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 3a1.5 1.5 0 100-3 1.5 1.5 0 000 3"/><path pid="1" d="M5.93 6.704l-.846 8.451a.768.768 0 001.523.203l.81-4.865a.59.59 0 011.165 0l.81 4.865a.768.768 0 001.523-.203l-.845-8.451A1.5 1.5 0 0110.5 5.5L13 2.284a.796.796 0 00-1.239-.998L9.634 3.84a.7.7 0 01-.33.235c-.23.074-.665.176-1.304.176-.64 0-1.074-.102-1.305-.176a.7.7 0 01-.329-.235L4.239 1.286a.796.796 0 00-1.24.998l2.5 3.216c.317.316.475.758.43 1.204z"/>',
    },
});
