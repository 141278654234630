/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    steam: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M.329 10.333A8.01 8.01 0 007.99 16C12.414 16 16 12.418 16 8s-3.586-8-8.009-8A8.006 8.006 0 000 7.468l.003.006 4.304 1.769A2.2 2.2 0 015.62 8.88l1.96-2.844-.001-.04a3.046 3.046 0 013.042-3.043 3.046 3.046 0 013.042 3.043 3.047 3.047 0 01-3.111 3.044l-2.804 2a2.223 2.223 0 01-3.075 2.11 2.22 2.22 0 01-1.312-1.568L.33 10.333z"/><path pid="1" d="M4.868 12.683a1.715 1.715 0 001.318-3.165 1.7 1.7 0 00-1.263-.02l1.023.424a1.261 1.261 0 11-.97 2.33l-.99-.41a1.7 1.7 0 00.882.84zm3.726-6.687a2.03 2.03 0 002.027 2.029 2.03 2.03 0 002.027-2.029 2.03 2.03 0 00-2.027-2.027 2.03 2.03 0 00-2.027 2.027m2.03-1.527a1.524 1.524 0 11-.002 3.048 1.524 1.524 0 01.002-3.048"/>',
    },
});
