/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'arrow-down-right-circle': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M1 8a7 7 0 1014 0A7 7 0 001 8m15 0A8 8 0 110 8a8 8 0 0116 0M5.854 5.146a.5.5 0 10-.708.708L9.243 9.95H6.475a.5.5 0 100 1h3.975a.5.5 0 00.5-.5V6.475a.5.5 0 10-1 0v2.768z"/>',
    },
});
