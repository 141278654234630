/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'clipboard-heart': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M5 1.5A1.5 1.5 0 016.5 0h3A1.5 1.5 0 0111 1.5v1A1.5 1.5 0 019.5 4h-3A1.5 1.5 0 015 2.5zm5 0a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h3a.5.5 0 00.5-.5z"/><path pid="1" d="M3 1.5h1v1H3a1 1 0 00-1 1V14a1 1 0 001 1h10a1 1 0 001-1V3.5a1 1 0 00-1-1h-1v-1h1a2 2 0 012 2V14a2 2 0 01-2 2H3a2 2 0 01-2-2V3.5a2 2 0 012-2"/><path pid="2" d="M8 6.982C9.664 5.309 13.825 8.236 8 12 2.175 8.236 6.336 5.31 8 6.982"/>',
    },
});
