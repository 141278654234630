/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    gift: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3 2.5a2.5 2.5 0 015 0 2.5 2.5 0 015 0v.006c0 .07 0 .27-.038.494H15a1 1 0 011 1v2a1 1 0 01-1 1v7.5a1.5 1.5 0 01-1.5 1.5h-11A1.5 1.5 0 011 14.5V7a1 1 0 01-1-1V4a1 1 0 011-1h2.038A3 3 0 013 2.506zm1.068.5H7v-.5a1.5 1.5 0 10-3 0c0 .085.002.274.045.43zM9 3h2.932l.023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 00-3 0zM1 4v2h6V4zm8 0v2h6V4zm5 3H9v8h4.5a.5.5 0 00.5-.5zm-7 8V7H2v7.5a.5.5 0 00.5.5z"/>',
    },
});
