/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'truck-flatbed': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11.5 4a.5.5 0 01.5.5V5h1.02a1.5 1.5 0 011.17.563l1.481 1.85a1.5 1.5 0 01.329.938V10.5a1.5 1.5 0 01-1.5 1.5H14a2 2 0 11-4 0H5a2 2 0 11-4 0 1 1 0 01-1-1v-1h11V4.5a.5.5 0 01.5-.5M3 11a1 1 0 100 2 1 1 0 000-2m9 0a1 1 0 100 2 1 1 0 000-2m1.732 0h.768a.5.5 0 00.5-.5V8.35a.5.5 0 00-.11-.312l-1.48-1.85A.5.5 0 0013.02 6H12v4a2 2 0 011.732 1"/>',
    },
});
