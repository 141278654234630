/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'door-closed-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12 1a1 1 0 011 1v13h1.5a.5.5 0 010 1h-13a.5.5 0 010-1H3V2a1 1 0 011-1zm-2 9a1 1 0 100-2 1 1 0 000 2"/>',
    },
});
