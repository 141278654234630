/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-ttf': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2h-2v-1h2a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM1.928 15.849v-3.337h2.269v3.337h.794v-3.337h1.137v-.662H0v.662h1.134v3.337zm5.315-1.59v1.59h-.791V11.85H9v.653H7.243v1.117h1.605v.638z"/>',
    },
});
