/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'envelope-paper-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M6.5 9.5L3 7.5v-6A1.5 1.5 0 014.5 0h7A1.5 1.5 0 0113 1.5v6l-3.5 2L8 8.75zM1.059 3.635L2 3.133v3.753L0 5.713V5.4a2 2 0 011.059-1.765M16 5.713l-2 1.173V3.133l.941.502A2 2 0 0116 5.4zm0 1.16l-5.693 3.337L16 13.372v-6.5zm-8 3.199l7.941 4.412A2 2 0 0114 16H2a2 2 0 01-1.941-1.516zm-8 3.3l5.693-3.162L0 6.873v6.5z"/>',
    },
});
