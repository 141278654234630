/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-plus': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 6.5a.5.5 0 01.5.5v1.5H10a.5.5 0 010 1H8.5V11a.5.5 0 01-1 0V9.5H6a.5.5 0 010-1h1.5V7a.5.5 0 01.5-.5"/><path pid="1" d="M14 4.5V14a2 2 0 01-2 2H4a2 2 0 01-2-2V2a2 2 0 012-2h5.5zm-3 0A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V4.5z"/>',
    },
});
