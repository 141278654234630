/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'envelope-paper-heart': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M2 2a2 2 0 012-2h8a2 2 0 012 2v1.133l.941.502A2 2 0 0116 5.4V14a2 2 0 01-2 2H2a2 2 0 01-2-2V5.4a2 2 0 011.059-1.765L2 3.133zm0 2.267l-.47.25A1 1 0 001 5.4v.817l1 .6zm1 3.15l3.75 2.25L8 8.917l1.25.75L13 7.417V2a1 1 0 00-1-1H4a1 1 0 00-1 1zm11-.6l1-.6V5.4a1 1 0 00-.53-.882L14 4.267zM8 2.982C9.664 1.309 13.825 4.236 8 8 2.175 4.236 6.336 1.31 8 2.982m7 4.401l-4.778 2.867L15 13.117zm-.035 6.88L8 10.082l-6.965 4.18A1 1 0 002 15h12a1 1 0 00.965-.738zM1 13.116l4.778-2.867L1 7.383v5.734z"/>',
    },
});
