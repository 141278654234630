/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'projector-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 4a2 2 0 00-2 2v3a2 2 0 002 2 1 1 0 001 1h1a1 1 0 001-1h6a1 1 0 001 1h1a1 1 0 001-1 2 2 0 002-2V6a2 2 0 00-2-2zm.5 2h4a.5.5 0 010 1h-4a.5.5 0 010-1M14 7.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0m-12 1a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5"/>',
    },
});
