/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-expressionless-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 16A8 8 0 108 0a8 8 0 000 16M4.5 6h2a.5.5 0 010 1h-2a.5.5 0 010-1m5 0h2a.5.5 0 010 1h-2a.5.5 0 010-1m-5 4h7a.5.5 0 010 1h-7a.5.5 0 010-1"/>',
    },
});
