/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'car-front': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 9a1 1 0 11-2 0 1 1 0 012 0m10 0a1 1 0 11-2 0 1 1 0 012 0M6 8a1 1 0 000 2h4a1 1 0 100-2zM4.862 4.276L3.906 6.19a.51.51 0 00.497.731c.91-.073 2.35-.17 3.597-.17s2.688.097 3.597.17a.51.51 0 00.497-.731l-.956-1.913A.5.5 0 0010.691 4H5.309a.5.5 0 00-.447.276"/><path pid="1" d="M2.52 3.515A2.5 2.5 0 014.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679q.05.242.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 01.049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.8.8 0 00.381-.404l.792-1.848zM4.82 3a1.5 1.5 0 00-1.379.91l-.792 1.847a1.8 1.8 0 01-.853.904.8.8 0 00-.43.564L1.03 8.904a1.5 1.5 0 00-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155s4.037-.084 5.592-.155A1.48 1.48 0 0015 9.611v-.413q0-.148-.03-.294l-.335-1.68a.8.8 0 00-.43-.563 1.8 1.8 0 01-.853-.904l-.792-1.848A1.5 1.5 0 0011.18 3z"/>',
    },
});
