/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    command: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.5 2A1.5 1.5 0 015 3.5V5H3.5a1.5 1.5 0 110-3M6 5V3.5A2.5 2.5 0 103.5 6H5v4H3.5A2.5 2.5 0 106 12.5V11h4v1.5a2.5 2.5 0 102.5-2.5H11V6h1.5A2.5 2.5 0 1010 3.5V5zm4 1v4H6V6zm1-1V3.5A1.5 1.5 0 1112.5 5zm0 6h1.5a1.5 1.5 0 11-1.5 1.5zm-6 0v1.5A1.5 1.5 0 113.5 11z"/>',
    },
});
