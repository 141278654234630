/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'app-indicator': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.5 2A3.5 3.5 0 002 5.5v5A3.5 3.5 0 005.5 14h5a3.5 3.5 0 003.5-3.5V8a.5.5 0 011 0v2.5a4.5 4.5 0 01-4.5 4.5h-5A4.5 4.5 0 011 10.5v-5A4.5 4.5 0 015.5 1H8a.5.5 0 010 1z"/><path pid="1" d="M16 3a3 3 0 11-6 0 3 3 0 016 0"/>',
    },
});
