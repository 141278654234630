/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-spreadsheet-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12 0H4a2 2 0 00-2 2v4h12V2a2 2 0 00-2-2m2 7h-4v2h4zm0 3h-4v2h4zm0 3h-4v3h2a2 2 0 002-2zm-5 3v-3H6v3zm-4 0v-3H2v1a2 2 0 002 2zm-3-4h3v-2H2zm0-3h3V7H2zm4 0V7h3v2zm0 1h3v2H6z"/>',
    },
});
