/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    ear: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5 1A4.5 4.5 0 004 5.5v7.047a2.453 2.453 0 004.75.861l.512-1.363a5.6 5.6 0 01.816-1.46l2.008-2.581A4.34 4.34 0 008.66 1zM3 5.5A5.5 5.5 0 018.5 0h.16a5.34 5.34 0 014.215 8.618l-2.008 2.581a4.6 4.6 0 00-.67 1.197l-.51 1.363A3.453 3.453 0 013 12.547zM8.5 4A1.5 1.5 0 007 5.5v2.695q.168-.09.332-.192c.327-.208.577-.44.72-.727a.5.5 0 11.895.448c-.256.513-.673.865-1.079 1.123A9 9 0 017 9.313V11.5a.5.5 0 01-1 0v-6a2.5 2.5 0 015 0V6a.5.5 0 01-1 0v-.5A1.5 1.5 0 008.5 4"/>',
    },
});
