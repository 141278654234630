/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'pc-horizontal': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1 6a1 1 0 00-1 1v3a1 1 0 001 1h14a1 1 0 001-1V7a1 1 0 00-1-1zm11.5 1a.5.5 0 110 1 .5.5 0 010-1m2 0a.5.5 0 110 1 .5.5 0 010-1M1 7.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5M1.25 9h5.5a.25.25 0 010 .5h-5.5a.25.25 0 010-.5"/>',
    },
});
