/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    eraser: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.086 2.207a2 2 0 012.828 0l3.879 3.879a2 2 0 010 2.828l-5.5 5.5A2 2 0 017.879 15H5.12a2 2 0 01-1.414-.586l-2.5-2.5a2 2 0 010-2.828zm2.121.707a1 1 0 00-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 000-1.414zM8.746 13.547L3.453 8.254 1.914 9.793a1 1 0 000 1.414l2.5 2.5a1 1 0 00.707.293H7.88a1 1 0 00.707-.293z"/>',
    },
});
