/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cake-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.399.804l.595-.792.598.79A.747.747 0 018.5 1.806V4H11a2 2 0 012 2v3h1a2 2 0 012 2v4a1 1 0 01-1 1H1a1 1 0 01-1-1v-4a2 2 0 012-2h1V6a2 2 0 012-2h2.5V1.813a.747.747 0 01-.101-1.01zM12 6.414a.9.9 0 01-.646-.268 1.914 1.914 0 00-2.708 0 .914.914 0 01-1.292 0 1.914 1.914 0 00-2.708 0A.9.9 0 014 6.414v1c.49 0 .98-.187 1.354-.56a.914.914 0 011.292 0c.748.747 1.96.747 2.708 0a.914.914 0 011.292 0c.374.373.864.56 1.354.56zm2.646 5.732a.914.914 0 01-1.293 0 1.914 1.914 0 00-2.707 0 .914.914 0 01-1.292 0 1.914 1.914 0 00-2.708 0 .914.914 0 01-1.292 0 1.914 1.914 0 00-2.708 0 .914.914 0 01-1.292 0L1 11.793v1.34c.737.452 1.715.36 2.354-.28a.914.914 0 011.292 0c.748.748 1.96.748 2.708 0a.914.914 0 011.292 0c.748.748 1.96.748 2.707 0a.914.914 0 011.293 0 1.915 1.915 0 002.354.28v-1.34z"/>',
    },
});
