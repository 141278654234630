/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'usb-mini': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.5 8a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z"/><path pid="1" d="M3 3a1 1 0 00-1 1v1.293L.293 7A1 1 0 000 7.707V12a1 1 0 001 1h14a1 1 0 001-1V7.707A1 1 0 0015.707 7L14 5.293V4a1 1 0 00-1-1zm0 1h10v1.293a1 1 0 00.293.707L15 7.707V12H1V7.707L2.707 6A1 1 0 003 5.293z"/>',
    },
});
