/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'box2-heart': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 7.982C9.664 6.309 13.825 9.236 8 13 2.175 9.236 6.336 6.31 8 7.982"/><path pid="1" d="M3.75 0a1 1 0 00-.8.4L.1 4.2a.5.5 0 00-.1.3V15a1 1 0 001 1h14a1 1 0 001-1V4.5a.5.5 0 00-.1-.3L13.05.4a1 1 0 00-.8-.4zm0 1H7.5v3h-6zM8.5 4V1h3.75l2.25 3zM15 5v10H1V5z"/>',
    },
});
