/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'postcard-heart': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 4.5a.5.5 0 00-1 0v7a.5.5 0 001 0zm3.5.878c1.482-1.42 4.795 1.392 0 4.622-4.795-3.23-1.482-6.043 0-4.622M2.5 5a.5.5 0 000 1h3a.5.5 0 000-1zm0 2a.5.5 0 000 1h3a.5.5 0 000-1zm0 2a.5.5 0 000 1h3a.5.5 0 000-1z"/><path pid="1" fill-rule="evenodd" d="M0 4a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2zm2-1a1 1 0 00-1 1v8a1 1 0 001 1h12a1 1 0 001-1V4a1 1 0 00-1-1z"/>',
    },
});
