/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-slides': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 4a.5.5 0 00-.496.438l-.5 4A.5.5 0 004.5 9h3v2.016c-.863.055-1.5.251-1.5.484 0 .276.895.5 2 .5s2-.224 2-.5c0-.233-.637-.429-1.5-.484V9h3a.5.5 0 00.496-.562l-.5-4A.5.5 0 0011 4zm2 3.78V5.22c0-.096.106-.156.19-.106l2.13 1.279a.125.125 0 010 .214l-2.13 1.28A.125.125 0 017 7.778z"/><path pid="1" d="M2 2a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2zm10-1H4a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V2a1 1 0 00-1-1"/>',
    },
});
