/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'pc-display': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 1a1 1 0 011-1h6a1 1 0 011 1v14a1 1 0 01-1 1H9a1 1 0 01-1-1zm1 13.5a.5.5 0 101 0 .5.5 0 00-1 0m2 0a.5.5 0 101 0 .5.5 0 00-1 0M9.5 1a.5.5 0 000 1h5a.5.5 0 000-1zM9 3.5a.5.5 0 00.5.5h5a.5.5 0 000-1h-5a.5.5 0 00-.5.5M1.5 2A1.5 1.5 0 000 3.5v7A1.5 1.5 0 001.5 12H6v2h-.5a.5.5 0 000 1H7v-4H1.5a.5.5 0 01-.5-.5v-7a.5.5 0 01.5-.5H7V2z"/>',
    },
});
