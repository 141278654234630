/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'terminal-split': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.146 3.146a.5.5 0 01.708 0l.823.824a.75.75 0 010 1.06l-.823.824a.5.5 0 11-.708-.708l.647-.646-.647-.646a.5.5 0 010-.708M4 6a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1A.5.5 0 014 6m6.354-2.854a.5.5 0 00-.708.708l.647.646-.647.646a.5.5 0 10.708.708l.823-.824a.75.75 0 000-1.06zM12 5.5a.5.5 0 000 1h1a.5.5 0 000-1z"/><path pid="1" d="M0 3a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H2a2 2 0 01-2-2zm2-1a1 1 0 00-1 1v10a1 1 0 001 1h5.5V2zm6.5 0v12H14a1 1 0 001-1V3a1 1 0 00-1-1z"/>',
    },
});
