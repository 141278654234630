/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cloud-haze2-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5 2a5 5 0 014.905 4.027A3 3 0 0113 12H3.5A3.5 3.5 0 01.035 9H5.5a.5.5 0 000-1H.035a3.5 3.5 0 013.871-2.977A5 5 0 018.5 2m-6 8a.5.5 0 000 1h9a.5.5 0 000-1zM0 13.5a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5"/>',
    },
});
