/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'pie-chart-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0013.277-5.5zM2 13.292A8 8 0 017.5.015v7.778zM8.5.015V7.5h7.485A8 8 0 008.5.015"/>',
    },
});
