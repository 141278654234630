/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'align-end': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14.5 1a.5.5 0 00-.5.5v13a.5.5 0 001 0v-13a.5.5 0 00-.5-.5"/><path pid="1" d="M13 7a1 1 0 00-1-1H2a1 1 0 00-1 1v2a1 1 0 001 1h10a1 1 0 001-1z"/>',
    },
});
