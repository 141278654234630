/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'mouse-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3 5a5 5 0 0110 0v6a5 5 0 01-10 0zm5.5-1.5a.5.5 0 00-1 0v2a.5.5 0 001 0z"/>',
    },
});
