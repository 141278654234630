/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'taxi-front-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6 1a1 1 0 00-1 1v1h-.181A2.5 2.5 0 002.52 4.515l-.792 1.848a.8.8 0 01-.38.404c-.5.25-.855.715-.965 1.262L.05 9.708a2.5 2.5 0 00-.049.49v.413c0 .814.39 1.543 1 1.997V14.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1.338c1.292.048 2.745.088 4 .088s2.708-.04 4-.088V14.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1.892c.61-.454 1-1.183 1-1.997v-.413q0-.248-.049-.49l-.335-1.68a1.8 1.8 0 00-.964-1.261.8.8 0 01-.381-.404l-.792-1.848A2.5 2.5 0 0011.181 3H11V2a1 1 0 00-1-1zM4.309 4h7.382a.5.5 0 01.447.276l.956 1.913a.51.51 0 01-.497.731c-.91-.073-3.35-.17-4.597-.17s-3.688.097-4.597.17a.51.51 0 01-.497-.731l.956-1.913A.5.5 0 014.309 4M4 10a1 1 0 11-2 0 1 1 0 012 0m10 0a1 1 0 11-2 0 1 1 0 012 0m-9 0a1 1 0 011-1h4a1 1 0 110 2H6a1 1 0 01-1-1"/>',
    },
});
