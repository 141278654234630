/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'phone-vibrate-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 4a2 2 0 012-2h4a2 2 0 012 2v8a2 2 0 01-2 2H6a2 2 0 01-2-2zm5 7a1 1 0 10-2 0 1 1 0 002 0M1.807 4.734a.5.5 0 10-.884-.468A8 8 0 000 8c0 1.347.334 2.618.923 3.734a.5.5 0 10.884-.468A7 7 0 011 8c0-1.18.292-2.292.807-3.266m13.27-.468a.5.5 0 00-.884.468C14.708 5.708 15 6.819 15 8c0 1.18-.292 2.292-.807 3.266a.5.5 0 00.884.468A8 8 0 0016 8a8 8 0 00-.923-3.734M3.34 6.182a.5.5 0 10-.93-.364A6 6 0 002 8c0 .769.145 1.505.41 2.182a.5.5 0 10.93-.364A5 5 0 013 8c0-.642.12-1.255.34-1.818m10.25-.364a.5.5 0 00-.93.364c.22.563.34 1.176.34 1.818s-.12 1.255-.34 1.818a.5.5 0 00.93.364C13.856 9.505 14 8.769 14 8s-.145-1.505-.41-2.182"/>',
    },
});
