/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    transparency: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 6.5a6.5 6.5 0 0112.346-2.846 6.5 6.5 0 11-8.691 8.691A6.5 6.5 0 010 6.5m5.144 6.358a5.5 5.5 0 107.714-7.714 6.5 6.5 0 01-7.714 7.714m-.733-1.269q.546.226 1.144.33l-1.474-1.474q.104.597.33 1.144m2.614.386a5.5 5.5 0 001.173-.242L4.374 7.91a6 6 0 00-.296 1.118zm2.157-.672q.446-.25.838-.576L5.418 6.126a6 6 0 00-.587.826zm1.545-1.284q.325-.39.576-.837L6.953 4.83a6 6 0 00-.827.587l4.6 4.602zm1.006-1.822q.183-.562.242-1.172L9.028 4.078q-.58.096-1.118.296l3.823 3.824zm.186-2.642a5.5 5.5 0 00-.33-1.144 5.5 5.5 0 00-1.144-.33z"/>',
    },
});
