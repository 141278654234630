/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sign-yield-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10.48 5.323h-.28v1.353h.28c.372 0 .54-.222.54-.674 0-.45-.169-.68-.54-.68z"/><path pid="1" d="M7.022 14.434a1.131 1.131 0 001.96 0l6.857-11.667c.457-.778-.092-1.767-.98-1.767H1.144c-.889 0-1.437.99-.98 1.767zM5.506 6.232V7H5.11v-.76L4.44 5h.44l.424.849h.016L5.748 5h.428zM6.628 5v2h-.396V5zm.684 1.676h.895V7H6.919V5h1.288v.324h-.895v.513h.842v.303h-.842zm1.521-.013h.848V7H8.437V5h.396zm.97.337V5h.73c.608 0 .895.364.895.995 0 .636-.291 1.005-.895 1.005z"/>',
    },
});
