/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'list-columns': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M0 .5A.5.5 0 01.5 0h9a.5.5 0 010 1h-9A.5.5 0 010 .5m13 0a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5m-13 2A.5.5 0 01.5 2h8a.5.5 0 010 1h-8a.5.5 0 01-.5-.5m13 0a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5m-13 2A.5.5 0 01.5 4h10a.5.5 0 010 1H.5a.5.5 0 01-.5-.5m13 0a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5m-13 2A.5.5 0 01.5 6h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5m13 0a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5m-13 2A.5.5 0 01.5 8h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5m13 0a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5m-13 2a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5m13 0a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5m-13 2a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5m13 0a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5m-13 2a.5.5 0 01.5-.5h10a.5.5 0 010 1H.5a.5.5 0 01-.5-.5m13 0a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5"/>',
    },
});
