/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'calendar-minus': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.5 9.5A.5.5 0 016 9h4a.5.5 0 010 1H6a.5.5 0 01-.5-.5"/><path pid="1" d="M3.5 0a.5.5 0 01.5.5V1h8V.5a.5.5 0 011 0V1h1a2 2 0 012 2v11a2 2 0 01-2 2H2a2 2 0 01-2-2V3a2 2 0 012-2h1V.5a.5.5 0 01.5-.5M1 4v10a1 1 0 001 1h12a1 1 0 001-1V4z"/>',
    },
});
