/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'pci-card-sound': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.562 7.39L8 7.04v1.92l-.438-.35a.5.5 0 00-.312-.11H6.5v-1h.75a.5.5 0 00.312-.11"/><path pid="1" d="M.5 1a.5.5 0 000 1H1v12.5a.5.5 0 001 0V12h13.5a.5.5 0 00.5-.5v-7a.5.5 0 00-.5-.5H2V1.5a.5.5 0 00-.5-.5zm11.619 3.881q.15.173.28.367c.484.726.768 1.7.768 2.752s-.284 2.026-.768 2.752q-.13.195-.28.367l-.71-.71q.082-.096.158-.212c.36-.54.6-1.315.6-2.197s-.24-1.657-.6-2.198a3 3 0 00-.157-.212zm-1.375 4.863L10 9c.057 0 .17-.035.291-.217.12-.178.209-.454.209-.783 0-.33-.09-.605-.209-.783C10.17 7.035 10.057 7 10 7l.744-.744c.15.113.278.254.38.406.242.364.376.839.376 1.338s-.134.974-.377 1.338a1.7 1.7 0 01-.379.406M9 6v4a.5.5 0 01-.812.39L7.075 9.5H6a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h1.075l1.113-.89A.5.5 0 019 6"/><path pid="2" d="M6.5 12.5H3v1a.5.5 0 00.5.5H6a.5.5 0 00.5-.5zm.5 1v-1h4v1a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5"/>',
    },
});
