/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'marker-tip': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 8A8 8 0 110 8a8 8 0 0116 0m-4.5 6.064l-1.281-4.696A.5.5 0 009.736 9H6.264a.5.5 0 00-.483.368l-1.28 4.696A6.97 6.97 0 008 15c1.275 0 2.47-.34 3.5-.936m.873-.598a7 7 0 10-8.746 0l1.19-4.36a1.5 1.5 0 011.31-1.1l1.155-3.851c.213-.713 1.223-.713 1.436 0l1.156 3.851a1.5 1.5 0 011.31 1.1z"/>',
    },
});
