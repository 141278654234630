/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'usb-drive-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6 .5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v4H6zM7 1v1h1V1zm2 0v1h1V1zM5.5 5a.5.5 0 00-.5.5V15a1 1 0 001 1h5a1 1 0 001-1V5.5a.5.5 0 00-.5-.5z"/>',
    },
});
