/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'send-arrow-up': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M15.854.146a.5.5 0 01.11.54l-2.8 7a.5.5 0 11-.928-.372l1.895-4.738-7.494 7.494 1.376 2.162a.5.5 0 11-.844.537l-1.531-2.407L.643 7.184a.75.75 0 01.124-1.33L15.314.037a.5.5 0 01.54.11zM5.93 9.363l7.494-7.494L1.591 6.602z"/><path pid="1" fill-rule="evenodd" d="M12.5 16a3.5 3.5 0 100-7 3.5 3.5 0 000 7m.354-5.354a.5.5 0 00-.722.016l-1.149 1.25a.5.5 0 10.737.676l.28-.305V14a.5.5 0 001 0v-1.793l.396.397a.5.5 0 00.708-.708z"/>',
    },
});
