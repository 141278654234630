/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-excel-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.293 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4.707A1 1 0 0013.707 4L10 .293A1 1 0 009.293 0M9.5 3.5v-2l3 3h-2a1 1 0 01-1-1M5.884 6.68L8 9.219l2.116-2.54a.5.5 0 11.768.641L8.651 10l2.233 2.68a.5.5 0 01-.768.64L8 10.781l-2.116 2.54a.5.5 0 01-.768-.641L7.349 10 5.116 7.32a.5.5 0 11.768-.64"/>',
    },
});
