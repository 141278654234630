/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'buildings-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M15 .5a.5.5 0 00-.724-.447l-8 4A.5.5 0 006 4.5v3.14L.342 9.526A.5.5 0 000 10v5.5a.5.5 0 00.5.5h9a.5.5 0 00.5-.5V14h1v1.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5zM2 11h1v1H2zm2 0h1v1H4zm-1 2v1H2v-1zm1 0h1v1H4zm9-10v1h-1V3zM8 5h1v1H8zm1 2v1H8V7zM8 9h1v1H8zm2 0h1v1h-1zm-1 2v1H8v-1zm1 0h1v1h-1zm3-2v1h-1V9zm-1 2h1v1h-1zm-2-4h1v1h-1zm3 0v1h-1V7zm-2-2v1h-1V5zm1 0h1v1h-1z"/>',
    },
});
