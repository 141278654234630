/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    gitlab: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M15.734 6.1l-.022-.058L13.534.358a.57.57 0 00-.563-.356.6.6 0 00-.328.122.6.6 0 00-.193.294l-1.47 4.499H5.025l-1.47-4.5A.572.572 0 002.47.358L.289 6.04l-.022.057A4.044 4.044 0 001.61 10.77l.007.006.02.014 3.318 2.485 1.64 1.242 1 .755a.67.67 0 00.814 0l1-.755 1.64-1.242 3.338-2.5.009-.007a4.05 4.05 0 001.34-4.668z"/>',
    },
});
