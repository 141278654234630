/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'fast-forward-btn': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.79 5.093A.5.5 0 008 5.5v1.886L4.79 5.093A.5.5 0 004 5.5v5a.5.5 0 00.79.407L8 8.614V10.5a.5.5 0 00.79.407l3.5-2.5a.5.5 0 000-.814z"/><path pid="1" d="M0 4a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2zm15 0a1 1 0 00-1-1H2a1 1 0 00-1 1v8a1 1 0 001 1h12a1 1 0 001-1z"/>',
    },
});
