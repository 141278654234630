/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    cookie: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6 7.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0m4.5.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3m-.5 3.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0"/><path pid="1" d="M8 0a7.96 7.96 0 00-4.075 1.114q-.245.102-.437.28A8 8 0 108 0m3.25 14.201a1.5 1.5 0 00-2.13.71A7 7 0 018 15a6.97 6.97 0 01-3.845-1.15 1.5 1.5 0 10-2.005-2.005A6.97 6.97 0 011 8c0-1.953.8-3.719 2.09-4.989a1.5 1.5 0 102.469-1.574A7 7 0 018 1c1.42 0 2.742.423 3.845 1.15a1.5 1.5 0 102.005 2.005A6.97 6.97 0 0115 8c0 .596-.074 1.174-.214 1.727a1.5 1.5 0 10-1.025 2.25 7 7 0 01-2.51 2.224z"/>',
    },
});
