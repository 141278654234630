/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'arrow-right-circle': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M1 8a7 7 0 1014 0A7 7 0 001 8m15 0A8 8 0 110 8a8 8 0 0116 0M4.5 7.5a.5.5 0 000 1h5.793l-2.147 2.146a.5.5 0 00.708.708l3-3a.5.5 0 000-.708l-3-3a.5.5 0 10-.708.708L10.293 7.5z"/>',
    },
});
