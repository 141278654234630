/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    memory: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1 3a1 1 0 00-1 1v8a1 1 0 001 1h4.586a1 1 0 00.707-.293l.353-.353a.5.5 0 01.708 0l.353.353a1 1 0 00.707.293H15a1 1 0 001-1V4a1 1 0 00-1-1zm.5 1h3a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5m5 0h3a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5m4.5.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5zM2 10v2H1v-2zm2 0v2H3v-2zm2 0v2H5v-2zm3 0v2H8v-2zm2 0v2h-1v-2zm2 0v2h-1v-2zm2 0v2h-1v-2z"/>',
    },
});
