/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'skip-end-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.5 4a.5.5 0 00-1 0v3.248L5.233 3.612C4.693 3.3 4 3.678 4 4.308v7.384c0 .63.692 1.01 1.233.697L11.5 8.753V12a.5.5 0 001 0z"/>',
    },
});
