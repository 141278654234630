/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    boxes: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.752.066a.5.5 0 01.496 0l3.75 2.143a.5.5 0 01.252.434v3.995l3.498 2A.5.5 0 0116 9.07v4.286a.5.5 0 01-.252.434l-3.75 2.143a.5.5 0 01-.496 0l-3.502-2-3.502 2.001a.5.5 0 01-.496 0l-3.75-2.143A.5.5 0 010 13.357V9.071a.5.5 0 01.252-.434L3.75 6.638V2.643a.5.5 0 01.252-.434zM4.25 7.504L1.508 9.071l2.742 1.567 2.742-1.567zM7.5 9.933l-2.75 1.571v3.134l2.75-1.571zm1 3.134l2.75 1.571v-3.134L8.5 9.933zm.508-3.996l2.742 1.567 2.742-1.567-2.742-1.567zm2.242-2.433V3.504L8.5 5.076V8.21zM7.5 8.21V5.076L4.75 3.504v3.134zM5.258 2.643L8 4.21l2.742-1.567L8 1.076zM15 9.933l-2.75 1.571v3.134L15 13.067zM3.75 14.638v-3.134L1 9.933v3.134z"/>',
    },
});
