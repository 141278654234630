/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    speaker: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12 1a1 1 0 011 1v12a1 1 0 01-1 1H4a1 1 0 01-1-1V2a1 1 0 011-1zM4 0a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2z"/><path pid="1" d="M8 4.75a.75.75 0 110-1.5.75.75 0 010 1.5M8 6a2 2 0 100-4 2 2 0 000 4m0 3a1.5 1.5 0 100 3 1.5 1.5 0 000-3m-3.5 1.5a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0"/>',
    },
});
