/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'key-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.5 11.5a3.5 3.5 0 113.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 01-3.163 2M2.5 9a1 1 0 100-2 1 1 0 000 2"/>',
    },
});
