/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'wrench-adjustable-circle-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.705 8.139a.25.25 0 00-.288-.376l-1.5.5.159.474.808-.27-.595.894a.25.25 0 00.287.376l.808-.27-.595.894a.25.25 0 00.287.376l1.5-.5-.159-.474-.808.27.596-.894a.25.25 0 00-.288-.376l-.808.27z"/><path pid="1" d="M8 16A8 8 0 108 0a8 8 0 000 16m-6.202-4.751l1.988-1.657a4.5 4.5 0 017.537-4.623L7.497 6.5l1 2.5 1.333 3.11c-.56.251-1.18.39-1.833.39a4.5 4.5 0 01-1.592-.29L4.747 14.2a7.03 7.03 0 01-2.949-2.951M12.496 8a4.5 4.5 0 01-1.703 3.526L9.497 8.5l2.959-1.11q.04.3.04.61"/>',
    },
});
