/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'briefcase-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.5 1A1.5 1.5 0 005 2.5V3H1.5A1.5 1.5 0 000 4.5v1.384l7.614 2.03a1.5 1.5 0 00.772 0L16 5.884V4.5A1.5 1.5 0 0014.5 3H11v-.5A1.5 1.5 0 009.5 1zm0 1h3a.5.5 0 01.5.5V3H6v-.5a.5.5 0 01.5-.5"/><path pid="1" d="M0 12.5A1.5 1.5 0 001.5 14h13a1.5 1.5 0 001.5-1.5V6.85L8.129 8.947a.5.5 0 01-.258 0L0 6.85z"/>',
    },
});
