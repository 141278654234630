/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-docx': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zm-6.839 9.688v-.522a1.5 1.5 0 00-.117-.641.86.86 0 00-.322-.387.86.86 0 00-.469-.129.87.87 0 00-.471.13.87.87 0 00-.32.386 1.5 1.5 0 00-.117.641v.522q0 .384.117.641a.87.87 0 00.32.387.9.9 0 00.471.126.9.9 0 00.469-.126.86.86 0 00.322-.386 1.55 1.55 0 00.117-.642m.803-.516v.513q0 .563-.205.973a1.47 1.47 0 01-.589.627q-.381.216-.917.216a1.86 1.86 0 01-.92-.216 1.46 1.46 0 01-.589-.627 2.15 2.15 0 01-.205-.973v-.513q0-.569.205-.975.205-.411.59-.627.386-.22.92-.22.535 0 .916.22.383.219.59.63.204.406.204.972M1 15.925v-3.999h1.459q.609 0 1.005.235.396.233.589.68.196.445.196 1.074 0 .634-.196 1.084-.197.451-.595.689-.396.237-.999.237zm1.354-3.354H1.79v2.707h.563q.277 0 .483-.082a.8.8 0 00.334-.252q.132-.17.196-.422a2.3 2.3 0 00.068-.592q0-.45-.118-.753a.9.9 0 00-.354-.454q-.237-.152-.61-.152zm6.756 1.116q0-.373.103-.633a.87.87 0 01.301-.398.8.8 0 01.475-.138q.225 0 .398.097a.7.7 0 01.273.26.85.85 0 01.12.381h.765v-.073a1.33 1.33 0 00-.466-.964 1.4 1.4 0 00-.49-.272 1.8 1.8 0 00-.606-.097q-.534 0-.911.223-.375.222-.571.633-.197.41-.197.978v.498q0 .568.194.976.195.406.571.627.375.216.914.216.44 0 .785-.164t.551-.454a1.27 1.27 0 00.226-.674v-.076h-.765a.8.8 0 01-.117.364.7.7 0 01-.273.248.9.9 0 01-.401.088.85.85 0 01-.478-.131.83.83 0 01-.298-.393 1.7 1.7 0 01-.103-.627zm5.092-1.76h.894l-1.275 2.006 1.254 1.992h-.908l-.85-1.415h-.035l-.852 1.415h-.862l1.24-2.015-1.228-1.984h.932l.832 1.439h.035z"/>',
    },
});
