/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-grin': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.946 11.398A6.002 6.002 0 012.108 9.14c-.114-.595.426-1.068 1.028-.997C4.405 8.289 6.48 8.5 8 8.5s3.595-.21 4.864-.358c.602-.07 1.142.402 1.028.998a5.95 5.95 0 01-.946 2.258m-.078-2.25C11.588 9.295 9.539 9.5 8 9.5s-3.589-.205-4.868-.352c.11.468.286.91.517 1.317A37 37 0 008 10.75a37 37 0 004.351-.285c.231-.407.407-.85.517-1.317m-1.36 2.416c-1.02.1-2.255.186-3.508.186s-2.488-.086-3.507-.186A5 5 0 008 13a5 5 0 003.507-1.436zM6.488 7c.114-.294.179-.636.179-1 0-1.105-.597-2-1.334-2C4.597 4 4 4.895 4 6c0 .364.065.706.178 1 .23-.598.662-1 1.155-1 .494 0 .925.402 1.155 1M12 6c0 .364-.065.706-.178 1-.23-.598-.662-1-1.155-1-.494 0-.925.402-1.155 1a2.8 2.8 0 01-.179-1c0-1.105.597-2 1.334-2C11.403 4 12 4.895 12 6"/><path pid="1" d="M8 16A8 8 0 108 0a8 8 0 000 16m0-1A7 7 0 118 1a7 7 0 010 14"/>',
    },
});
