/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    dpad: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.788 2.34l-.799 1.278A.25.25 0 007.201 4h1.598a.25.25 0 00.212-.382l-.799-1.279a.25.25 0 00-.424 0zm0 11.32l-.799-1.277A.25.25 0 017.201 12h1.598a.25.25 0 01.212.383l-.799 1.278a.25.25 0 01-.424 0zM3.617 9.01L2.34 8.213a.25.25 0 010-.424l1.278-.799A.25.25 0 014 7.201V8.8a.25.25 0 01-.383.212zm10.043-.798l-1.277.799A.25.25 0 0112 8.799V7.2a.25.25 0 01.383-.212l1.278.799a.25.25 0 010 .424z"/><path pid="1" d="M6.5 0A1.5 1.5 0 005 1.5v3a.5.5 0 01-.5.5h-3A1.5 1.5 0 000 6.5v3A1.5 1.5 0 001.5 11h3a.5.5 0 01.5.5v3A1.5 1.5 0 006.5 16h3a1.5 1.5 0 001.5-1.5v-3a.5.5 0 01.5-.5h3A1.5 1.5 0 0016 9.5v-3A1.5 1.5 0 0014.5 5h-3a.5.5 0 01-.5-.5v-3A1.5 1.5 0 009.5 0zM6 1.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v3A1.5 1.5 0 0011.5 6h3a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-3a1.5 1.5 0 00-1.5 1.5v3a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-3A1.5 1.5 0 004.5 10h-3a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h3A1.5 1.5 0 006 4.5z"/>',
    },
});
