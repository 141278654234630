/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    bookshelf: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.5 0a.5.5 0 01.5.5V2h10V.5a.5.5 0 011 0v15a.5.5 0 01-1 0V15H3v.5a.5.5 0 01-1 0V.5a.5.5 0 01.5-.5M3 14h10v-3H3zm0-4h10V7H3zm0-4h10V3H3z"/>',
    },
});
