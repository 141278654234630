/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    cone: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.03 1.88c.252-1.01 1.688-1.01 1.94 0l2.905 11.62H14a.5.5 0 010 1H2a.5.5 0 010-1h2.125z"/>',
    },
});
