/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'postage-heart-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4.5 3a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5zM8 11C2.175 7.236 6.336 4.31 8 5.982 9.664 4.309 13.825 7.236 8 11"/><path pid="1" d="M4.5 0a1 1 0 01-2 0H1v1a1 1 0 010 2v1a1 1 0 010 2v1a1 1 0 010 2v1a1 1 0 110 2v1a1 1 0 110 2v1h1.5a1 1 0 112 0h1a1 1 0 112 0h1a1 1 0 112 0h1a1 1 0 112 0H15v-1a1 1 0 110-2v-1a1 1 0 110-2V9a1 1 0 110-2V6a1 1 0 110-2V3a1 1 0 110-2V0h-1.5a1 1 0 11-2 0h-1a1 1 0 11-2 0h-1a1 1 0 01-2 0zM4 14a1 1 0 01-1-1V3a1 1 0 011-1h8a1 1 0 011 1v10a1 1 0 01-1 1z"/>',
    },
});
