/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'phone-flip': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M11 1H5a1 1 0 00-1 1v6a.5.5 0 01-1 0V2a2 2 0 012-2h6a2 2 0 012 2v6a.5.5 0 01-1 0V2a1 1 0 00-1-1m1 13a1 1 0 01-1 1H5a1 1 0 01-1-1v-2a.5.5 0 00-1 0v2a2 2 0 002 2h6a2 2 0 002-2v-2a.5.5 0 00-1 0zM1.713 7.954a.5.5 0 10-.419-.908c-.347.16-.654.348-.882.57C.184 7.842 0 8.139 0 8.5c0 .546.408.94.823 1.201.44.278 1.043.51 1.745.696C3.978 10.773 5.898 11 8 11q.148 0 .294-.002l-1.148 1.148a.5.5 0 00.708.708l2-2a.5.5 0 000-.708l-2-2a.5.5 0 10-.708.708l1.145 1.144L8 10c-2.04 0-3.87-.221-5.174-.569-.656-.175-1.151-.374-1.47-.575C1.012 8.639 1 8.506 1 8.5c0-.003 0-.059.112-.17.115-.112.31-.242.6-.376zm12.993-.908a.5.5 0 00-.419.908c.292.134.486.264.6.377.113.11.113.166.113.169s0 .065-.13.187c-.132.122-.352.26-.677.4-.645.28-1.596.523-2.763.687a.5.5 0 00.14.99c1.212-.17 2.26-.43 3.02-.758.38-.164.713-.357.96-.587.246-.229.45-.537.45-.919 0-.362-.184-.66-.412-.883s-.535-.411-.882-.571M7.5 2a.5.5 0 000 1h1a.5.5 0 000-1z"/>',
    },
});
