/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'thermometer-sun': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 12.5a1.5 1.5 0 11-2-1.415V2.5a.5.5 0 011 0v8.585A1.5 1.5 0 015 12.5"/><path pid="1" d="M1 2.5a2.5 2.5 0 015 0v7.55a3.5 3.5 0 11-5 0zM3.5 1A1.5 1.5 0 002 2.5v7.987l-.167.15a2.5 2.5 0 103.333 0L5 10.486V2.5A1.5 1.5 0 003.5 1m5 1a.5.5 0 01.5.5v1a.5.5 0 01-1 0v-1a.5.5 0 01.5-.5m4.243 1.757a.5.5 0 010 .707l-.707.708a.5.5 0 11-.708-.708l.708-.707a.5.5 0 01.707 0M8 5.5a.5.5 0 01.5-.5 3 3 0 110 6 .5.5 0 010-1 2 2 0 000-4 .5.5 0 01-.5-.5M12.5 8a.5.5 0 01.5-.5h1a.5.5 0 110 1h-1a.5.5 0 01-.5-.5m-1.172 2.828a.5.5 0 01.708 0l.707.708a.5.5 0 01-.707.707l-.708-.707a.5.5 0 010-.708M8.5 12a.5.5 0 01.5.5v1a.5.5 0 01-1 0v-1a.5.5 0 01.5-.5"/>',
    },
});
