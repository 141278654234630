/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    people: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 100-4 2 2 0 000 4m3-2a3 3 0 11-6 0 3 3 0 016 0M6.936 9.28a6 6 0 00-1.23-.247A7 7 0 005 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 015 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 004 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 116 0 3 3 0 01-6 0m3-2a2 2 0 100 4 2 2 0 000-4"/>',
    },
});
