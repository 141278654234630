/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-person': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12 1a1 1 0 011 1v10.755S12 11 8 11s-5 1.755-5 1.755V2a1 1 0 011-1zM4 0a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2z"/><path pid="1" d="M8 10a3 3 0 100-6 3 3 0 000 6"/>',
    },
});
