/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'house-dash': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.5 16a3.5 3.5 0 100-7 3.5 3.5 0 000 7M11 12h3a.5.5 0 010 1h-3a.5.5 0 110-1"/><path pid="1" d="M7.293 1.5a1 1 0 011.414 0L11 3.793V2.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v3.293l2.354 2.353a.5.5 0 01-.708.708L8 2.207l-5 5V13.5a.5.5 0 00.5.5h4a.5.5 0 010 1h-4A1.5 1.5 0 012 13.5V8.207l-.646.647a.5.5 0 11-.708-.708z"/>',
    },
});
