/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'globe-asia-australia': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10.495 6.92l1.278-.619a.483.483 0 00.126-.782c-.252-.244-.682-.139-.932.107-.23.226-.513.373-.816.53l-.102.054c-.338.178-.264.626.1.736a.48.48 0 00.346-.027zM7.741 9.808V9.78a.413.413 0 11.783.183l-.22.443a.6.6 0 01-.12.167l-.193.185a.36.36 0 11-.5-.516l.112-.108a.45.45 0 00.138-.326M5.672 12.5l.482.233A.386.386 0 106.32 12h-.416a.7.7 0 01-.419-.139l-.277-.206a.302.302 0 10-.298.52z"/><path pid="1" d="M8 0a8 8 0 100 16A8 8 0 008 0M1.612 10.867l.756-1.288a1 1 0 011.545-.225l1.074 1.005a.986.986 0 001.36-.011l.038-.037a.88.88 0 00.26-.755c-.075-.548.37-1.033.92-1.099.728-.086 1.587-.324 1.728-.957.086-.386-.114-.83-.361-1.2-.207-.312 0-.8.374-.8.123 0 .24-.055.318-.15l.393-.474c.196-.237.491-.368.797-.403.554-.064 1.407-.277 1.583-.973.098-.391-.192-.634-.484-.88-.254-.212-.51-.426-.515-.741a7 7 0 013.425 7.692 1 1 0 00-.087-.063l-.316-.204a1 1 0 00-.977-.06l-.169.082a1 1 0 01-.741.051l-1.021-.329A1 1 0 0011.205 9h-.165a1 1 0 00-.945.674l-.172.499a1 1 0 01-.404.514l-.802.518a1 1 0 00-.458.84v.455a1 1 0 001 1h.257a1 1 0 01.542.16l.762.49a1 1 0 00.283.126 7 7 0 01-9.49-3.409z"/>',
    },
});
