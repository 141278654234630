/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'house-down-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.5 9a3.5 3.5 0 110 7 3.5 3.5 0 010-7m.354 5.854l1.5-1.5a.5.5 0 00-.708-.707l-.646.646V10.5a.5.5 0 00-1 0v2.793l-.646-.646a.5.5 0 00-.708.707l1.5 1.5a.5.5 0 00.708 0"/><path pid="1" d="M8.707 1.5a1 1 0 00-1.414 0L.646 8.146a.5.5 0 00.708.708L8 2.207l6.646 6.647a.5.5 0 00.708-.708L13 5.793V2.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v1.293z"/><path pid="2" d="M8 3.293l4.712 4.712A4.5 4.5 0 008.758 15H3.5A1.5 1.5 0 012 13.5V9.293z"/>',
    },
});
