/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-angry': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 15A7 7 0 118 1a7 7 0 010 14m0 1A8 8 0 108 0a8 8 0 000 16"/><path pid="1" d="M4.285 12.433a.5.5 0 00.683-.183A3.5 3.5 0 018 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 00.866-.5A4.5 4.5 0 008 9.5a4.5 4.5 0 00-3.898 2.25.5.5 0 00.183.683m6.991-8.38a.5.5 0 11.448.894l-1.009.504c.176.27.285.64.285 1.049 0 .828-.448 1.5-1 1.5s-1-.672-1-1.5c0-.247.04-.48.11-.686a.502.502 0 01.166-.761zm-6.552 0a.5.5 0 00-.448.894l1.009.504A1.94 1.94 0 005 6.5C5 7.328 5.448 8 6 8s1-.672 1-1.5c0-.247-.04-.48-.11-.686a.502.502 0 00-.166-.761z"/>',
    },
});
