/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'building-check': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.5 16a3.5 3.5 0 100-7 3.5 3.5 0 000 7m1.679-4.493l-1.335 2.226a.75.75 0 01-1.174.144l-.774-.773a.5.5 0 01.708-.708l.547.548 1.17-1.951a.5.5 0 11.858.514"/><path pid="1" d="M2 1a1 1 0 011-1h10a1 1 0 011 1v6.5a.5.5 0 01-1 0V1H3v14h3v-2.5a.5.5 0 01.5-.5H8v4H3a1 1 0 01-1-1z"/><path pid="2" d="M4.5 2a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm3 0a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm3 0a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm-6 3a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm3 0a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm3 0a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm-6 3a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm3 0a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z"/>',
    },
});
