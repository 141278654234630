/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'hdd-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 10a2 2 0 012-2h12a2 2 0 012 2v1a2 2 0 01-2 2H2a2 2 0 01-2-2zm2.5 1a.5.5 0 100-1 .5.5 0 000 1m2 0a.5.5 0 100-1 .5.5 0 000 1M.91 7.204A3 3 0 012 7h12c.384 0 .752.072 1.09.204l-1.867-3.422A1.5 1.5 0 0011.906 3H4.094a1.5 1.5 0 00-1.317.782z"/>',
    },
});
