/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'clipboard2-heart': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10.058.501a.5.5 0 00-.5-.501h-2.98c-.276 0-.5.225-.5.501A.5.5 0 015.582 1a.497.497 0 00-.497.497V2a.5.5 0 00.5.5h4.968a.5.5 0 00.5-.5v-.503A.497.497 0 0010.555 1a.5.5 0 01-.497-.499"/><path pid="1" d="M3.605 2a.5.5 0 00-.5.5v12a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-12a.5.5 0 00-.5-.5h-.5a.5.5 0 010-1h.5a1.5 1.5 0 011.5 1.5v12a1.5 1.5 0 01-1.5 1.5h-9a1.5 1.5 0 01-1.5-1.5v-12a1.5 1.5 0 011.5-1.5h.5a.5.5 0 010 1z"/><path pid="2" d="M8.068 6.482c1.656-1.673 5.795 1.254 0 5.018-5.795-3.764-1.656-6.69 0-5.018"/>',
    },
});
