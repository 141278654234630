/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-frown': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 15A7 7 0 118 1a7 7 0 010 14m0 1A8 8 0 108 0a8 8 0 000 16"/><path pid="1" d="M4.285 12.433a.5.5 0 00.683-.183A3.5 3.5 0 018 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 00.866-.5A4.5 4.5 0 008 9.5a4.5 4.5 0 00-3.898 2.25.5.5 0 00.183.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5"/>',
    },
});
