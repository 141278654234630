/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-rolodex': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 9.05a2.5 2.5 0 100-5 2.5 2.5 0 000 5"/><path pid="1" d="M1 1a1 1 0 00-1 1v11a1 1 0 001 1h.5a.5.5 0 00.5-.5.5.5 0 011 0 .5.5 0 00.5.5h9a.5.5 0 00.5-.5.5.5 0 011 0 .5.5 0 00.5.5h.5a1 1 0 001-1V3a1 1 0 00-1-1H6.707L6 1.293A1 1 0 005.293 1zm0 1h4.293L6 2.707A1 1 0 006.707 3H15v10h-.085a1.5 1.5 0 00-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 00-2.4.63H1z"/>',
    },
});
