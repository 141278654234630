/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'zoom-out': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11M13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0"/><path pid="1" d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 001.415-1.414l-3.85-3.85a1 1 0 00-.115-.1 6.5 6.5 0 01-1.398 1.4z"/><path pid="2" fill-rule="evenodd" d="M3 6.5a.5.5 0 01.5-.5h6a.5.5 0 010 1h-6a.5.5 0 01-.5-.5"/>',
    },
});
