/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    valentine: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 5.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132M2.25 4a.25.25 0 00-.25.25v1.5a.25.25 0 00.5 0V4.5h1.25a.25.25 0 000-.5zm10 0a.25.25 0 100 .5h1.25v1.25a.25.25 0 10.5 0v-1.5a.25.25 0 00-.25-.25zM2.5 10.25a.25.25 0 10-.5 0v1.5c0 .138.112.25.25.25h1.5a.25.25 0 100-.5H2.5zm11.5 0a.25.25 0 10-.5 0v1.25h-1.25a.25.25 0 100 .5h1.5a.25.25 0 00.25-.25z"/><path pid="1" fill-rule="evenodd" d="M0 2.994v-.06a1 1 0 01.859-.99l13-1.857a1 1 0 011.141.99V2a1 1 0 011 1v10a1 1 0 01-1 1H1a1 1 0 01-1-1zM1 3v10h14V3z"/>',
    },
});
