/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'pci-card': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 1.5A.5.5 0 01.5 1h1a.5.5 0 01.5.5V4h13.5a.5.5 0 01.5.5v7a.5.5 0 01-.5.5H2v2.5a.5.5 0 01-1 0V2H.5a.5.5 0 01-.5-.5"/><path pid="1" d="M3 12.5h3.5v1a.5.5 0 01-.5.5H3.5a.5.5 0 01-.5-.5zm4 0h4v1a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5z"/>',
    },
});
