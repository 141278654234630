/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'arrow-down-left-circle-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 8A8 8 0 100 8a8 8 0 0016 0m-5.904-2.803a.5.5 0 11.707.707L6.707 10h2.768a.5.5 0 010 1H5.5a.5.5 0 01-.5-.5V6.525a.5.5 0 011 0v2.768z"/>',
    },
});
