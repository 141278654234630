/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'telephone-forward-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 012.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 00.178.643l2.457 2.457a.68.68 0 00.644.178l2.189-.547a1.75 1.75 0 011.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 01-7.01-4.42 18.6 18.6 0 01-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zm10.761.135a.5.5 0 01.708 0l2.5 2.5a.5.5 0 010 .708l-2.5 2.5a.5.5 0 01-.708-.708L14.293 4H9.5a.5.5 0 010-1h4.793l-1.647-1.646a.5.5 0 010-.708"/>',
    },
});
