/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-ppt-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.188 10H7V6.5h1.188a1.75 1.75 0 110 3.5"/><path pid="1" d="M4 0h5.293A1 1 0 0110 .293L13.707 4a1 1 0 01.293.707V14a2 2 0 01-2 2H4a2 2 0 01-2-2V2a2 2 0 012-2m5.5 1.5v2a1 1 0 001 1h2zM7 5.5a1 1 0 00-1 1V13a.5.5 0 001 0v-2h1.188a2.75 2.75 0 000-5.5z"/>',
    },
});
