/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    vignette: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 1a7 7 0 100 14A7 7 0 008 1M0 8a8 8 0 1116 0A8 8 0 010 8"/><path pid="1" d="M8.5 4.5a.5.5 0 11-1 0 .5.5 0 011 0m0 7a.5.5 0 11-1 0 .5.5 0 011 0m1.683-6.281a.5.5 0 11-.866-.5.5.5 0 01.866.5m-3.5 6.062a.5.5 0 11-.866-.5.5.5 0 01.866.5m4.598-4.598a.5.5 0 11-.5-.866.5.5 0 01.5.866m-6.062 3.5a.5.5 0 11-.5-.866.5.5 0 01.5.866M11.5 8.5a.5.5 0 110-1 .5.5 0 010 1m-7 0a.5.5 0 110-1 .5.5 0 010 1m6.281 1.683a.5.5 0 11.5-.866.5.5 0 01-.5.866m-6.062-3.5a.5.5 0 11.5-.866.5.5 0 01-.5.866m4.598 4.598a.5.5 0 11.866-.5.5.5 0 01-.866.5m-3.5-6.062a.5.5 0 11.866-.5.5.5 0 01-.866.5"/>',
    },
});
