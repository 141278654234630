/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'chat-right-dots': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 1a1 1 0 00-1 1v8a1 1 0 001 1h9.586a2 2 0 011.414.586l2 2V2a1 1 0 00-1-1zm12-1a2 2 0 012 2v12.793a.5.5 0 01-.854.353l-2.853-2.853a1 1 0 00-.707-.293H2a2 2 0 01-2-2V2a2 2 0 012-2z"/><path pid="1" d="M5 6a1 1 0 11-2 0 1 1 0 012 0m4 0a1 1 0 11-2 0 1 1 0 012 0m4 0a1 1 0 11-2 0 1 1 0 012 0"/>',
    },
});
