/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'arrow-down-right-circle-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 8a8 8 0 1116 0A8 8 0 010 8m5.904-2.803a.5.5 0 10-.707.707L9.293 10H6.525a.5.5 0 000 1H10.5a.5.5 0 00.5-.5V6.525a.5.5 0 00-1 0v2.768z"/>',
    },
});
