/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'easel3-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5 12v1.134a1 1 0 11-1 0V12h-5A1.5 1.5 0 011 10.5V3h14v7.5a1.5 1.5 0 01-1.5 1.5zm7-10a.5.5 0 000-1H.5a.5.5 0 000 1z"/>',
    },
});
