/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cloud-snow-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.625 11.5a.25.25 0 01.25.25v.57l.501-.287a.25.25 0 01.248.434l-.495.283.495.283a.25.25 0 01-.248.434l-.501-.286v.569a.25.25 0 11-.5 0v-.57l-.501.287a.25.25 0 01-.248-.434l.495-.283-.495-.283a.25.25 0 01.248-.434l.501.286v-.569a.25.25 0 01.25-.25m2.75 2a.25.25 0 01.25.25v.57l.5-.287a.25.25 0 01.249.434l-.495.283.495.283a.25.25 0 01-.248.434l-.501-.286v.569a.25.25 0 11-.5 0v-.57l-.501.287a.25.25 0 01-.248-.434l.495-.283-.495-.283a.25.25 0 01.248-.434l.501.286v-.569a.25.25 0 01.25-.25m5.5 0a.25.25 0 01.25.25v.57l.5-.287a.25.25 0 01.249.434l-.495.283.495.283a.25.25 0 01-.248.434l-.501-.286v.569a.25.25 0 01-.5 0v-.57l-.501.287a.25.25 0 01-.248-.434l.495-.283-.495-.283a.25.25 0 01.248-.434l.501.286v-.569a.25.25 0 01.25-.25m-2.75-2a.25.25 0 01.25.25v.57l.5-.287a.25.25 0 01.249.434l-.495.283.495.283a.25.25 0 01-.248.434l-.501-.286v.569a.25.25 0 11-.5 0v-.57l-.501.287a.25.25 0 01-.248-.434l.495-.283-.495-.283a.25.25 0 01.248-.434l.501.286v-.569a.25.25 0 01.25-.25m5.5 0a.25.25 0 01.25.25v.57l.5-.287a.25.25 0 01.249.434l-.495.283.495.283a.25.25 0 01-.248.434l-.501-.286v.569a.25.25 0 01-.5 0v-.57l-.501.287a.25.25 0 11-.248-.434l.495-.283-.495-.283a.25.25 0 01.248-.434l.501.286v-.569a.25.25 0 01.25-.25m-.22-7.223a5.001 5.001 0 00-9.499-1.004A3.5 3.5 0 103.5 10.25H13a3 3 0 00.405-5.973"/>',
    },
});
