/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'vector-pen': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M10.646.646a.5.5 0 01.708 0l4 4a.5.5 0 010 .708l-1.902 1.902-.829 3.313a1.5 1.5 0 01-1.024 1.073L1.254 14.746 4.358 4.4A1.5 1.5 0 015.43 3.377l3.313-.828zm-1.8 2.908l-3.173.793a.5.5 0 00-.358.342l-2.57 8.565 8.567-2.57a.5.5 0 00.34-.357l.794-3.174-3.6-3.6z"/><path pid="1" fill-rule="evenodd" d="M2.832 13.228L8 9a1 1 0 10-1-1l-4.228 5.168-.026.086z"/>',
    },
});
