/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'volume-down': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9 4a.5.5 0 00-.812-.39L5.825 5.5H3.5A.5.5 0 003 6v4a.5.5 0 00.5.5h2.325l2.363 1.89A.5.5 0 009 12zM6.312 6.39L8 5.04v5.92L6.312 9.61A.5.5 0 006 9.5H4v-3h2a.5.5 0 00.312-.11M12.025 8a4.5 4.5 0 01-1.318 3.182L10 10.475A3.5 3.5 0 0011.025 8 3.5 3.5 0 0010 5.525l.707-.707A4.5 4.5 0 0112.025 8"/>',
    },
});
