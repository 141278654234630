/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-jsx': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2h-1v-1h1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM3.075 14.841a1.13 1.13 0 00.401.823q.194.162.478.252.284.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 00.187-.656q0-.336-.135-.56a1 1 0 00-.375-.357 2 2 0 00-.565-.21l-.621-.144a1 1 0 01-.405-.176.37.37 0 01-.143-.299q0-.234.184-.384.188-.152.513-.152.214 0 .37.068a.6.6 0 01.245.181.56.56 0 01.12.258h.75a1.1 1.1 0 00-.199-.566 1.2 1.2 0 00-.5-.41 1.8 1.8 0 00-.78-.152q-.44 0-.776.15-.337.149-.528.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 01.153.326.5.5 0 01-.085.29.56.56 0 01-.255.193q-.167.07-.413.07-.176 0-.32-.04a.8.8 0 01-.248-.115.58.58 0 01-.255-.384zM0 14.791q0 .247.082.466.083.22.243.39.165.17.407.267.246.093.569.093.63 0 .984-.346.357-.345.358-1.004v-2.725h-.791v2.745q0 .302-.138.466t-.422.164a.5.5 0 01-.454-.246.6.6 0 01-.073-.27zm8.907-2.859H9.8l-1.274 2.007L9.78 15.93h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438z"/>',
    },
});
