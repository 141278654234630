/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'exclamation-square': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M14 1a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1zM2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2z"/><path pid="1" d="M7.002 11a1 1 0 112 0 1 1 0 01-2 0M7.1 4.995a.905.905 0 111.8 0l-.35 3.507a.552.552 0 01-1.1 0z"/>',
    },
});
