/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cart-x-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M.5 1a.5.5 0 000 1h1.11l.401 1.607 1.498 7.985A.5.5 0 004 12h1a2 2 0 100 4 2 2 0 000-4h7a2 2 0 100 4 2 2 0 000-4h1a.5.5 0 00.491-.408l1.5-8A.5.5 0 0014.5 3H2.89l-.405-1.621A.5.5 0 002 1zM6 14a1 1 0 11-2 0 1 1 0 012 0m7 0a1 1 0 11-2 0 1 1 0 012 0M7.354 5.646L8.5 6.793l1.146-1.147a.5.5 0 01.708.708L9.207 7.5l1.147 1.146a.5.5 0 01-.708.708L8.5 8.207 7.354 9.354a.5.5 0 11-.708-.708L7.793 7.5 6.646 6.354a.5.5 0 11.708-.708"/>',
    },
});
