/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'clipboard-data-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.5 0A1.5 1.5 0 005 1.5v1A1.5 1.5 0 006.5 4h3A1.5 1.5 0 0011 2.5v-1A1.5 1.5 0 009.5 0zm3 1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5z"/><path pid="1" d="M4 1.5H3a2 2 0 00-2 2V14a2 2 0 002 2h10a2 2 0 002-2V3.5a2 2 0 00-2-2h-1v1A2.5 2.5 0 019.5 5h-3A2.5 2.5 0 014 2.5zM10 8a1 1 0 112 0v5a1 1 0 11-2 0zm-6 4a1 1 0 112 0v1a1 1 0 11-2 0zm4-3a1 1 0 011 1v3a1 1 0 11-2 0v-3a1 1 0 011-1"/>',
    },
});
