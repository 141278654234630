/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'calendar-range-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 .5a.5.5 0 00-1 0V1H2a2 2 0 00-2 2v1h16V3a2 2 0 00-2-2h-1V.5a.5.5 0 00-1 0V1H4zM16 7V5H0v5h5a1 1 0 110 2H0v2a2 2 0 002 2h12a2 2 0 002-2V9h-6a1 1 0 110-2z"/>',
    },
});
