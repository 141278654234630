/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-video': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 9.05a2.5 2.5 0 100-5 2.5 2.5 0 000 5"/><path pid="1" d="M2 2a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2zm10.798 11c-.453-1.27-1.76-3-4.798-3-3.037 0-4.345 1.73-4.798 3H2a1 1 0 01-1-1V4a1 1 0 011-1h12a1 1 0 011 1v8a1 1 0 01-1 1z"/>',
    },
});
