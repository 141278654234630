/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'clipboard2-heart-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M10.058.501a.5.5 0 00-.5-.501h-2.98c-.276 0-.5.225-.5.501A.5.5 0 015.582 1a.497.497 0 00-.497.497V2a.5.5 0 00.5.5h4.968a.5.5 0 00.5-.5v-.503A.497.497 0 0010.555 1a.5.5 0 01-.497-.499"/><path pid="1" fill-rule="evenodd" d="M4.174 1h-.57a1.5 1.5 0 00-1.5 1.5v12a1.5 1.5 0 001.5 1.5h9a1.5 1.5 0 001.5-1.5v-12a1.5 1.5 0 00-1.5-1.5h-.642q.084.236.085.5V2c0 .828-.668 1.5-1.492 1.5H5.581A1.496 1.496 0 014.09 2v-.5q.001-.264.085-.5zm3.894 5.482c1.656-1.673 5.795 1.254 0 5.018-5.795-3.764-1.656-6.69 0-5.018"/>',
    },
});
