/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'dash-lg': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M2 8a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11A.5.5 0 012 8"/>',
    },
});
