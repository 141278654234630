/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    usb: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.25 7a.25.25 0 00-.25.25v1c0 .138.112.25.25.25h11.5a.25.25 0 00.25-.25v-1a.25.25 0 00-.25-.25z"/><path pid="1" d="M0 5.5A.5.5 0 01.5 5h15a.5.5 0 01.5.5v5a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5zM1 10h14V6H1z"/>',
    },
});
