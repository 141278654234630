/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    bricks: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 .5A.5.5 0 01.5 0h15a.5.5 0 01.5.5v3a.5.5 0 01-.5.5H14v2h1.5a.5.5 0 01.5.5v3a.5.5 0 01-.5.5H14v2h1.5a.5.5 0 01.5.5v3a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5H2v-2H.5a.5.5 0 01-.5-.5v-3A.5.5 0 01.5 6H2V4H.5a.5.5 0 01-.5-.5zM3 4v2h4.5V4zm5.5 0v2H13V4zM3 10v2h4.5v-2zm5.5 0v2H13v-2zM1 1v2h3.5V1zm4.5 0v2h5V1zm6 0v2H15V1zM1 7v2h3.5V7zm4.5 0v2h5V7zm6 0v2H15V7zM1 13v2h3.5v-2zm4.5 0v2h5v-2zm6 0v2H15v-2z"/>',
    },
});
