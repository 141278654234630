/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    dot: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3"/>',
    },
});
