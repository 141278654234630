/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'node-minus-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M16 8a5 5 0 01-9.975.5H4A1.5 1.5 0 012.5 10h-1A1.5 1.5 0 010 8.5v-1A1.5 1.5 0 011.5 6h1A1.5 1.5 0 014 7.5h2.025A5 5 0 0116 8m-2 0a.5.5 0 00-.5-.5h-5a.5.5 0 000 1h5A.5.5 0 0014 8"/>',
    },
});
