/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'collection-play': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 3a.5.5 0 00.5.5h11a.5.5 0 000-1h-11A.5.5 0 002 3m2-2a.5.5 0 00.5.5h7a.5.5 0 000-1h-7A.5.5 0 004 1m2.765 5.576A.5.5 0 006 7v5a.5.5 0 00.765.424l4-2.5a.5.5 0 000-.848z"/><path pid="1" d="M1.5 14.5A1.5 1.5 0 010 13V6a1.5 1.5 0 011.5-1.5h13A1.5 1.5 0 0116 6v7a1.5 1.5 0 01-1.5 1.5zm13-1a.5.5 0 00.5-.5V6a.5.5 0 00-.5-.5h-13A.5.5 0 001 6v7a.5.5 0 00.5.5z"/>',
    },
});
