/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'egg-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M14 10a6 6 0 01-12 0C2 5.686 5 0 8 0s6 5.686 6 10"/>',
    },
});
