/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    webcam: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 6a2 2 0 012-2h12a2 2 0 012 2v3a2 2 0 01-2 2H9.269c.144.162.33.324.531.475a7 7 0 00.907.57l.014.006.003.002A.5.5 0 0110.5 13h-5a.5.5 0 01-.224-.947l.003-.002.014-.007a5 5 0 00.268-.148 7 7 0 00.639-.421c.2-.15.387-.313.531-.475H2a2 2 0 01-2-2zm2-1a1 1 0 00-1 1v3a1 1 0 001 1h12a1 1 0 001-1V6a1 1 0 00-1-1z"/><path pid="1" d="M8 6.5a1 1 0 100 2 1 1 0 000-2m-2 1a2 2 0 114 0 2 2 0 01-4 0m7 0a.5.5 0 11-1 0 .5.5 0 011 0"/>',
    },
});
