/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    line: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 0c4.411 0 8 2.912 8 6.492 0 1.433-.555 2.723-1.715 3.994-1.678 1.932-5.431 4.285-6.285 4.645-.83.35-.734-.197-.696-.413l.003-.018.114-.685c.027-.204.055-.521-.026-.723-.09-.223-.444-.339-.704-.395C2.846 12.39 0 9.701 0 6.492 0 2.912 3.59 0 8 0M5.022 7.686H3.497V4.918a.156.156 0 00-.155-.156H2.78a.156.156 0 00-.156.156v3.486c0 .041.017.08.044.107v.001l.002.002.002.002a.15.15 0 00.108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 00-.155-.157m.791-2.924a.156.156 0 00-.156.156v3.486c0 .086.07.155.156.155h.562c.086 0 .155-.07.155-.155V4.918a.156.156 0 00-.155-.156zm3.863 0a.156.156 0 00-.156.156v2.07L7.923 4.832l-.013-.015v-.001l-.01-.01-.003-.003-.011-.009h-.001L7.88 4.79l-.003-.002-.005-.003-.008-.005h-.002l-.003-.002-.01-.004-.004-.002-.01-.003h-.002l-.003-.001-.009-.002h-.006l-.003-.001h-.004l-.002-.001h-.574a.156.156 0 00-.156.155v3.486c0 .086.07.155.156.155h.56c.087 0 .157-.07.157-.155v-2.07l1.6 2.16a.2.2 0 00.039.038l.001.001.01.006.004.002.008.004.007.003.005.002.01.003h.003a.2.2 0 00.04.006h.56c.087 0 .157-.07.157-.155V4.918a.156.156 0 00-.156-.156zm3.815.717v-.56a.156.156 0 00-.155-.157h-2.242a.16.16 0 00-.108.044h-.001l-.001.002-.002.003a.16.16 0 00-.044.107v3.486c0 .041.017.08.044.107l.002.003.002.002a.16.16 0 00.108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 00-.155-.157H11.81v-.589h1.525c.086 0 .155-.07.155-.156v-.56a.156.156 0 00-.155-.157H11.81v-.589h1.525c.086 0 .155-.07.155-.156z"/>',
    },
});
