/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'credit-card': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 4a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2zm2-1a1 1 0 00-1 1v1h14V4a1 1 0 00-1-1zm13 4H1v5a1 1 0 001 1h12a1 1 0 001-1z"/><path pid="1" d="M2 10a1 1 0 011-1h1a1 1 0 011 1v1a1 1 0 01-1 1H3a1 1 0 01-1-1z"/>',
    },
});
