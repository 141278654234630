/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-mp3': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2v-1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zm-4.911 9.67h-.443v-.609h.422a.7.7 0 00.322-.073.56.56 0 00.22-.2.5.5 0 00.076-.284.49.49 0 00-.176-.392.65.65 0 00-.442-.15.7.7 0 00-.252.041.6.6 0 00-.193.112.5.5 0 00-.179.349H7.71q.009-.235.102-.437.094-.202.27-.352.176-.152.428-.237.255-.085.583-.088.418-.003.723.132.304.135.472.372a.9.9 0 01.173.539.83.83 0 01-.12.478.96.96 0 01-.619.439v.041a1 1 0 01.718.434.9.9 0 01.144.521q.003.285-.117.507a1.1 1.1 0 01-.329.378q-.21.152-.486.234-.273.08-.583.08-.451 0-.77-.153a1.2 1.2 0 01-.487-.41 1.1 1.1 0 01-.178-.563h.726a.46.46 0 00.106.258.7.7 0 00.249.179 1 1 0 00.357.067.9.9 0 00.384-.076.6.6 0 00.252-.217.56.56 0 00.088-.319.56.56 0 00-.334-.522.8.8 0 00-.372-.079zM.706 15.925v-2.66h.038l.952 2.16h.516l.946-2.16h.038v2.66h.715v-3.999h-.8l-1.14 2.596h-.026l-1.14-2.596H0v4zm5.458-3.999h-1.6v4h.792v-1.342h.803q.43 0 .732-.173.304-.177.463-.475a1.4 1.4 0 00.161-.677q0-.374-.158-.677a1.2 1.2 0 00-.46-.477 1.4 1.4 0 00-.733-.179m.545 1.333a.8.8 0 01-.085.381.57.57 0 01-.237.24.8.8 0 01-.375.082h-.66v-1.406h.66q.328 0 .513.182.184.181.184.521"/>',
    },
});
