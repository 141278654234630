/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-fill-x': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11 5a3 3 0 11-6 0 3 3 0 016 0m-9 8c0 1 1 1 1 1h5.256A4.5 4.5 0 018 12.5a4.5 4.5 0 011.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"/><path pid="1" d="M12.5 16a3.5 3.5 0 100-7 3.5 3.5 0 000 7m-.646-4.854l.646.647.646-.647a.5.5 0 01.708.708l-.647.646.647.646a.5.5 0 01-.708.708l-.646-.647-.646.647a.5.5 0 01-.708-.708l.647-.646-.647-.646a.5.5 0 01.708-.708"/>',
    },
});
