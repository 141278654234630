/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'envelope-plus-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M.05 3.555A2 2 0 012 2h12a2 2 0 011.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.026A2 2 0 002 14h6.256A4.5 4.5 0 018 12.5a4.49 4.49 0 011.606-3.446l-.367-.225L8 9.586zM16 4.697v4.974A4.5 4.5 0 0012.5 8a4.5 4.5 0 00-1.965.45l-.338-.207z"/><path pid="1" d="M16 12.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0m-3.5-2a.5.5 0 00-.5.5v1h-1a.5.5 0 000 1h1v1a.5.5 0 001 0v-1h1a.5.5 0 000-1h-1v-1a.5.5 0 00-.5-.5"/>',
    },
});
