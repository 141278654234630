/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    duffle: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 5.75c1.388 0 2.673.193 3.609.385a18 18 0 011.43.354l.112.034.002.001h.001a.5.5 0 01-.308.952l-.004-.002-.018-.005a17 17 0 00-1.417-.354A17.3 17.3 0 008 6.75a17.3 17.3 0 00-3.408.365 17 17 0 00-1.416.354l-.018.005-.003.001a.5.5 0 11-.308-.95A17.3 17.3 0 018 5.75"/><path pid="1" d="M5.229 2.722c-.126.461-.19.945-.222 1.375-1.401.194-2.65.531-3.525 1.012C-.644 6.278.036 11.204.393 13.127a.954.954 0 00.95.772h13.314a.954.954 0 00.95-.772c.357-1.923 1.037-6.85-1.09-8.018-.873-.48-2.123-.818-3.524-1.012a7.4 7.4 0 00-.222-1.375c-.162-.593-.445-1.228-.971-1.622-1.115-.836-2.485-.836-3.6 0-.526.394-.81 1.03-.971 1.622M9.2 1.9c.26.195.466.57.606 1.085.088.322.142.667.173.998a23.3 23.3 0 00-3.958 0 6 6 0 01.173-.998c.14-.515.346-.89.606-1.085.76-.57 1.64-.57 2.4 0M8 4.9c2.475 0 4.793.402 6.036 1.085.238.13.472.406.655.93.183.522.28 1.195.303 1.952.047 1.486-.189 3.088-.362 4.032H1.368c-.173-.944-.409-2.545-.362-4.032.024-.757.12-1.43.303-1.952.183-.524.417-.8.655-.93C3.207 5.302 5.525 4.9 8 4.9"/>',
    },
});
