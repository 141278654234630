/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    substack: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M15 3.604H1v1.891h14v-1.89zM1 7.208V16l7-3.926L15 16V7.208zM15 0H1v1.89h14z"/>',
    },
});
