/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-richtext-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.293 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4.707A1 1 0 0013.707 4L10 .293A1 1 0 009.293 0M9.5 3.5v-2l3 3h-2a1 1 0 01-1-1M7 6.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0m-.861 1.542l1.33.886 1.854-1.855a.25.25 0 01.289-.047l1.888.974V9.5a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5V9s1.54-1.274 1.639-1.208M5 11h6a.5.5 0 010 1H5a.5.5 0 010-1m0 2h3a.5.5 0 010 1H5a.5.5 0 010-1"/>',
    },
});
