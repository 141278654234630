/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    luggage: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.5 1a.5.5 0 00-.5.5V5h-.5A1.5 1.5 0 000 6.5v7a1.5 1.5 0 001 1.415v.335a.75.75 0 001.5 0V15H4v-1H1.5a.5.5 0 01-.5-.5v-7a.5.5 0 01.5-.5h5a.5.5 0 01.5.5V7h1v-.5A1.5 1.5 0 006.5 5H6V1.5a.5.5 0 00-.5-.5zM5 5H3V2h2z"/><path pid="1" d="M3 7.5a.5.5 0 00-1 0v5a.5.5 0 001 0zM11 6a1.5 1.5 0 011.5 1.5V8h2A1.5 1.5 0 0116 9.5v5a1.5 1.5 0 01-1.5 1.5h-8A1.5 1.5 0 015 14.5v-5A1.5 1.5 0 016.5 8h2v-.5A1.5 1.5 0 0110 6zM9.5 7.5V8h2v-.5A.5.5 0 0011 7h-1a.5.5 0 00-.5.5M6 9.5v5a.5.5 0 00.5.5H7V9h-.5a.5.5 0 00-.5.5m7 5.5V9H8v6zm1.5 0a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5H14v6z"/>',
    },
});
