/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'house-slash': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M13.879 10.414a2.5 2.5 0 00-3.465 3.465zm.707.707l-3.465 3.465a2.501 2.501 0 003.465-3.465m-4.56-1.096a3.5 3.5 0 114.949 4.95 3.5 3.5 0 01-4.95-4.95z"/><path pid="1" d="M7.293 1.5a1 1 0 011.414 0L11 3.793V2.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v3.293l2.354 2.353a.5.5 0 01-.708.708L8 2.207l-5 5V13.5a.5.5 0 00.5.5h4a.5.5 0 010 1h-4A1.5 1.5 0 012 13.5V8.207l-.646.647a.5.5 0 11-.708-.708z"/>',
    },
});
