/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'pin-angle': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.828.722a.5.5 0 01.354.146l4.95 4.95a.5.5 0 010 .707c-.48.48-1.072.588-1.503.588-.177 0-.335-.018-.46-.039l-3.134 3.134a6 6 0 01.16 1.013c.046.702-.032 1.687-.72 2.375a.5.5 0 01-.707 0l-2.829-2.828-3.182 3.182c-.195.195-1.219.902-1.414.707s.512-1.22.707-1.414l3.182-3.182-2.828-2.829a.5.5 0 010-.707c.688-.688 1.673-.767 2.375-.72a6 6 0 011.013.16l3.134-3.133a3 3 0 01-.04-.461c0-.43.108-1.022.589-1.503a.5.5 0 01.353-.146m.122 2.112v-.002zm0-.002v.002a.5.5 0 01-.122.51L6.293 6.878a.5.5 0 01-.511.12H5.78l-.014-.004a5 5 0 00-.288-.076 5 5 0 00-.765-.116c-.422-.028-.836.008-1.175.15l5.51 5.509c.141-.34.177-.753.149-1.175a5 5 0 00-.192-1.054l-.004-.013v-.001a.5.5 0 01.12-.512l3.536-3.535a.5.5 0 01.532-.115l.096.022c.087.017.208.034.344.034q.172.002.343-.04L9.927 2.028q-.042.172-.04.343a1.8 1.8 0 00.062.46z"/>',
    },
});
