/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    bullseye: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 15A7 7 0 118 1a7 7 0 010 14m0 1A8 8 0 108 0a8 8 0 000 16"/><path pid="1" d="M8 13A5 5 0 118 3a5 5 0 010 10m0 1A6 6 0 108 2a6 6 0 000 12"/><path pid="2" d="M8 11a3 3 0 110-6 3 3 0 010 6m0 1a4 4 0 100-8 4 4 0 000 8"/><path pid="3" d="M9.5 8a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0"/>',
    },
});
