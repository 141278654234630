/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'receipt-cutoff': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3 4.5a.5.5 0 01.5-.5h6a.5.5 0 110 1h-6a.5.5 0 01-.5-.5m0 2a.5.5 0 01.5-.5h6a.5.5 0 110 1h-6a.5.5 0 01-.5-.5m0 2a.5.5 0 01.5-.5h6a.5.5 0 110 1h-6a.5.5 0 01-.5-.5m0 2a.5.5 0 01.5-.5h6a.5.5 0 010 1h-6a.5.5 0 01-.5-.5m0 2a.5.5 0 01.5-.5h6a.5.5 0 010 1h-6a.5.5 0 01-.5-.5M11.5 4a.5.5 0 000 1h1a.5.5 0 000-1zm0 2a.5.5 0 000 1h1a.5.5 0 000-1zm0 2a.5.5 0 000 1h1a.5.5 0 000-1zm0 2a.5.5 0 000 1h1a.5.5 0 000-1zm0 2a.5.5 0 000 1h1a.5.5 0 000-1z"/><path pid="1" d="M2.354.646a.5.5 0 00-.801.13l-.5 1A.5.5 0 001 2v13H.5a.5.5 0 000 1h15a.5.5 0 000-1H15V2a.5.5 0 00-.053-.224l-.5-1a.5.5 0 00-.8-.13L13 1.293l-.646-.647a.5.5 0 00-.708 0L11 1.293l-.646-.647a.5.5 0 00-.708 0L9 1.293 8.354.646a.5.5 0 00-.708 0L7 1.293 6.354.646a.5.5 0 00-.708 0L5 1.293 4.354.646a.5.5 0 00-.708 0L3 1.293zm-.217 1.198l.51.51a.5.5 0 00.707 0L4 1.707l.646.647a.5.5 0 00.708 0L6 1.707l.646.647a.5.5 0 00.708 0L8 1.707l.646.647a.5.5 0 00.708 0L10 1.707l.646.647a.5.5 0 00.708 0L12 1.707l.646.647a.5.5 0 00.708 0l.509-.51.137.274V15H2V2.118z"/>',
    },
});
