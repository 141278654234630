/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'usb-symbol': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.792.312l-1.533 2.3A.25.25 0 006.467 3H7.5v7.319a2.5 2.5 0 00-.515-.298L5.909 9.56A1.5 1.5 0 015 8.18v-.266a1.5 1.5 0 10-1 0v.266a2.5 2.5 0 001.515 2.298l1.076.461a1.5 1.5 0 01.888 1.129 2.001 2.001 0 101.021-.006v-.902a1.5 1.5 0 01.756-1.303l1.484-.848A2.5 2.5 0 0011.995 7h.755a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25h-2.5a.25.25 0 00-.25.25v2.5c0 .138.112.25.25.25h.741a1.5 1.5 0 01-.747 1.142L8.76 8.99a3 3 0 00-.26.17V3h1.033a.25.25 0 00.208-.389L8.208.312a.25.25 0 00-.416 0"/>',
    },
});
