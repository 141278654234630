/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    backpack4: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 9.5a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5zm1 .5v3h6v-3h-1v.5a.5.5 0 01-1 0V10z"/><path pid="1" d="M8 0a2 2 0 00-2 2H3.5a2 2 0 00-2 2v1c0 .52.198.993.523 1.349A.5.5 0 002 6.5V14a2 2 0 002 2h8a2 2 0 002-2V6.5a.5.5 0 00-.023-.151c.325-.356.523-.83.523-1.349V4a2 2 0 00-2-2H10a2 2 0 00-2-2m0 1a1 1 0 00-1 1h2a1 1 0 00-1-1M3 14V6.937q.24.062.5.063h4v.5a.5.5 0 001 0V7h4q.26 0 .5-.063V14a1 1 0 01-1 1H4a1 1 0 01-1-1m9.5-11a1 1 0 011 1v1a1 1 0 01-1 1h-9a1 1 0 01-1-1V4a1 1 0 011-1z"/>',
    },
});
