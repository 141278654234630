/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'usb-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M.5 5a.5.5 0 00-.5.5v5a.5.5 0 00.5.5h15a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5zm1.75 1.5h11.5a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H2.25A.25.25 0 012 7.75v-1a.25.25 0 01.25-.25"/>',
    },
});
