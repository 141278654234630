/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'device-hdd-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.785 9.896A3.001 3.001 0 008 4a3 3 0 00-.891 5.865c.667-.44 1.396-.91 1.955-1.268.224-.144.483.115.34.34zM9 7a1 1 0 11-2 0 1 1 0 012 0"/><path pid="1" d="M4 0a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2zm9 1.5a.5.5 0 11-1 0 .5.5 0 011 0m0 13a.5.5 0 11-1 0 .5.5 0 011 0m-9.5.5a.5.5 0 110-1 .5.5 0 010 1M4 1.5a.5.5 0 11-1 0 .5.5 0 011 0m2.882 11.177a1.102 1.102 0 01-1.56-1.559c.1-.098.396-.314.795-.588a4 4 0 111.946.47c-.537.813-1.02 1.515-1.181 1.677"/>',
    },
});
