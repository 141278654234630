/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    mouse: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 3a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2A.5.5 0 018 3m4 8a4 4 0 01-8 0V5a4 4 0 118 0zM8 0a5 5 0 00-5 5v6a5 5 0 0010 0V5a5 5 0 00-5-5"/>',
    },
});
