/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'fast-forward-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.596 7.304a.802.802 0 010 1.392l-6.363 3.692C.713 12.69 0 12.345 0 11.692V4.308c0-.653.713-.998 1.233-.696z"/><path pid="1" d="M15.596 7.304a.802.802 0 010 1.392l-6.363 3.692C8.713 12.69 8 12.345 8 11.692V4.308c0-.653.713-.998 1.233-.696z"/>',
    },
});
