/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'house-heart-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.293 1.5a1 1 0 011.414 0L11 3.793V2.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v3.293l2.354 2.353a.5.5 0 01-.708.707L8 2.207 1.354 8.853a.5.5 0 11-.708-.707z"/><path pid="1" d="M14 9.293l-6-6-6 6V13.5A1.5 1.5 0 003.5 15h9a1.5 1.5 0 001.5-1.5zm-6-.811c1.664-1.673 5.825 1.254 0 5.018-5.825-3.764-1.664-6.691 0-5.018"/>',
    },
});
