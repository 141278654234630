/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'bag-x-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M10.5 3.5a2.5 2.5 0 00-5 0V4h5zm1 0V4H15v10a2 2 0 01-2 2H3a2 2 0 01-2-2V4h3.5v-.5a3.5 3.5 0 117 0M6.854 8.146a.5.5 0 10-.708.708L7.293 10l-1.147 1.146a.5.5 0 00.708.708L8 10.707l1.146 1.147a.5.5 0 00.708-.708L8.707 10l1.147-1.146a.5.5 0 00-.708-.708L8 9.293z"/>',
    },
});
