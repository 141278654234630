/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    nvme: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1.5 4.5A.5.5 0 012 4h13.5a.5.5 0 01.5.5V7a.5.5 0 01-.5.5.5.5 0 000 1 .5.5 0 01.5.5v2.5a.5.5 0 01-.5.5H2a.5.5 0 01-.5-.5h-1A.5.5 0 010 11V7.5A.5.5 0 01.5 7h1a.25.25 0 000-.5h-1A.5.5 0 010 6V5a.5.5 0 01.5-.5zm1 .5a.5.5 0 01-.5.5h-.5a1.25 1.25 0 110 2.5H1v2.5h1a.5.5 0 01.5.5H15V9.415a1.5 1.5 0 010-2.83V5z"/><path pid="1" d="M4 6.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zM5 7v2h1V7zm3-.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5zM9 7v2h3V7z"/>',
    },
});
