/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sort-down-alt': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.5 3.5a.5.5 0 00-1 0v8.793l-1.146-1.147a.5.5 0 00-.708.708l2 1.999.007.007a.497.497 0 00.7-.006l2-2a.5.5 0 00-.707-.708L3.5 12.293zm4 .5a.5.5 0 010-1h1a.5.5 0 010 1zm0 3a.5.5 0 010-1h3a.5.5 0 010 1zm0 3a.5.5 0 010-1h5a.5.5 0 010 1zM7 12.5a.5.5 0 00.5.5h7a.5.5 0 000-1h-7a.5.5 0 00-.5.5"/>',
    },
});
