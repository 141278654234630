/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'calendar3-event-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M2 0a2 2 0 00-2 2h16a2 2 0 00-2-2zM0 14V3h16v11a2 2 0 01-2 2H2a2 2 0 01-2-2m12-8a1 1 0 102 0 1 1 0 00-2 0"/>',
    },
});
