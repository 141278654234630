/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    truck: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 3.5A1.5 1.5 0 011.5 2h9A1.5 1.5 0 0112 3.5V5h1.02a1.5 1.5 0 011.17.563l1.481 1.85a1.5 1.5 0 01.329.938V10.5a1.5 1.5 0 01-1.5 1.5H14a2 2 0 11-4 0H5a2 2 0 11-3.998-.085A1.5 1.5 0 010 10.5zm1.294 7.456A2 2 0 014.732 11h5.536a2 2 0 01.732-.732V3.5a.5.5 0 00-.5-.5h-9a.5.5 0 00-.5.5v7a.5.5 0 00.294.456M12 10a2 2 0 011.732 1h.768a.5.5 0 00.5-.5V8.35a.5.5 0 00-.11-.312l-1.48-1.85A.5.5 0 0013.02 6H12zm-9 1a1 1 0 100 2 1 1 0 000-2m9 0a1 1 0 100 2 1 1 0 000-2"/>',
    },
});
