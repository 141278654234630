/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'bell-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 16a2 2 0 002-2H6a2 2 0 002 2m.995-14.901a1 1 0 10-1.99 0A5 5 0 003 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901"/>',
    },
});
