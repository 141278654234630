/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'fast-forward': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.804 8L1 4.633v6.734zm.792-.696a.802.802 0 010 1.392l-6.363 3.692C.713 12.69 0 12.345 0 11.692V4.308c0-.653.713-.998 1.233-.696z"/><path pid="1" d="M14.804 8L9 4.633v6.734zm.792-.696a.802.802 0 010 1.392l-6.363 3.692C8.713 12.69 8 12.345 8 11.692V4.308c0-.653.713-.998 1.233-.696z"/>',
    },
});
