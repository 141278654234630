/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-ruled': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 2a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2zm2-1a1 1 0 00-1 1v4h10V2a1 1 0 00-1-1zm9 6H6v2h7zm0 3H6v2h7zm0 3H6v2h6a1 1 0 001-1zm-8 2v-2H3v1a1 1 0 001 1zm-2-3h2v-2H3zm0-3h2V7H3z"/>',
    },
});
