/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'slash-circle-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 8A8 8 0 110 8a8 8 0 0116 0m-4.646-2.646a.5.5 0 00-.708-.708l-6 6a.5.5 0 00.708.708z"/>',
    },
});
