/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'graph-down': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M0 0h1v15h15v1H0zm14.817 11.887a.5.5 0 00.07-.704l-4.5-5.5a.5.5 0 00-.74-.037L7.06 8.233 3.404 3.206a.5.5 0 00-.808.588l4 5.5a.5.5 0 00.758.06l2.609-2.61 4.15 5.073a.5.5 0 00.704.07"/>',
    },
});
