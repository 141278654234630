/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'chat-square-quote': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M14 1a1 1 0 011 1v8a1 1 0 01-1 1h-2.5a2 2 0 00-1.6.8L8 14.333 6.1 11.8a2 2 0 00-1.6-.8H2a1 1 0 01-1-1V2a1 1 0 011-1zM2 0a2 2 0 00-2 2v8a2 2 0 002 2h2.5a1 1 0 01.8.4l1.9 2.533a1 1 0 001.6 0l1.9-2.533a1 1 0 01.8-.4H14a2 2 0 002-2V2a2 2 0 00-2-2z"/><path pid="1" d="M7.066 4.76A1.665 1.665 0 004 5.668a1.667 1.667 0 002.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 10.6.58c1.486-1.54 1.293-3.214.682-4.112zm4 0A1.665 1.665 0 008 5.668a1.667 1.667 0 002.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 10.6.58c1.486-1.54 1.293-3.214.682-4.112z"/>',
    },
});
