/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-fill-lock': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11 5a3 3 0 11-6 0 3 3 0 016 0m-9 8c0 1 1 1 1 1h5v-1a2 2 0 01.01-.2 4.49 4.49 0 011.534-3.693Q8.844 9.002 8 9c-5 0-6 3-6 4m7 0a1 1 0 011-1v-1a2 2 0 114 0v1a1 1 0 011 1v2a1 1 0 01-1 1h-4a1 1 0 01-1-1zm3-3a1 1 0 00-1 1v1h2v-1a1 1 0 00-1-1"/>',
    },
});
