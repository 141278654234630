/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'ui-radios-grid': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.5 15a2.5 2.5 0 100-5 2.5 2.5 0 000 5m9-9a2.5 2.5 0 100-5 2.5 2.5 0 000 5m0 9a2.5 2.5 0 110-5 2.5 2.5 0 010 5M16 3.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0m-9 9a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0m5.5 3.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7m-9-11a1.5 1.5 0 110-3 1.5 1.5 0 010 3m0 2a3.5 3.5 0 100-7 3.5 3.5 0 000 7"/>',
    },
});
