/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    sunset: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.646 4.854a.5.5 0 00.708 0l1.5-1.5a.5.5 0 00-.708-.708l-.646.647V1.5a.5.5 0 00-1 0v1.793l-.646-.647a.5.5 0 10-.708.708zm-5.303-.51a.5.5 0 01.707 0l1.414 1.413a.5.5 0 01-.707.707L2.343 5.05a.5.5 0 010-.707zm11.314 0a.5.5 0 010 .706l-1.414 1.414a.5.5 0 11-.707-.707l1.414-1.414a.5.5 0 01.707 0zM8 7a3 3 0 012.599 4.5H5.4A3 3 0 018 7m3.71 4.5a4 4 0 10-7.418 0H.499a.5.5 0 000 1h15a.5.5 0 000-1h-3.79zM0 10a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2A.5.5 0 010 10m13 0a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5"/>',
    },
});
