/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'grid-3x2': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 3.5A1.5 1.5 0 011.5 2h13A1.5 1.5 0 0116 3.5v8a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 010 11.5zM1.5 3a.5.5 0 00-.5.5V7h4V3zM5 8H1v3.5a.5.5 0 00.5.5H5zm1 0v4h4V8zm4-1V3H6v4zm1 1v4h3.5a.5.5 0 00.5-.5V8zm0-1h4V3.5a.5.5 0 00-.5-.5H11z"/>',
    },
});
