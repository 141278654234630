/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'inboxes-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4.98 1a.5.5 0 00-.39.188L1.54 5H6a.5.5 0 01.5.5 1.5 1.5 0 003 0A.5.5 0 0110 5h4.46l-3.05-3.812A.5.5 0 0011.02 1zM3.81.563A1.5 1.5 0 014.98 0h6.04a1.5 1.5 0 011.17.563l3.7 4.625a.5.5 0 01.106.374l-.39 3.124A1.5 1.5 0 0114.117 10H1.883A1.5 1.5 0 01.394 8.686l-.39-3.124a.5.5 0 01.106-.374zM.125 11.17A.5.5 0 01.5 11H6a.5.5 0 01.5.5 1.5 1.5 0 003 0 .5.5 0 01.5-.5h5.5a.5.5 0 01.496.562l-.39 3.124A1.5 1.5 0 0114.117 16H1.883a1.5 1.5 0 01-1.489-1.314l-.39-3.124a.5.5 0 01.121-.393z"/>',
    },
});
