/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'usb-c': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.5 7.5a.5.5 0 000 1h9a.5.5 0 000-1z"/><path pid="1" d="M0 8a3 3 0 013-3h10a3 3 0 110 6H3a3 3 0 01-3-3m3-2a2 2 0 100 4h10a2 2 0 100-4z"/>',
    },
});
