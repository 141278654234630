/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'phone-landscape': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1 4.5a1 1 0 011-1h12a1 1 0 011 1v6a1 1 0 01-1 1H2a1 1 0 01-1-1zm-1 6a2 2 0 002 2h12a2 2 0 002-2v-6a2 2 0 00-2-2H2a2 2 0 00-2 2z"/><path pid="1" d="M14 7.5a1 1 0 10-2 0 1 1 0 002 0"/>',
    },
});
