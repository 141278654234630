/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'toggle2-on': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7 5H3a3 3 0 000 6h4a5 5 0 01-.584-1H3a2 2 0 110-4h3.416q.235-.537.584-1"/><path pid="1" d="M16 8A5 5 0 116 8a5 5 0 0110 0"/>',
    },
});
