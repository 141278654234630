/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-standing': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 3a1.5 1.5 0 100-3 1.5 1.5 0 000 3M6 6.75v8.5a.75.75 0 001.5 0V10.5a.5.5 0 011 0v4.75a.75.75 0 001.5 0v-8.5a.25.25 0 11.5 0v2.5a.75.75 0 001.5 0V6.5a3 3 0 00-3-3H7a3 3 0 00-3 3v2.75a.75.75 0 001.5 0v-2.5a.25.25 0 01.5 0"/>',
    },
});
