/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-exclamation': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11 5a3 3 0 11-6 0 3 3 0 016 0M8 7a2 2 0 100-4 2 2 0 000 4m.256 7a4.5 4.5 0 01-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/><path pid="1" d="M16 12.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0m-3.5-2a.5.5 0 00-.5.5v1.5a.5.5 0 001 0V11a.5.5 0 00-.5-.5m0 4a.5.5 0 100-1 .5.5 0 000 1"/>',
    },
});
