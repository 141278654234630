/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'border-width': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 3.5A.5.5 0 01.5 3h15a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5zm0 5A.5.5 0 01.5 8h15a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5zm0 4a.5.5 0 01.5-.5h15a.5.5 0 010 1H.5a.5.5 0 01-.5-.5"/>',
    },
});
