/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'noise-reduction': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M13 5.5a.5.5 0 11-1 0 .5.5 0 011 0m-1 1a.5.5 0 11-1 0 .5.5 0 011 0m-1 1a.5.5 0 11-1 0 .5.5 0 011 0m-1 1a.5.5 0 11-1 0 .5.5 0 011 0m-1 1a.5.5 0 11-1 0 .5.5 0 011 0m-1 1a.5.5 0 11-1 0 .5.5 0 011 0m-1 1a.5.5 0 11-1 0 .5.5 0 011 0m-1 1a.5.5 0 11-1 0 .5.5 0 011 0m1 1a.5.5 0 11-1 0 .5.5 0 011 0m.5-.5a.5.5 0 100-1 .5.5 0 000 1m1-1a.5.5 0 100-1 .5.5 0 000 1m1-1a.5.5 0 100-1 .5.5 0 000 1m1-1a.5.5 0 100-1 .5.5 0 000 1m1-1a.5.5 0 100-1 .5.5 0 000 1m1-1a.5.5 0 100-1 .5.5 0 000 1m1-1a.5.5 0 100-1 .5.5 0 000 1m-5 7a.5.5 0 100-1 .5.5 0 000 1m1.5-1.5a.5.5 0 11-1 0 .5.5 0 011 0m1-1a.5.5 0 11-1 0 .5.5 0 011 0m1-1a.5.5 0 11-1 0 .5.5 0 011 0m1-1a.5.5 0 11-1 0 .5.5 0 011 0m1-1a.5.5 0 11-1 0 .5.5 0 011 0m-3 5a.5.5 0 11-1 0 .5.5 0 011 0m.5-.5a.5.5 0 100-1 .5.5 0 000 1m1-1a.5.5 0 100-1 .5.5 0 000 1m1-1a.5.5 0 100-1 .5.5 0 000 1"/><path pid="1" d="M8 0a8 8 0 100 16A8 8 0 008 0M1 8a7 7 0 0112.83-3.875.5.5 0 10.15.235q.197.322.359.667a.5.5 0 10.359.932q.201.658.27 1.364a.5.5 0 10.021.282 7 7 0 01-.091 1.592.5.5 0 10-.172.75 7 7 0 01-.418 1.091.5.5 0 00-.3.555 7 7 0 01-.296.454.5.5 0 00-.712.453c0 .111.036.214.098.297a7 7 0 01-.3.3.5.5 0 00-.75.614 7 7 0 01-.455.298.5.5 0 00-.555.3 7 7 0 01-1.092.417.5.5 0 10-.749.172 7 7 0 01-1.592.091.5.5 0 10-.282-.021 7 7 0 01-1.364-.27A.498.498 0 005.5 14a.5.5 0 00-.473.339 7 7 0 01-.668-.36A.5.5 0 005 13.5a.5.5 0 10-.875.33A7 7 0 011 8"/>',
    },
});
