/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sign-stop-lights': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6 4a1 1 0 011-1h2a1 1 0 011 1v8a1 1 0 01-1 1H7a1 1 0 01-1-1zm2 2a1 1 0 100-2 1 1 0 000 2m0 3a1 1 0 100-2 1 1 0 000 2m1 2a1 1 0 10-2 0 1 1 0 002 0"/><path pid="1" d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098zm-1.4.7a.495.495 0 01.7 0l6.516 6.515a.495.495 0 010 .7L8.35 14.866a.495.495 0 01-.7 0L1.134 8.35a.495.495 0 010-.7L7.65 1.134z"/>',
    },
});
