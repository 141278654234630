/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    megaphone: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M13 2.5a1.5 1.5 0 013 0v11a1.5 1.5 0 01-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 015.51 15.1h-.548a1 1 0 01-.916-.599l-1.85-3.49-.202-.003A2.014 2.014 0 010 9V7a2.02 2.02 0 011.992-2.013 75 75 0 002.483-.075c3.043-.154 6.148-.849 8.525-2.199zm1 0v11a.5.5 0 001 0v-11a.5.5 0 00-1 0m-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233q.27.015.537.036c2.568.189 5.093.744 7.463 1.993zm-9 6.215v-4.13a95 95 0 01-1.992.052A1.02 1.02 0 001 7v2c0 .55.448 1.002 1.006 1.009A61 61 0 014 10.065m-.657.975l1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68 68 0 00-1.722-.082z"/>',
    },
});
