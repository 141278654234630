/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sign-intersection': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.25 4v3.25H4v1.5h3.25V12h1.5V8.75H12v-1.5H8.75V4z"/><path pid="1" d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098zm-1.4.7a.495.495 0 01.7 0l6.516 6.515a.495.495 0 010 .7L8.35 14.866a.495.495 0 01-.7 0L1.134 8.35a.495.495 0 010-.7L7.65 1.134z"/>',
    },
});
