/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'skip-start-circle-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 8A8 8 0 110 8a8 8 0 0116 0M9.71 5.093L7 7.028V5.5a.5.5 0 00-1 0v5a.5.5 0 001 0V8.972l2.71 1.935a.5.5 0 00.79-.407v-5a.5.5 0 00-.79-.407"/>',
    },
});
