/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'airplane-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 01.83 1.342V12a.5.5 0 01-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0110.5 16h-5a.5.5 0 01-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 010 12v-1.382a1.5 1.5 0 01.83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849"/>',
    },
});
