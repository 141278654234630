/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-otf': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2h-1v-1h1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM2.622 13.666v.522q0 .384-.117.641a.86.86 0 01-.322.387.9.9 0 01-.47.126.9.9 0 01-.47-.126.87.87 0 01-.32-.386 1.55 1.55 0 01-.117-.642v-.522q0-.386.117-.641a.87.87 0 01.32-.387.87.87 0 01.47-.129q.265 0 .47.13a.86.86 0 01.322.386q.117.255.117.641m.803.519v-.513q0-.565-.205-.972a1.46 1.46 0 00-.59-.63q-.38-.22-.916-.22-.534 0-.92.22a1.44 1.44 0 00-.589.627Q0 13.103 0 13.672v.513q0 .563.205.973.205.406.589.627.386.216.92.216.536 0 .917-.216a1.47 1.47 0 00.589-.627q.204-.41.205-.973m2 1.74v-3.337H6.56v-.662H3.497v.662H4.63v3.337h.794zm2.251-1.59v1.59h-.79v-3.999h2.548v.653H7.676v1.117h1.606v.638z"/>',
    },
});
