/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-x-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2M6.854 6.146L8 7.293l1.146-1.147a.5.5 0 11.708.708L8.707 8l1.147 1.146a.5.5 0 01-.708.708L8 8.707 6.854 9.854a.5.5 0 01-.708-.708L7.293 8 6.146 6.854a.5.5 0 11.708-.708"/>',
    },
});
