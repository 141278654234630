/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'pentagon-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.685.256a.5.5 0 01.63 0l7.421 6.03a.5.5 0 01.162.538l-2.788 8.827a.5.5 0 01-.476.349H3.366a.5.5 0 01-.476-.35L.102 6.825a.5.5 0 01.162-.538l7.42-6.03z"/>',
    },
});
