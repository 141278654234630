/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'floppy-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 1.5A1.5 1.5 0 011.5 0H3v5.5A1.5 1.5 0 004.5 7h7A1.5 1.5 0 0013 5.5V0h.086a1.5 1.5 0 011.06.44l1.415 1.414A1.5 1.5 0 0116 2.914V14.5a1.5 1.5 0 01-1.5 1.5H14v-5.5A1.5 1.5 0 0012.5 9h-9A1.5 1.5 0 002 10.5V16h-.5A1.5 1.5 0 010 14.5z"/><path pid="1" d="M3 16h10v-5.5a.5.5 0 00-.5-.5h-9a.5.5 0 00-.5.5zm9-16H4v5.5a.5.5 0 00.5.5h7a.5.5 0 00.5-.5zM9 1h2v4H9z"/>',
    },
});
