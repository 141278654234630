/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    prescription2: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7 6h2v2h2v2H9v2H7v-2H5V8h2z"/><path pid="1" d="M2 1a1 1 0 011-1h10a1 1 0 011 1v2a1 1 0 01-1 1v10.5a1.5 1.5 0 01-1.5 1.5h-7A1.5 1.5 0 013 14.5V4a1 1 0 01-1-1zm2 3v10.5a.5.5 0 00.5.5h7a.5.5 0 00.5-.5V4zM3 3h10V1H3z"/>',
    },
});
