/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'wifi-2': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.46 9.46 0 008 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 00-.063.745.525.525 0 00.652.065A8.46 8.46 0 018 7a8.46 8.46 0 014.577 1.336c.205.132.48.108.652-.065m-2.183 2.183c.226-.226.185-.605-.1-.75A6.5 6.5 0 008 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.5 5.5 0 018 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091zM9.06 12.44c.196-.196.198-.52-.04-.66A2 2 0 008 11.5a2 2 0 00-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 00.708 0l.707-.707z"/>',
    },
});
