/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'layer-forward': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.354.146a.5.5 0 00-.708 0l-3 3a.5.5 0 000 .708l1 1a.5.5 0 00.708 0L7 4.207V12H1a1 1 0 00-1 1v2a1 1 0 001 1h14a1 1 0 001-1v-2a1 1 0 00-1-1H9V4.207l.646.647a.5.5 0 00.708 0l1-1a.5.5 0 000-.708z"/><path pid="1" d="M1 7a1 1 0 00-1 1v2a1 1 0 001 1h4.5a.5.5 0 000-1H1V8h4.5a.5.5 0 000-1zm9.5 0a.5.5 0 000 1H15v2h-4.5a.5.5 0 000 1H15a1 1 0 001-1V8a1 1 0 00-1-1z"/>',
    },
});
