/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cloud-sleet': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M13.405 4.027a5.001 5.001 0 00-9.499-1.004A3.5 3.5 0 103.5 10H13a3 3 0 00.405-5.973M8.5 1a4 4 0 013.976 3.555.5.5 0 00.5.445H13a2 2 0 010 4H3.5a2.5 2.5 0 11.605-4.926.5.5 0 00.596-.329A4 4 0 018.5 1M2.375 13.5a.25.25 0 01.25.25v.57l.501-.287a.25.25 0 01.248.434l-.495.283.495.283a.25.25 0 01-.248.434l-.501-.286v.569a.25.25 0 11-.5 0v-.57l-.501.287a.25.25 0 01-.248-.434l.495-.283-.495-.283a.25.25 0 01.248-.434l.501.286v-.569a.25.25 0 01.25-.25m1.849-2.447a.5.5 0 01.223.67l-.5 1a.5.5 0 11-.894-.447l.5-1a.5.5 0 01.67-.223zM6.375 13.5a.25.25 0 01.25.25v.57l.501-.287a.25.25 0 01.248.434l-.495.283.495.283a.25.25 0 01-.248.434l-.501-.286v.569a.25.25 0 11-.5 0v-.57l-.501.287a.25.25 0 01-.248-.434l.495-.283-.495-.283a.25.25 0 01.248-.434l.501.286v-.569a.25.25 0 01.25-.25m1.849-2.447a.5.5 0 01.223.67l-.5 1a.5.5 0 11-.894-.447l.5-1a.5.5 0 01.67-.223zm2.151 2.447a.25.25 0 01.25.25v.57l.501-.287a.25.25 0 01.248.434l-.495.283.495.283a.25.25 0 01-.248.434l-.501-.286v.569a.25.25 0 11-.5 0v-.57l-.501.287a.25.25 0 01-.248-.434l.495-.283-.495-.283a.25.25 0 01.248-.434l.501.286v-.569a.25.25 0 01.25-.25m1.849-2.447a.5.5 0 01.223.67l-.5 1a.5.5 0 11-.894-.447l.5-1a.5.5 0 01.67-.223z"/>',
    },
});
