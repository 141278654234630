/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-wink-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 0a8 8 0 110 16A8 8 0 018 0M7 6.5C7 5.672 6.552 5 6 5s-1 .672-1 1.5S5.448 8 6 8s1-.672 1-1.5M4.285 9.567a.5.5 0 00-.183.683A4.5 4.5 0 008 12.5a4.5 4.5 0 003.898-2.25.5.5 0 10-.866-.5A3.5 3.5 0 018 11.5a3.5 3.5 0 01-3.032-1.75.5.5 0 00-.683-.183m5.152-3.31a.5.5 0 00-.874.486c.33.595.958 1.007 1.687 1.007s1.356-.412 1.687-1.007a.5.5 0 00-.874-.486.93.93 0 01-.813.493.93.93 0 01-.813-.493"/>',
    },
});
