/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sim-slash-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11.646.44l.897.896-1.703 1.703A1.5 1.5 0 0010.5 3h-5A1.5 1.5 0 004 4.5v5.379l-2 2V1.5A1.5 1.5 0 013.5 0h7.086a1.5 1.5 0 011.06.44M8.5 5.378L9.879 4H8.5zM5 8.879L6.879 7H5zm6-1.758L9.121 9H11zm-3.5 3.5L6.121 12H7.5zM5.5 13q-.175 0-.34-.039L2.502 15.62c.265.236.615.38.998.38h9a1.5 1.5 0 001.5-1.5V4.121l-2 2V11.5a1.5 1.5 0 01-1.5 1.5zM5 4.5a.5.5 0 01.5-.5h2v2H5zM8.5 10H11v1.5a.5.5 0 01-.5.5h-2zm6.354-8.146a.5.5 0 00-.708-.708l-13 13a.5.5 0 00.708.708z"/>',
    },
});
