/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-mov': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2v-1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zm-6.914 9.166v.522q0 .384-.117.641a.86.86 0 01-.323.387.9.9 0 01-.468.126.9.9 0 01-.472-.126.87.87 0 01-.32-.386 1.55 1.55 0 01-.117-.642v-.522q0-.386.118-.641a.87.87 0 01.319-.387.87.87 0 01.472-.129q.263 0 .468.13a.86.86 0 01.323.386q.117.255.117.641m.802.519v-.513q0-.565-.205-.972a1.46 1.46 0 00-.588-.63q-.381-.22-.917-.22-.534 0-.92.22a1.44 1.44 0 00-.59.627q-.204.406-.204.975v.513q0 .563.205.973.205.406.589.627.386.216.92.216.536 0 .917-.216.383-.22.588-.627.205-.41.205-.973m-7.182 1.74v-2.66h.038l.952 2.16h.516l.946-2.16h.038v2.66h.715v-3.999h-.8l-1.14 2.596h-.026l-1.14-2.596H0v4zm9.54 0h-.952l-1.34-3.999h.918l.896 3.138h.038l.888-3.138h.879z"/>',
    },
});
