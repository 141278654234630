/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-tsx': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2h-1v-1h1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM3.172 14.841a1.13 1.13 0 00.401.823q.193.162.478.252.283.091.665.091.507 0 .858-.158.354-.158.54-.44a1.17 1.17 0 00.187-.656q0-.336-.135-.56a1 1 0 00-.375-.357 2 2 0 00-.566-.21l-.62-.144a1 1 0 01-.405-.176.37.37 0 01-.144-.299q0-.234.185-.384.188-.152.513-.152.213 0 .369.068a.6.6 0 01.246.181.56.56 0 01.12.258h.75a1.1 1.1 0 00-.2-.566 1.2 1.2 0 00-.5-.41 1.8 1.8 0 00-.78-.152q-.438 0-.776.15-.336.149-.527.421-.19.273-.19.639 0 .302.122.524.124.223.352.367.228.143.54.213l.617.144q.311.073.463.193a.39.39 0 01.152.326.5.5 0 01-.084.29.56.56 0 01-.255.193 1.1 1.1 0 01-.413.07q-.177 0-.32-.04a.8.8 0 01-.249-.115.58.58 0 01-.255-.384zm-1.244 1.09v-3.337h1.136v-.662H0v.662h1.134v3.337zm7.076-3.999h.893l-1.274 2.007 1.254 1.992h-.909l-.85-1.415h-.034l-.853 1.415H6.37l1.239-2.016-1.228-1.983h.932l.832 1.438h.035z"/>',
    },
});
