/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-md': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2H9v-1h3a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM.706 13.189v2.66H0V11.85h.806l1.14 2.596h.026l1.14-2.596h.8v3.999h-.716v-2.66h-.038l-.946 2.159h-.516l-.952-2.16H.706zm3.919 2.66V11.85h1.459q.609 0 1.005.234t.589.68q.195.445.196 1.075 0 .634-.196 1.084-.197.451-.595.689-.396.237-1 .237H4.626zm1.353-3.354h-.562v2.707h.562q.279 0 .484-.082a.8.8 0 00.334-.252 1.1 1.1 0 00.196-.422q.067-.252.067-.592a2.1 2.1 0 00-.117-.753.9.9 0 00-.354-.454q-.238-.152-.61-.152"/>',
    },
});
