/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'credit-card-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 4a2 2 0 012-2h12a2 2 0 012 2v1H0zm0 3v5a2 2 0 002 2h12a2 2 0 002-2V7zm3 2h1a1 1 0 011 1v1a1 1 0 01-1 1H3a1 1 0 01-1-1v-1a1 1 0 011-1"/>',
    },
});
