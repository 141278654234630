/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'record-btn-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 12V4a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2m8-1a3 3 0 100-6 3 3 0 000 6"/>',
    },
});
