/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'align-bottom': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<rect pid="0" width="4" height="12" x="6" y="1" rx="1"/><path pid="1" d="M1.5 14a.5.5 0 000 1zm13 1a.5.5 0 000-1zm-13 0h13v-1h-13z"/>',
    },
});
