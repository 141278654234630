/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'building-slash': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M13.879 10.414a2.501 2.501 0 00-3.465 3.465zm.707.707l-3.465 3.465a2.501 2.501 0 003.465-3.465m-4.56-1.096a3.5 3.5 0 114.949 4.95 3.5 3.5 0 01-4.95-4.95z"/><path pid="1" d="M2 1a1 1 0 011-1h10a1 1 0 011 1v6.5a.5.5 0 01-1 0V1H3v14h3v-2.5a.5.5 0 01.5-.5H8v4H3a1 1 0 01-1-1z"/><path pid="2" d="M4.5 2a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm3 0a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm3 0a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm-6 3a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm3 0a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm3 0a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm-6 3a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm3 0a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z"/>',
    },
});
