/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'skip-start-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 4a.5.5 0 011 0v3.248l6.267-3.636c.54-.313 1.232.066 1.232.696v7.384c0 .63-.692 1.01-1.232.697L5 8.753V12a.5.5 0 01-1 0z"/>',
    },
});
