/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    alexa: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.996 0A8 8 0 000 8a8 8 0 006.93 7.93v-1.613a1.06 1.06 0 00-.717-1.008A5.6 5.6 0 012.4 7.865 5.58 5.58 0 018.054 2.4a5.6 5.6 0 015.535 5.81l-.002.046-.012.192-.005.061a5 5 0 01-.033.284l-.01.068c-.685 4.516-6.564 7.054-6.596 7.068A7.998 7.998 0 0015.992 8 8 8 0 007.996.001z"/>',
    },
});
