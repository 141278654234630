/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    upc: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3 4.5a.5.5 0 011 0v7a.5.5 0 01-1 0zm2 0a.5.5 0 011 0v7a.5.5 0 01-1 0zm2 0a.5.5 0 011 0v7a.5.5 0 01-1 0zm2 0a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v7a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5zm3 0a.5.5 0 011 0v7a.5.5 0 01-1 0z"/>',
    },
});
