/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    pass: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.5 5a.5.5 0 000 1h5a.5.5 0 000-1zm0 2a.5.5 0 000 1h3a.5.5 0 000-1z"/><path pid="1" d="M8 2a2 2 0 002-2h2.5A1.5 1.5 0 0114 1.5v13a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 012 14.5v-13A1.5 1.5 0 013.5 0H6a2 2 0 002 2m0 1a3 3 0 01-2.83-2H3.5a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-13a.5.5 0 00-.5-.5h-1.67A3 3 0 018 3"/>',
    },
});
