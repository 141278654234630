/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'layout-text-window': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3 6.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5m0 3a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5m.5 2.5a.5.5 0 000 1h5a.5.5 0 000-1z"/><path pid="1" d="M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2zm12 1a1 1 0 011 1v1H1V2a1 1 0 011-1zm1 3v10a1 1 0 01-1 1h-2V4zm-4 0v11H2a1 1 0 01-1-1V4z"/>',
    },
});
