/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'wallet-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1.5 2A1.5 1.5 0 000 3.5v2h6a.5.5 0 01.5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542s.987-.254 1.194-.542C9.42 6.644 9.5 6.253 9.5 6a.5.5 0 01.5-.5h6v-2A1.5 1.5 0 0014.5 2z"/><path pid="1" d="M16 6.5h-5.551a2.7 2.7 0 01-.443 1.042C9.613 8.088 8.963 8.5 8 8.5s-1.613-.412-2.006-.958A2.7 2.7 0 015.551 6.5H0v6A1.5 1.5 0 001.5 14h13a1.5 1.5 0 001.5-1.5z"/>',
    },
});
