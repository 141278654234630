/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    infinity: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.68 5.792L7.345 7.75 5.681 9.708a2.75 2.75 0 110-3.916zM8 6.978L6.416 5.113l-.014-.015a3.75 3.75 0 100 5.304l.014-.015L8 8.522l1.584 1.865.014.015a3.75 3.75 0 100-5.304l-.014.015zm.656.772l1.663-1.958a2.75 2.75 0 110 3.916z"/>',
    },
});
