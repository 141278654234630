/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-ai': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2H6v-1h6a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM1.113 14.82L.8 15.85H0l1.342-3.999h.926l1.336 3.999h-.841l-.314-1.028H1.113zm1.178-.588l-.49-1.617h-.034l-.49 1.617zm2.425-2.382v3.999h-.791V11.85h.79z"/>',
    },
});
