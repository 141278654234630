/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'arrow-up-left-circle': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M1 8a7 7 0 1014 0A7 7 0 001 8m15 0A8 8 0 110 8a8 8 0 0116 0m-5.904 2.803a.5.5 0 10.707-.707L6.707 6h2.768a.5.5 0 100-1H5.5a.5.5 0 00-.5.5v3.975a.5.5 0 001 0V6.707z"/>',
    },
});
