/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'clouds-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11.473 9a4.5 4.5 0 00-8.72-.99A3 3 0 003 14h8.5a2.5 2.5 0 10-.027-5"/><path pid="1" d="M14.544 9.772a3.5 3.5 0 00-2.225-1.676 5.5 5.5 0 00-6.337-4.002 4.002 4.002 0 017.392.91 2.5 2.5 0 011.17 4.769z"/>',
    },
});
