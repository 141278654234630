/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'postage-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4.5 3a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5z"/><path pid="1" d="M3.5 1a1 1 0 001-1h1a1 1 0 002 0h1a1 1 0 002 0h1a1 1 0 102 0H15v1a1 1 0 100 2v1a1 1 0 100 2v1a1 1 0 100 2v1a1 1 0 100 2v1a1 1 0 100 2v1h-1.5a1 1 0 10-2 0h-1a1 1 0 10-2 0h-1a1 1 0 10-2 0h-1a1 1 0 10-2 0H1v-1a1 1 0 100-2v-1a1 1 0 100-2V9a1 1 0 100-2V6a1 1 0 000-2V3a1 1 0 000-2V0h1.5a1 1 0 001 1M3 3v10a1 1 0 001 1h8a1 1 0 001-1V3a1 1 0 00-1-1H4a1 1 0 00-1 1"/>',
    },
});
