/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'ticket-perforated': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 4.85v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9z"/><path pid="1" d="M1.5 3A1.5 1.5 0 000 4.5V6a.5.5 0 00.5.5 1.5 1.5 0 110 3 .5.5 0 00-.5.5v1.5A1.5 1.5 0 001.5 13h13a1.5 1.5 0 001.5-1.5V10a.5.5 0 00-.5-.5 1.5 1.5 0 010-3A.5.5 0 0016 6V4.5A1.5 1.5 0 0014.5 3zM1 4.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v1.05a2.5 2.5 0 000 4.9v1.05a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-1.05a2.5 2.5 0 000-4.9z"/>',
    },
});
