/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cloud-fog2': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5 4a4 4 0 00-3.8 2.745.5.5 0 11-.949-.313 5.002 5.002 0 019.654.595A3 3 0 0113 13H.5a.5.5 0 010-1H13a2 2 0 00.001-4h-.026a.5.5 0 01-.5-.445A4 4 0 008.5 4M0 8.5A.5.5 0 01.5 8h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5m0 2a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5"/>',
    },
});
