/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'clipboard2-pulse': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.5 0a.5.5 0 01.5.5.5.5 0 00.5.5.5.5 0 01.5.5V2a.5.5 0 01-.5.5h-5A.5.5 0 015 2v-.5a.5.5 0 01.5-.5.5.5 0 00.5-.5.5.5 0 01.5-.5z"/><path pid="1" d="M3 2.5a.5.5 0 01.5-.5H4a.5.5 0 000-1h-.5A1.5 1.5 0 002 2.5v12A1.5 1.5 0 003.5 16h9a1.5 1.5 0 001.5-1.5v-12A1.5 1.5 0 0012.5 1H12a.5.5 0 000 1h.5a.5.5 0 01.5.5v12a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5z"/><path pid="2" d="M9.979 5.356a.5.5 0 00-.968.04L7.92 10.49l-.94-3.135a.5.5 0 00-.926-.08L4.69 10H4.5a.5.5 0 000 1H5a.5.5 0 00.447-.276l.936-1.873 1.138 3.793a.5.5 0 00.968-.04L9.58 7.51l.94 3.135A.5.5 0 0011 11h.5a.5.5 0 000-1h-.128z"/>',
    },
});
