/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    rss: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M14 1a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1zM2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2z"/><path pid="1" d="M5.5 12a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0m-3-8.5a1 1 0 011-1c5.523 0 10 4.477 10 10a1 1 0 11-2 0 8 8 0 00-8-8 1 1 0 01-1-1m0 4a1 1 0 011-1 6 6 0 016 6 1 1 0 11-2 0 4 4 0 00-4-4 1 1 0 01-1-1"/>',
    },
});
