/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'pci-card-network': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.5 9.5v-2h.214a.5.5 0 00.5-.5v-.5h2.572V7a.5.5 0 00.5.5h.214v2z"/><path pid="1" d="M0 1.5A.5.5 0 01.5 1h1a.5.5 0 01.5.5V4h13.5a.5.5 0 01.5.5v7a.5.5 0 01-.5.5H2v2.5a.5.5 0 01-1 0V2H.5a.5.5 0 01-.5-.5m6.714 4a.5.5 0 00-.5.5v.5H6a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V7a.5.5 0 00-.5-.5h-.214V6a.5.5 0 00-.5-.5z"/><path pid="2" d="M3 12.5h3.5v1a.5.5 0 01-.5.5H3.5a.5.5 0 01-.5-.5zm8 0H7v1a.5.5 0 00.5.5h3a.5.5 0 00.5-.5z"/>',
    },
});
