/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    scooter: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M9 2.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-.39l1.4 7a2.5 2.5 0 11-.98.195l-.189-.938-2.43 3.527A.5.5 0 019.5 13H4.95a2.5 2.5 0 110-1h4.287l2.831-4.11L11.09 3H9.5a.5.5 0 01-.5-.5M3.915 12a1.5 1.5 0 100 1H2.5a.5.5 0 010-1zm8.817-.789A1.499 1.499 0 0013.5 14a1.5 1.5 0 00.213-2.985l.277 1.387a.5.5 0 01-.98.196z"/>',
    },
});
