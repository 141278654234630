/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'clipboard-pulse': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M10 1.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h3a.5.5 0 00.5-.5zm-5 0A1.5 1.5 0 016.5 0h3A1.5 1.5 0 0111 1.5v1A1.5 1.5 0 019.5 4h-3A1.5 1.5 0 015 2.5zm-2 0h1v1H3a1 1 0 00-1 1V14a1 1 0 001 1h10a1 1 0 001-1V3.5a1 1 0 00-1-1h-1v-1h1a2 2 0 012 2V14a2 2 0 01-2 2H3a2 2 0 01-2-2V3.5a2 2 0 012-2m6.979 3.856a.5.5 0 00-.968.04L7.92 10.49l-.94-3.135a.5.5 0 00-.895-.133L4.232 10H3.5a.5.5 0 000 1h1a.5.5 0 00.416-.223l1.41-2.115 1.195 3.982a.5.5 0 00.968-.04L9.58 7.51l.94 3.135A.5.5 0 0011 11h1.5a.5.5 0 000-1h-1.128z"/>',
    },
});
