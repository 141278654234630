/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'phone-vibrate': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10 3a1 1 0 011 1v8a1 1 0 01-1 1H6a1 1 0 01-1-1V4a1 1 0 011-1zM6 2a2 2 0 00-2 2v8a2 2 0 002 2h4a2 2 0 002-2V4a2 2 0 00-2-2z"/><path pid="1" d="M8 12a1 1 0 100-2 1 1 0 000 2M1.599 4.058a.5.5 0 01.208.676A7 7 0 001 8c0 1.18.292 2.292.807 3.266a.5.5 0 01-.884.468A8 8 0 010 8c0-1.347.334-2.619.923-3.734a.5.5 0 01.676-.208m12.802 0a.5.5 0 01.676.208A8 8 0 0116 8a8 8 0 01-.923 3.734.5.5 0 01-.884-.468A7 7 0 0015 8c0-1.18-.292-2.292-.807-3.266a.5.5 0 01.208-.676M3.057 5.534a.5.5 0 01.284.648A5 5 0 003 8c0 .642.12 1.255.34 1.818a.5.5 0 11-.93.364A6 6 0 012 8c0-.769.145-1.505.41-2.182a.5.5 0 01.647-.284m9.886 0a.5.5 0 01.648.284C13.855 6.495 14 7.231 14 8s-.145 1.505-.41 2.182a.5.5 0 01-.93-.364C12.88 9.255 13 8.642 13 8s-.12-1.255-.34-1.818a.5.5 0 01.283-.648"/>',
    },
});
