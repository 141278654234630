/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'credit-card-2-front-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 4a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2zm2.5 1a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm0 3a.5.5 0 000 1h5a.5.5 0 000-1zm0 2a.5.5 0 000 1h1a.5.5 0 000-1zm3 0a.5.5 0 000 1h1a.5.5 0 000-1zm3 0a.5.5 0 000 1h1a.5.5 0 000-1zm3 0a.5.5 0 000 1h1a.5.5 0 000-1z"/>',
    },
});
