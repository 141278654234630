/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'signpost-2-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.293.707A1 1 0 007 1.414V2H2a1 1 0 00-1 1v2a1 1 0 001 1h5v1H2.5a1 1 0 00-.8.4L.725 8.7a.5.5 0 000 .6l.975 1.3a1 1 0 00.8.4H7v5h2v-5h5a1 1 0 001-1V8a1 1 0 00-1-1H9V6h4.5a1 1 0 00.8-.4l.975-1.3a.5.5 0 000-.6L14.3 2.4a1 1 0 00-.8-.4H9v-.586A1 1 0 007.293.707"/>',
    },
});
