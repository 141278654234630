/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-raised-hand': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6 6.207v9.043a.75.75 0 001.5 0V10.5a.5.5 0 011 0v4.75a.75.75 0 001.5 0v-8.5a.25.25 0 11.5 0v2.5a.75.75 0 001.5 0V6.5a3 3 0 00-3-3H6.236a1 1 0 01-.447-.106l-.33-.165A.83.83 0 015 2.488V.75a.75.75 0 00-1.5 0v2.083c0 .715.404 1.37 1.044 1.689L5.5 5c.32.32.5.754.5 1.207"/><path pid="1" d="M8 3a1.5 1.5 0 100-3 1.5 1.5 0 000 3"/>',
    },
});
