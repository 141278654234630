/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-sh': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2H8v-1h4a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM.111 15.29A1.2 1.2 0 010 14.84h.765a.58.58 0 00.255.384q.105.073.249.114.143.041.319.041.246 0 .413-.07a.56.56 0 00.255-.193.5.5 0 00.085-.29.39.39 0 00-.153-.326q-.151-.12-.462-.193l-.619-.143a1.7 1.7 0 01-.539-.214 1 1 0 01-.351-.367 1.1 1.1 0 01-.123-.524q0-.366.19-.639.19-.272.528-.422.336-.15.776-.149.457 0 .78.152.324.153.5.41.18.255.2.566h-.75a.56.56 0 00-.12-.258.6.6 0 00-.247-.181.9.9 0 00-.369-.068q-.326 0-.513.152a.47.47 0 00-.184.384q0 .18.143.3a1 1 0 00.405.175l.62.143q.327.075.566.211.24.136.375.358t.135.56q0 .37-.188.656a1.2 1.2 0 01-.539.439q-.351.158-.858.158-.381 0-.665-.09a1.4 1.4 0 01-.478-.252 1.1 1.1 0 01-.29-.375zm6.67-3.358v4h-.79v-1.715H4.308v1.714h-.792v-3.999h.792v1.626H5.99v-1.626z"/>',
    },
});
