/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'envelope-dash': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 2a2 2 0 00-2 2v8.01A2 2 0 002 14h5.5a.5.5 0 000-1H2a1 1 0 01-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 001 0V4a2 2 0 00-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 011-1h12a1 1 0 011 1v.217l-7 4.2z"/><path pid="1" d="M16 12.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0m-5.5 0a.5.5 0 00.5.5h3a.5.5 0 000-1h-3a.5.5 0 00-.5.5"/>',
    },
});
