/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'motherboard-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 7h3V4H5z"/><path pid="1" d="M1 2a2 2 0 012-2h11a2 2 0 012 2v11a2 2 0 01-2 2H3a2 2 0 01-2-2v-2H.5a.5.5 0 01-.5-.5v-1A.5.5 0 01.5 9H1V8H.5a.5.5 0 01-.5-.5v-1A.5.5 0 01.5 6H1V5H.5a.5.5 0 01-.5-.5v-2A.5.5 0 01.5 2zm11 .5a.5.5 0 00-1 0v7a.5.5 0 001 0zm2 0a.5.5 0 00-1 0v7a.5.5 0 001 0zM3.5 10a.5.5 0 000 1h6a.5.5 0 000-1zm0 2a.5.5 0 000 1h6a.5.5 0 000-1zM4 4h-.5a.5.5 0 000 1H4v1h-.5a.5.5 0 000 1H4a1 1 0 001 1v.5a.5.5 0 001 0V8h1v.5a.5.5 0 001 0V8a1 1 0 001-1h.5a.5.5 0 000-1H9V5h.5a.5.5 0 000-1H9a1 1 0 00-1-1v-.5a.5.5 0 00-1 0V3H6v-.5a.5.5 0 00-1 0V3a1 1 0 00-1 1m7 7.5v1a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5"/>',
    },
});
