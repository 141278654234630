/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'speaker-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9 4a1 1 0 11-2 0 1 1 0 012 0m-2.5 6.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0"/><path pid="1" d="M4 0a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2zm6 4a2 2 0 11-4 0 2 2 0 014 0M8 7a3.5 3.5 0 110 7 3.5 3.5 0 010-7"/>',
    },
});
