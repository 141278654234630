/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    intersect: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 2a2 2 0 012-2h8a2 2 0 012 2v2h2a2 2 0 012 2v8a2 2 0 01-2 2H6a2 2 0 01-2-2v-2H2a2 2 0 01-2-2zm5 10v2a1 1 0 001 1h8a1 1 0 001-1V6a1 1 0 00-1-1h-2v5a2 2 0 01-2 2zm6-8V2a1 1 0 00-1-1H2a1 1 0 00-1 1v8a1 1 0 001 1h2V6a2 2 0 012-2z"/>',
    },
});
