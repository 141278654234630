/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'lightbulb-off-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 6c0-.572.08-1.125.23-1.65l8.558 8.559A.5.5 0 0110.5 13h-5a.5.5 0 01-.46-.302l-.761-1.77a2 2 0 00-.453-.618A5.98 5.98 0 012 6m10.303 4.181L3.818 1.697a6 6 0 018.484 8.484zM5 14.5a.5.5 0 01.5-.5h5a.5.5 0 010 1l-.224.447a1 1 0 01-.894.553H6.618a1 1 0 01-.894-.553L5.5 15a.5.5 0 01-.5-.5M2.354 1.646a.5.5 0 10-.708.708l12 12a.5.5 0 00.708-.708z"/>',
    },
});
