/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-astonished-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 8A8 8 0 110 8a8 8 0 0116 0m-4.884-3.978a2.1 2.1 0 01.53.332.5.5 0 00.708-.708h-.001v-.001a2 2 0 00-.237-.197 3 3 0 00-.606-.345 3 3 0 00-2.168-.077.5.5 0 10.316.948 2 2 0 011.458.048m-4.774-.048a.5.5 0 00.316-.948 3 3 0 00-2.167.077 3.1 3.1 0 00-.773.478q-.036.03-.07.064l-.002.001a.5.5 0 10.728.689 2 2 0 01.51-.313 2 2 0 011.458-.048M7 6.5C7 5.672 6.552 5 6 5s-1 .672-1 1.5S5.448 8 6 8s1-.672 1-1.5m4 0c0-.828-.448-1.5-1-1.5s-1 .672-1 1.5S9.448 8 10 8s1-.672 1-1.5m-5.247 4.746c-.383.478.08 1.06.687.98q1.56-.202 3.12 0c.606.08 1.07-.502.687-.98C9.747 10.623 8.998 10 8 10s-1.747.623-2.247 1.246"/>',
    },
});
