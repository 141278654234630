/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    tablet: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12 1a1 1 0 011 1v12a1 1 0 01-1 1H4a1 1 0 01-1-1V2a1 1 0 011-1zM4 0a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2z"/><path pid="1" d="M8 14a1 1 0 100-2 1 1 0 000 2"/>',
    },
});
