/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-video3': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M14 9.5a2 2 0 11-4 0 2 2 0 014 0m-6 5.7c0 .8.8.8.8.8h6.4s.8 0 .8-.8-.8-3.2-4-3.2-4 2.4-4 3.2"/><path pid="1" d="M2 2a2 2 0 00-2 2v8a2 2 0 002 2h5.243c.122-.326.295-.668.526-1H2a1 1 0 01-1-1V4a1 1 0 011-1h12a1 1 0 011 1v7.81c.353.23.656.496.91.783Q16 12.312 16 12V4a2 2 0 00-2-2z"/>',
    },
});
