/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    screwdriver: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 .995L.995 0l3.064 2.19a1 1 0 01.417.809v.07c0 .264.105.517.291.704l5.677 5.676.909-.303a1 1 0 011.018.24l3.338 3.339a.995.995 0 010 1.406L14.13 15.71a.995.995 0 01-1.406 0l-3.337-3.34a1 1 0 01-.24-1.018l.302-.909-5.676-5.677a1 1 0 00-.704-.291H3a1 1 0 01-.81-.417zm11.293 9.595a.497.497 0 10-.703.703l2.984 2.984a.497.497 0 00.703-.703z"/>',
    },
});
