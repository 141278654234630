/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'credit-card-2-back-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 4a2 2 0 012-2h12a2 2 0 012 2v5H0zm11.5 1a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM0 11v1a2 2 0 002 2h12a2 2 0 002-2v-1z"/>',
    },
});
