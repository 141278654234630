/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'body-text': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M0 .5A.5.5 0 01.5 0h4a.5.5 0 010 1h-4A.5.5 0 010 .5m0 2A.5.5 0 01.5 2h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5m9 0a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5m-9 2A.5.5 0 01.5 4h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5m5 0a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5m7 0a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5m-12 2A.5.5 0 01.5 6h6a.5.5 0 010 1h-6a.5.5 0 01-.5-.5m8 0a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5m-8 2A.5.5 0 01.5 8h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5m7 0a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5m-7 2a.5.5 0 01.5-.5h8a.5.5 0 010 1h-8a.5.5 0 01-.5-.5m0 2a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5m0 2a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5"/>',
    },
});
