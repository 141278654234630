/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-richtext': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7 4.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0m-.861 1.542l1.33.886 1.854-1.855a.25.25 0 01.289-.047l1.888.974V7.5a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5V7s1.54-1.274 1.639-1.208M5 9a.5.5 0 000 1h6a.5.5 0 000-1zm0 2a.5.5 0 000 1h3a.5.5 0 000-1z"/><path pid="1" d="M2 2a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2zm10-1H4a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V2a1 1 0 00-1-1"/>',
    },
});
