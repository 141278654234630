/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    ladder: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4.5 1a.5.5 0 01.5.5V2h6v-.5a.5.5 0 011 0v14a.5.5 0 01-1 0V15H5v.5a.5.5 0 01-1 0v-14a.5.5 0 01.5-.5M5 14h6v-2H5zm0-3h6V9H5zm0-3h6V6H5zm0-3h6V3H5z"/>',
    },
});
