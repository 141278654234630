/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    hospital: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5 5.034v1.1l.953-.55.5.867L9 7l.953.55-.5.866-.953-.55v1.1h-1v-1.1l-.953.55-.5-.866L7 7l-.953-.55.5-.866.953.55v-1.1zM13.25 9a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25zM13 11.25a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25zm.25 1.75a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25zm-11-4a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 003 9.75v-.5A.25.25 0 002.75 9zm0 2a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25zM2 13.25a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25z"/><path pid="1" d="M5 1a1 1 0 011-1h4a1 1 0 011 1v1a1 1 0 011 1v4h3a1 1 0 011 1v7a1 1 0 01-1 1H1a1 1 0 01-1-1V8a1 1 0 011-1h3V3a1 1 0 011-1zm2 14h2v-3H7zm3 0h1V3H5v12h1v-3a1 1 0 011-1h2a1 1 0 011 1zm0-14H6v1h4zm2 7v7h3V8zm-8 7V8H1v7z"/>',
    },
});
