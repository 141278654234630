/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    power: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.5 1v7h1V1z"/><path pid="1" d="M3 8.812a5 5 0 012.578-4.375l-.485-.874A6 6 0 1011 3.616l-.501.865A5 5 0 113 8.812"/>',
    },
});
