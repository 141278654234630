/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    flower3: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11.424 8c.437-.052.811-.136 1.04-.268a2 2 0 00-2-3.464c-.229.132-.489.414-.752.767C9.886 4.63 10 4.264 10 4a2 2 0 10-4 0c0 .264.114.63.288 1.035-.263-.353-.523-.635-.752-.767a2 2 0 00-2 3.464c.229.132.603.216 1.04.268-.437.052-.811.136-1.04.268a2 2 0 102 3.464c.229-.132.489-.414.752-.767C6.114 11.37 6 11.736 6 12a2 2 0 104 0c0-.264-.114-.63-.288-1.035.263.353.523.635.752.767a2 2 0 102-3.464c-.229-.132-.603-.216-1.04-.268M9 4a2 2 0 01-.045.205q-.059.2-.183.484a13 13 0 01-.637 1.223L8 6.142l-.135-.23a13 13 0 01-.637-1.223 4 4 0 01-.183-.484A2 2 0 017 4a1 1 0 112 0M3.67 5.5a1 1 0 011.366-.366 2 2 0 01.156.142q.142.15.326.4c.245.333.502.747.742 1.163l.13.232-.265.002a13 13 0 01-1.379-.06 4 4 0 01-.51-.083 2 2 0 01-.2-.064A1 1 0 013.67 5.5m1.366 5.366a1 1 0 01-1-1.732l.047-.02q.055-.02.153-.044.202-.048.51-.083a13 13 0 011.379-.06q.135 0 .266.002l-.131.232c-.24.416-.497.83-.742 1.163a4 4 0 01-.327.4 2 2 0 01-.155.142M9 12a1 1 0 01-2 0 2 2 0 01.045-.206q.058-.198.183-.483c.166-.378.396-.808.637-1.223L8 9.858l.135.23c.241.415.47.845.637 1.223q.124.285.183.484A1.3 1.3 0 019 12m3.33-6.5a1 1 0 01-.366 1.366 2 2 0 01-.2.064q-.202.048-.51.083c-.412.045-.898.061-1.379.06q-.135 0-.266-.002l.131-.232c.24-.416.497-.83.742-1.163a4 4 0 01.327-.4q.07-.074.114-.11l.041-.032a1 1 0 011.366.366m-1.366 5.366a2 2 0 01-.155-.141 4 4 0 01-.327-.4A13 13 0 019.74 9.16l-.13-.232.265-.002c.48-.001.967.015 1.379.06q.308.035.51.083.098.024.153.044l.048.02a1 1 0 11-1 1.732zM8 9a1 1 0 110-2 1 1 0 010 2"/>',
    },
});
