/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'slash-square-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2zm9.354 5.354l-6 6a.5.5 0 01-.708-.708l6-6a.5.5 0 01.708.708"/>',
    },
});
