/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-medical': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5 4.5a.5.5 0 00-1 0v.634l-.549-.317a.5.5 0 10-.5.866L7 6l-.549.317a.5.5 0 10.5.866l.549-.317V7.5a.5.5 0 101 0v-.634l.549.317a.5.5 0 10.5-.866L9 6l.549-.317a.5.5 0 10-.5-.866l-.549.317zM5.5 9a.5.5 0 000 1h5a.5.5 0 000-1zm0 2a.5.5 0 000 1h5a.5.5 0 000-1z"/><path pid="1" d="M2 2a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2zm10-1H4a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V2a1 1 0 00-1-1"/>',
    },
});
