/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'bookmark-star-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M2 15.5V2a2 2 0 012-2h8a2 2 0 012 2v13.5a.5.5 0 01-.74.439L8 13.069l-5.26 2.87A.5.5 0 012 15.5M8.16 4.1a.178.178 0 00-.32 0l-.634 1.285a.18.18 0 01-.134.098l-1.42.206a.178.178 0 00-.098.303L6.58 6.993c.042.041.061.1.051.158L6.39 8.565a.178.178 0 00.258.187l1.27-.668a.18.18 0 01.165 0l1.27.668a.178.178 0 00.257-.187L9.368 7.15a.18.18 0 01.05-.158l1.028-1.001a.178.178 0 00-.098-.303l-1.42-.206a.18.18 0 01-.134-.098z"/>',
    },
});
