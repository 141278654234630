/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'diagram-2-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 017.5 2h1A1.5 1.5 0 0110 3.5v1A1.5 1.5 0 018.5 6v1H11a.5.5 0 01.5.5v1a.5.5 0 01-1 0V8h-5v.5a.5.5 0 01-1 0v-1A.5.5 0 015 7h2.5V6A1.5 1.5 0 016 4.5zm-3 8A1.5 1.5 0 014.5 10h1A1.5 1.5 0 017 11.5v1A1.5 1.5 0 015.5 14h-1A1.5 1.5 0 013 12.5zm6 0a1.5 1.5 0 011.5-1.5h1a1.5 1.5 0 011.5 1.5v1a1.5 1.5 0 01-1.5 1.5h-1A1.5 1.5 0 019 12.5z"/>',
    },
});
