/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'backpack-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 13v-3h4v.5a.5.5 0 001 0V10h1v3z"/><path pid="1" d="M6 2v.341C3.67 3.165 2 5.388 2 8v5.5A2.5 2.5 0 004.5 16h7a2.5 2.5 0 002.5-2.5V8a6 6 0 00-4-5.659V2a2 2 0 10-4 0m2-1a1 1 0 011 1v.083a6 6 0 00-2 0V2a1 1 0 011-1m0 3a4 4 0 013.96 3.43.5.5 0 11-.99.14 3 3 0 00-5.94 0 .5.5 0 11-.99-.14A4 4 0 018 4M4.5 9h7a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5"/>',
    },
});
