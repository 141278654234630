/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-smile-upside-down': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 1a7 7 0 100 14A7 7 0 008 1m0-1a8 8 0 110 16A8 8 0 018 0"/><path pid="1" d="M4.285 6.433a.5.5 0 00.683-.183A3.5 3.5 0 018 4.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 00.866-.5A4.5 4.5 0 008 3.5a4.5 4.5 0 00-3.898 2.25.5.5 0 00.183.683M7 9.5C7 8.672 6.552 8 6 8s-1 .672-1 1.5.448 1.5 1 1.5 1-.672 1-1.5m4 0c0-.828-.448-1.5-1-1.5s-1 .672-1 1.5.448 1.5 1 1.5 1-.672 1-1.5"/>',
    },
});
