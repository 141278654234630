/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'send-slash': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M15.964.686a.5.5 0 00-.65-.65L.767 5.855a.75.75 0 00-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 00.844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.5.5 0 10.928.372zm-2.54 1.183L5.93 9.363 1.591 6.602z"/><path pid="1" d="M14.975 10.025a3.5 3.5 0 10-4.95 4.95 3.5 3.5 0 004.95-4.95m-4.243.707a2.5 2.5 0 013.147-.318l-3.465 3.465a2.5 2.5 0 01.318-3.147m.39 3.854l3.464-3.465a2.501 2.501 0 01-3.465 3.465z"/>',
    },
});
