/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'volume-off-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10.717 3.55A.5.5 0 0111 4v8a.5.5 0 01-.812.39L7.825 10.5H5.5A.5.5 0 015 10V6a.5.5 0 01.5-.5h2.325l2.363-1.89a.5.5 0 01.529-.06"/>',
    },
});
