/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'gpu-card': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 8a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0m7.5-1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3"/><path pid="1" d="M0 1.5A.5.5 0 01.5 1h1a.5.5 0 01.5.5V4h13.5a.5.5 0 01.5.5v7a.5.5 0 01-.5.5H2v2.5a.5.5 0 01-1 0V2H.5a.5.5 0 01-.5-.5m5.5 4a2.5 2.5 0 100 5 2.5 2.5 0 000-5M9 8a2.5 2.5 0 105 0 2.5 2.5 0 00-5 0"/><path pid="2" d="M3 12.5h3.5v1a.5.5 0 01-.5.5H3.5a.5.5 0 01-.5-.5zm4 1v-1h4v1a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5"/>',
    },
});
