/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'star-half': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.354 5.119L7.538.792A.52.52 0 018 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.54.54 0 0116 6.32a.55.55 0 01-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.5.5 0 01-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 01-.172-.403.6.6 0 01.085-.302.51.51 0 01.37-.245zM8 12.027a.5.5 0 01.232.056l3.686 1.894-.694-3.957a.56.56 0 01.162-.505l2.907-2.77-4.052-.576a.53.53 0 01-.393-.288L8.001 2.223 8 2.226z"/>',
    },
});
