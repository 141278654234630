/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    scissors: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.5 3.5c-.614-.884-.074-1.962.858-2.5L8 7.226 11.642 1c.932.538 1.472 1.616.858 2.5L8.81 8.61l1.556 2.661a2.5 2.5 0 11-.794.637L8 9.73l-1.572 2.177a2.5 2.5 0 11-.794-.637L7.19 8.61zm2.5 10a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0m7 0a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0"/>',
    },
});
