/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    cassette: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 8a1 1 0 100-2 1 1 0 000 2m9-1a1 1 0 11-2 0 1 1 0 012 0M7 6a1 1 0 000 2h2a1 1 0 100-2z"/><path pid="1" d="M1.5 2A1.5 1.5 0 000 3.5v9A1.5 1.5 0 001.5 14h13a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0014.5 2zM1 3.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v9a.5.5 0 01-.5.5h-.691l-1.362-2.724A.5.5 0 0012 10H4a.5.5 0 00-.447.276L2.19 13H1.5a.5.5 0 01-.5-.5zM11.691 11l1 2H3.309l1-2z"/>',
    },
});
