/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'battery-charging': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.585 2.568a.5.5 0 01.226.58L8.677 6.832h1.99a.5.5 0 01.364.843l-5.334 5.667a.5.5 0 01-.842-.49L5.99 9.167H4a.5.5 0 01-.364-.843l5.333-5.667a.5.5 0 01.616-.09z"/><path pid="1" d="M2 4h4.332l-.94 1H2a1 1 0 00-1 1v4a1 1 0 001 1h2.38l-.308 1H2a2 2 0 01-2-2V6a2 2 0 012-2"/><path pid="2" d="M2 6h2.45L2.908 7.639A1.5 1.5 0 003.313 10H2zm8.595-2l-.308 1H12a1 1 0 011 1v4a1 1 0 01-1 1H9.276l-.942 1H12a2 2 0 002-2V6a2 2 0 00-2-2z"/><path pid="3" d="M12 10h-1.783l1.542-1.639q.146-.156.241-.34zm0-3.354V6h-.646a1.5 1.5 0 01.646.646M16 8a1.5 1.5 0 01-1.5 1.5v-3A1.5 1.5 0 0116 8"/>',
    },
});
