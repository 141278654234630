/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'envelope-open': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.47 1.318a1 1 0 00-.94 0l-6 3.2A1 1 0 001 5.4v.817l5.75 3.45L8 8.917l1.25.75L15 6.217V5.4a1 1 0 00-.53-.882zM15 7.383l-4.778 2.867L15 13.117zm-.035 6.88L8 10.082l-6.965 4.18A1 1 0 002 15h12a1 1 0 00.965-.738zM1 13.116l4.778-2.867L1 7.383v5.734zM7.059.435a2 2 0 011.882 0l6 3.2A2 2 0 0116 5.4V14a2 2 0 01-2 2H2a2 2 0 01-2-2V5.4a2 2 0 011.059-1.765z"/>',
    },
});
