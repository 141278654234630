/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'palette-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.433 10.07C14.133 10.585 16 11.15 16 8a8 8 0 10-8 8c1.996 0 1.826-1.504 1.649-3.08-.124-1.101-.252-2.237.351-2.92.465-.527 1.42-.237 2.433.07M8 5a1.5 1.5 0 110-3 1.5 1.5 0 010 3m4.5 3a1.5 1.5 0 110-3 1.5 1.5 0 010 3M5 6.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0m.5 6.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3"/>',
    },
});
