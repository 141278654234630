/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'arrow-through-heart-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M2.854 15.854A.5.5 0 012 15.5V14H.5a.5.5 0 01-.354-.854l1.5-1.5A.5.5 0 012 11.5h1.793l3.103-3.104a.5.5 0 11.708.708L4.5 12.207V14a.5.5 0 01-.146.354zM16 3.5a.5.5 0 01-.854.354L14 2.707l-1.006 1.006c.236.248.44.531.6.845.562 1.096.585 2.517-.213 4.092-.793 1.563-2.395 3.288-5.105 5.08L8 13.912l-.276-.182A24 24 0 015.8 12.323L8.31 9.81a1.5 1.5 0 00-2.122-2.122L3.657 10.22a9 9 0 01-1.039-1.57c-.798-1.576-.775-2.997-.213-4.093C3.426 2.565 6.18 1.809 8 3.233c1.25-.98 2.944-.928 4.212-.152L13.292 2 12.147.854A.5.5 0 0112.5 0h3a.5.5 0 01.5.5z"/>',
    },
});
