/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sign-turn-slight-right': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.335 6.982l.8 1.386a.25.25 0 00.451-.039l1.06-2.882a.25.25 0 00-.192-.333l-3.026-.523a.25.25 0 00-.26.371l.667 1.154-.621.373A2.5 2.5 0 006 8.632V11h1V8.632a1.5 1.5 0 01.728-1.286z"/><path pid="1" fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.48 1.48 0 010-2.098zm1.4.7a.495.495 0 00-.7 0L1.134 7.65a.495.495 0 000 .7l6.516 6.516a.495.495 0 00.7 0l6.516-6.516a.495.495 0 000-.7L8.35 1.134z"/>',
    },
});
