/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    toggles: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4.5 9a3.5 3.5 0 100 7h7a3.5 3.5 0 100-7zm7 6a2.5 2.5 0 110-5 2.5 2.5 0 010 5m-7-14a2.5 2.5 0 100 5 2.5 2.5 0 000-5m2.45 0A3.5 3.5 0 018 3.5 3.5 3.5 0 016.95 6h4.55a2.5 2.5 0 000-5zM4.5 0h7a3.5 3.5 0 110 7h-7a3.5 3.5 0 110-7"/>',
    },
});
