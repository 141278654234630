/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'suitcase-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6 .5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5V3h1.5A1.5 1.5 0 0113 4.5v9a1.5 1.5 0 01-1.004 1.416A1 1 0 1110 15H6a1 1 0 11-1.997-.084A1.5 1.5 0 013 13.5v-9A1.5 1.5 0 014.5 3H6zM9 1H7v2h2zM6 5.5a.5.5 0 00-1 0v7a.5.5 0 001 0zm2.5 0a.5.5 0 00-1 0v7a.5.5 0 001 0zm2.5 0a.5.5 0 00-1 0v7a.5.5 0 001 0z"/>',
    },
});
