/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-text': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 4a.5.5 0 000 1h6a.5.5 0 000-1zm-.5 2.5A.5.5 0 015 6h6a.5.5 0 010 1H5a.5.5 0 01-.5-.5M5 8a.5.5 0 000 1h6a.5.5 0 000-1zm0 2a.5.5 0 000 1h3a.5.5 0 000-1z"/><path pid="1" d="M2 2a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2zm10-1H4a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V2a1 1 0 00-1-1"/>',
    },
});
