/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'people-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 100-6 3 3 0 000 6m-5.784 6A2.24 2.24 0 015 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 005 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 100-5 2.5 2.5 0 000 5"/>',
    },
});
