/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'house-gear-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.293 1.5a1 1 0 011.414 0L11 3.793V2.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v3.293l2.354 2.353a.5.5 0 01-.708.708L8 2.207 1.354 8.854a.5.5 0 11-.708-.708z"/><path pid="1" d="M11.07 9.047a1.5 1.5 0 00-1.742.26l-.02.021a1.5 1.5 0 00-.261 1.742 1.5 1.5 0 000 2.86 1.5 1.5 0 00-.12 1.07H3.5A1.5 1.5 0 012 13.5V9.293l6-6 4.724 4.724a1.5 1.5 0 00-1.654 1.03"/><path pid="2" d="M13.158 9.608l-.043-.148c-.181-.613-1.049-.613-1.23 0l-.043.148a.64.64 0 01-.921.382l-.136-.074c-.561-.306-1.175.308-.87.869l.075.136a.64.64 0 01-.382.92l-.148.045c-.613.18-.613 1.048 0 1.229l.148.043a.64.64 0 01.382.921l-.074.136c-.306.561.308 1.175.869.87l.136-.075a.64.64 0 01.92.382l.045.149c.18.612 1.048.612 1.229 0l.043-.15a.64.64 0 01.921-.38l.136.074c.561.305 1.175-.309.87-.87l-.075-.136a.64.64 0 01.382-.92l.149-.044c.612-.181.612-1.049 0-1.23l-.15-.043a.64.64 0 01-.38-.921l.074-.136c.305-.561-.309-1.175-.87-.87l-.136.075a.64.64 0 01-.92-.382zM12.5 14a1.5 1.5 0 110-3 1.5 1.5 0 010 3"/>',
    },
});
