/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-json': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM4.151 15.29a1.2 1.2 0 01-.111-.449h.764a.58.58 0 00.255.384q.105.073.25.114.142.041.319.041.245 0 .413-.07a.56.56 0 00.255-.193.5.5 0 00.084-.29.39.39 0 00-.152-.326q-.152-.12-.463-.193l-.618-.143a1.7 1.7 0 01-.539-.214 1 1 0 01-.352-.367 1.1 1.1 0 01-.123-.524q0-.366.19-.639.192-.272.528-.422.337-.15.777-.149.456 0 .779.152.326.153.5.41.18.255.2.566h-.75a.56.56 0 00-.12-.258.6.6 0 00-.246-.181.9.9 0 00-.37-.068q-.324 0-.512.152a.47.47 0 00-.185.384q0 .18.144.3a1 1 0 00.404.175l.621.143q.326.075.566.211a1 1 0 01.375.358q.135.222.135.56 0 .37-.188.656a1.2 1.2 0 01-.539.439q-.351.158-.858.158-.381 0-.665-.09a1.4 1.4 0 01-.478-.252 1.1 1.1 0 01-.29-.375m-3.104-.033a1.3 1.3 0 01-.082-.466h.764a.6.6 0 00.074.27.5.5 0 00.454.246q.285 0 .422-.164.137-.165.137-.466v-2.745h.791v2.725q0 .66-.357 1.005-.355.345-.985.345a1.6 1.6 0 01-.568-.094 1.15 1.15 0 01-.407-.266 1.1 1.1 0 01-.243-.39m9.091-1.585v.522q0 .384-.117.641a.86.86 0 01-.322.387.9.9 0 01-.47.126.9.9 0 01-.47-.126.87.87 0 01-.32-.387 1.55 1.55 0 01-.117-.641v-.522q0-.386.117-.641a.87.87 0 01.32-.387.87.87 0 01.47-.129q.265 0 .47.129a.86.86 0 01.322.387q.117.255.117.641m.803.519v-.513q0-.565-.205-.973a1.46 1.46 0 00-.59-.63q-.38-.22-.916-.22-.534 0-.92.22a1.44 1.44 0 00-.589.628q-.205.407-.205.975v.513q0 .562.205.973.205.407.589.626.386.217.92.217.536 0 .917-.217.384-.22.589-.626.204-.41.205-.973m1.29-.935v2.675h-.746v-3.999h.662l1.752 2.66h.032v-2.66h.75v4h-.656l-1.761-2.676z"/>',
    },
});
