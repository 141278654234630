/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'circle-half': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 15A7 7 0 108 1zm0 1A8 8 0 118 0a8 8 0 010 16"/>',
    },
});
