/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'modem-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7 0a1.5 1.5 0 00-1.5 1.5v11a1.5 1.5 0 001.404 1.497c-.35.305-.872.678-1.628 1.056A.5.5 0 005.5 16h5a.5.5 0 00.224-.947c-.756-.378-1.278-.75-1.628-1.056A1.5 1.5 0 0010.5 12.5v-11A1.5 1.5 0 009 0zm1 3a.5.5 0 110-1 .5.5 0 010 1m0 2a.5.5 0 110-1 .5.5 0 010 1m.5 1.5a.5.5 0 11-1 0 .5.5 0 011 0M8 9a.5.5 0 110-1 .5.5 0 010 1"/>',
    },
});
