/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'hdd-rack-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 2a2 2 0 00-2 2v1a2 2 0 002 2h1v2H2a2 2 0 00-2 2v1a2 2 0 002 2h12a2 2 0 002-2v-1a2 2 0 00-2-2h-1V7h1a2 2 0 002-2V4a2 2 0 00-2-2zm.5 3a.5.5 0 110-1 .5.5 0 010 1m2 0a.5.5 0 110-1 .5.5 0 010 1m-2 7a.5.5 0 110-1 .5.5 0 010 1m2 0a.5.5 0 110-1 .5.5 0 010 1M12 7v2H4V7z"/>',
    },
});
