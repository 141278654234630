/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'geo-alt-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 16s6-5.686 6-10A6 6 0 002 6c0 4.314 6 10 6 10m0-7a3 3 0 110-6 3 3 0 010 6"/>',
    },
});
