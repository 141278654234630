/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'balloon-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M8.48 10.901C11.211 10.227 13 7.837 13 5A5 5 0 003 5c0 2.837 1.789 5.227 4.52 5.901l-.244.487a.25.25 0 10.448.224l.04-.08c.009.17.024.315.051.45.068.344.208.622.448 1.102l.013.028c.212.422.182.85.05 1.246-.135.402-.366.751-.534 1.003a.25.25 0 00.416.278l.004-.007c.166-.248.431-.646.588-1.115.16-.479.212-1.051-.076-1.629-.258-.515-.365-.732-.419-1.004a2 2 0 01-.037-.289l.008.017a.25.25 0 10.448-.224zM4.352 3.356a4 4 0 013.15-2.325C7.774.997 8 1.224 8 1.5s-.226.496-.498.542c-.95.162-1.749.78-2.173 1.617a.6.6 0 01-.52.341c-.346 0-.599-.329-.457-.644"/>',
    },
});
