/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'toggle-on': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 3a5 5 0 000 10h6a5 5 0 000-10zm6 9a4 4 0 110-8 4 4 0 010 8"/>',
    },
});
