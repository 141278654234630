/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'stopwatch-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.5 0a.5.5 0 000 1H7v1.07A7.001 7.001 0 008 16a7 7 0 005.29-11.584l.013-.012.354-.354.353.354a.5.5 0 10.707-.707l-1.414-1.415a.5.5 0 10-.707.707l.354.354-.354.354-.012.012A6.97 6.97 0 009 2.071V1h.5a.5.5 0 000-1zm2 5.6V9a.5.5 0 01-.5.5H4.5a.5.5 0 010-1h3V5.6a.5.5 0 111 0"/>',
    },
});
