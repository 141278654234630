/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'terminal-plus': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 3a1 1 0 00-1 1v8a1 1 0 001 1h5.5a.5.5 0 010 1H2a2 2 0 01-2-2V4a2 2 0 012-2h11a2 2 0 012 2v4a.5.5 0 01-1 0V4a1 1 0 00-1-1z"/><path pid="1" d="M3.146 5.146a.5.5 0 01.708 0L5.177 6.47a.75.75 0 010 1.06L3.854 8.854a.5.5 0 11-.708-.708L4.293 7 3.146 5.854a.5.5 0 010-.708M5.5 9a.5.5 0 01.5-.5h2a.5.5 0 010 1H6a.5.5 0 01-.5-.5M16 12.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0m-3.5-2a.5.5 0 00-.5.5v1h-1a.5.5 0 000 1h1v1a.5.5 0 001 0v-1h1a.5.5 0 000-1h-1v-1a.5.5 0 00-.5-.5"/>',
    },
});
