/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'signpost-split': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7 7V1.414a1 1 0 012 0V2h5a1 1 0 01.8.4l.975 1.3a.5.5 0 010 .6L14.8 5.6a1 1 0 01-.8.4H9v10H7v-5H2a1 1 0 01-.8-.4L.225 9.3a.5.5 0 010-.6L1.2 7.4A1 1 0 012 7zm1 3V8H2l-.75 1L2 10zm0-5h6l.75-1L14 3H8z"/>',
    },
});
