/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    slash: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11.354 4.646a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708l6-6a.5.5 0 01.708 0"/>',
    },
});
