/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'send-slash-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M15.964.686a.5.5 0 00-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 00-.082.887l.41.26.001.002 4.995 3.178 1.59 2.498C8 14 8 13 8 12.5a4.5 4.5 0 015.026-4.47zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 00-.154-.154l-.338-.215 7.494-7.494 1.178-.471z"/><path pid="1" d="M14.975 10.025a3.5 3.5 0 10-4.95 4.95 3.5 3.5 0 004.95-4.95m-4.243.707a2.5 2.5 0 013.147-.318l-3.465 3.465a2.5 2.5 0 01.318-3.147m.39 3.854l3.464-3.465a2.501 2.501 0 01-3.465 3.465z"/>',
    },
});
