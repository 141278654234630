/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'record-circle-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 8A8 8 0 110 8a8 8 0 0116 0m-8 3a3 3 0 100-6 3 3 0 000 6"/>',
    },
});
