/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'rewind-btn-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 4v8a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2H2a2 2 0 00-2 2m7.729 1.055A.5.5 0 018 5.5v1.886l3.21-2.293A.5.5 0 0112 5.5v5a.5.5 0 01-.79.407L8 8.614V10.5a.5.5 0 01-.79.407l-3.5-2.5a.5.5 0 010-.814l3.5-2.5a.5.5 0 01.519-.038"/>',
    },
});
