/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-image': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.002 5.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0"/><path pid="1" d="M12 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2M3 2a1 1 0 011-1h8a1 1 0 011 1v8l-2.083-2.083a.5.5 0 00-.76.063L8 11 5.835 9.7a.5.5 0 00-.611.076L3 12z"/>',
    },
});
