/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    calendar3: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M14 0H2a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z"/><path pid="1" d="M6.5 7a1 1 0 100-2 1 1 0 000 2m3 0a1 1 0 100-2 1 1 0 000 2m3 0a1 1 0 100-2 1 1 0 000 2m-9 3a1 1 0 100-2 1 1 0 000 2m3 0a1 1 0 100-2 1 1 0 000 2m3 0a1 1 0 100-2 1 1 0 000 2m3 0a1 1 0 100-2 1 1 0 000 2m-9 3a1 1 0 100-2 1 1 0 000 2m3 0a1 1 0 100-2 1 1 0 000 2m3 0a1 1 0 100-2 1 1 0 000 2"/>',
    },
});
