/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    '1-circle': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1 8a7 7 0 1014 0A7 7 0 001 8m15 0A8 8 0 110 8a8 8 0 0116 0M9.283 4.002V12H7.971V5.338h-.065L6.072 6.656V5.385l1.899-1.383z"/>',
    },
});
