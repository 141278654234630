/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'text-wrap': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M2 3.5a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5m0 4a.5.5 0 01.5-.5h9a2.5 2.5 0 010 5h-1.293l.647.646a.5.5 0 01-.708.708l-1.5-1.5a.5.5 0 010-.708l1.5-1.5a.5.5 0 01.708.708l-.647.646H11.5a1.5 1.5 0 000-3h-9a.5.5 0 01-.5-.5m0 4a.5.5 0 01.5-.5H7a.5.5 0 010 1H2.5a.5.5 0 01-.5-.5"/>',
    },
});
