/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    ethernet: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M14 13.5v-7a.5.5 0 00-.5-.5H12V4.5a.5.5 0 00-.5-.5h-1v-.5A.5.5 0 0010 3H6a.5.5 0 00-.5.5V4h-1a.5.5 0 00-.5.5V6H2.5a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h11a.5.5 0 00.5-.5M3.75 11h.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25m2 0h.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25m1.75.25a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25zM9.75 11h.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25m1.75.25a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25z"/><path pid="1" d="M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2zM1 2a1 1 0 011-1h12a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1z"/>',
    },
});
