/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'three-dots-vertical': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.5 13a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0m0-5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0m0-5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0"/>',
    },
});
