/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    escape: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.538 1.02a.5.5 0 10-.076.998 6 6 0 11-6.445 6.444.5.5 0 00-.997.076A7 7 0 108.538 1.02"/><path pid="1" d="M7.096 7.828a.5.5 0 00.707-.707L2.707 2.025h2.768a.5.5 0 100-1H1.5a.5.5 0 00-.5.5V5.5a.5.5 0 001 0V2.732z"/>',
    },
});
