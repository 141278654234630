/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    sliders2: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M10.5 1a.5.5 0 01.5.5v4a.5.5 0 01-1 0V4H1.5a.5.5 0 010-1H10V1.5a.5.5 0 01.5-.5M12 3.5a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5m-6.5 2A.5.5 0 016 6v1.5h8.5a.5.5 0 010 1H6V10a.5.5 0 01-1 0V6a.5.5 0 01.5-.5M1 8a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2A.5.5 0 011 8m9.5 2a.5.5 0 01.5.5v4a.5.5 0 01-1 0V13H1.5a.5.5 0 010-1H10v-1.5a.5.5 0 01.5-.5m1.5 2.5a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5"/>',
    },
});
