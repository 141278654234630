/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-laughing': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 15A7 7 0 118 1a7 7 0 010 14m0 1A8 8 0 108 0a8 8 0 000 16"/><path pid="1" d="M12.331 9.5a1 1 0 010 1A5 5 0 018 13a5 5 0 01-4.33-2.5A1 1 0 014.535 9h6.93a1 1 0 01.866.5M7 6.5c0 .828-.448 0-1 0s-1 .828-1 0S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 0-1 0s-1 .828-1 0S9.448 5 10 5s1 .672 1 1.5"/>',
    },
});
