/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'menu-down': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.646.146a.5.5 0 01.708 0L10.207 2H14a2 2 0 012 2v9a2 2 0 01-2 2H2a2 2 0 01-2-2V4a2 2 0 012-2h3.793zM1 7v3h14V7zm14-1V4a1 1 0 00-1-1h-3.793a1 1 0 01-.707-.293L8 1.207l-1.5 1.5A1 1 0 015.793 3H2a1 1 0 00-1 1v2zm0 5H1v2a1 1 0 001 1h12a1 1 0 001-1zM2 4.5a.5.5 0 01.5-.5h8a.5.5 0 010 1h-8a.5.5 0 01-.5-.5m0 4a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5m0 4a.5.5 0 01.5-.5h6a.5.5 0 010 1h-6a.5.5 0 01-.5-.5"/>',
    },
});
