/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    paragraph: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10.5 15a.5.5 0 01-.5-.5V2H9v12.5a.5.5 0 01-1 0V9H7a4 4 0 110-8h5.5a.5.5 0 010 1H11v12.5a.5.5 0 01-.5.5"/>',
    },
});
