/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'files-alt': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11 0H3a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2 2 2 0 002-2V4a2 2 0 00-2-2 2 2 0 00-2-2m2 3a1 1 0 011 1v8a1 1 0 01-1 1zM2 2a1 1 0 011-1h8a1 1 0 011 1v12a1 1 0 01-1 1H3a1 1 0 01-1-1z"/>',
    },
});
