/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    image: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.002 5.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0"/><path pid="1" d="M2.002 1a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V3a2 2 0 00-2-2zm12 1a1 1 0 011 1v6.5l-3.777-1.947a.5.5 0 00-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 00-.63.062L1.002 12V3a1 1 0 011-1z"/>',
    },
});
