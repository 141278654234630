/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'search-heart-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.5 13a6.47 6.47 0 003.845-1.258h-.001q.044.06.098.115l3.85 3.85a1 1 0 001.415-1.414l-3.85-3.85a1 1 0 00-.115-.1A6.47 6.47 0 0013 6.5 6.5 6.5 0 006.5 0a6.5 6.5 0 100 13m0-8.518c1.664-1.673 5.825 1.254 0 5.018-5.825-3.764-1.664-6.69 0-5.018"/>',
    },
});
