/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'hdd-network-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 2a2 2 0 00-2 2v1a2 2 0 002 2h5.5v3A1.5 1.5 0 006 11.5H.5a.5.5 0 000 1H6A1.5 1.5 0 007.5 14h1a1.5 1.5 0 001.5-1.5h5.5a.5.5 0 000-1H10A1.5 1.5 0 008.5 10V7H14a2 2 0 002-2V4a2 2 0 00-2-2zm.5 3a.5.5 0 110-1 .5.5 0 010 1m2 0a.5.5 0 110-1 .5.5 0 010 1"/>',
    },
});
