/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-medical': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.5 5.5a.5.5 0 00-1 0v.634l-.549-.317a.5.5 0 10-.5.866L6 7l-.549.317a.5.5 0 10.5.866l.549-.317V8.5a.5.5 0 101 0v-.634l.549.317a.5.5 0 10.5-.866L8 7l.549-.317a.5.5 0 10-.5-.866l-.549.317zm-2 4.5a.5.5 0 000 1h5a.5.5 0 000-1zm0 2a.5.5 0 000 1h5a.5.5 0 000-1z"/><path pid="1" d="M14 14V4.5L9.5 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2M9.5 3A1.5 1.5 0 0011 4.5h2V14a1 1 0 01-1 1H4a1 1 0 01-1-1V2a1 1 0 011-1h5.5z"/>',
    },
});
