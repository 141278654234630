/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    heartbreak: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.867 14.41c13.308-9.322 4.79-16.563.064-13.824L7 3l1.5 4-2 3L8 15a38 38 0 00.867-.59m-.303-1.01l-.971-3.237 1.74-2.608a1 1 0 00.103-.906l-1.3-3.468 1.45-1.813c1.861-.948 4.446.002 5.197 2.11.691 1.94-.055 5.521-6.219 9.922m-1.25 1.137a36 36 0 01-1.522-1.116C-5.077 4.97 1.842-1.472 6.454.293c.314.12.618.279.904.477L5.5 3 7 7l-1.5 3zm-2.3-3.06l-.442-1.106a1 1 0 01.034-.818l1.305-2.61L4.564 3.35a1 1 0 01.168-.991l1.032-1.24c-1.688-.449-3.7.398-4.456 2.128-.711 1.627-.413 4.55 3.706 8.229z"/>',
    },
});
