/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'pause-circle-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 8A8 8 0 110 8a8 8 0 0116 0M6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 102.5 0v-3.5C7.5 5.56 6.94 5 6.25 5m3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 102.5 0v-3.5C11 5.56 10.44 5 9.75 5"/>',
    },
});
