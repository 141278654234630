/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'calendar2-plus-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.5 0a.5.5 0 01.5.5V1h8V.5a.5.5 0 011 0V1h1a2 2 0 012 2v11a2 2 0 01-2 2H2a2 2 0 01-2-2V3a2 2 0 012-2h1V.5a.5.5 0 01.5-.5M2 3.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5H2.545c-.3 0-.545.224-.545.5m6.5 5a.5.5 0 00-1 0V10H6a.5.5 0 000 1h1.5v1.5a.5.5 0 001 0V11H10a.5.5 0 000-1H8.5z"/>',
    },
});
