/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-text-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2M5 4h6a.5.5 0 010 1H5a.5.5 0 010-1m-.5 2.5A.5.5 0 015 6h6a.5.5 0 010 1H5a.5.5 0 01-.5-.5M5 8h6a.5.5 0 010 1H5a.5.5 0 010-1m0 2h3a.5.5 0 010 1H5a.5.5 0 010-1"/>',
    },
});
