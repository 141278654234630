/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'window-stack': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4.5 6a.5.5 0 100-1 .5.5 0 000 1M6 6a.5.5 0 100-1 .5.5 0 000 1m2-.5a.5.5 0 11-1 0 .5.5 0 011 0"/><path pid="1" d="M12 1a2 2 0 012 2 2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2 2 2 0 01-2-2V3a2 2 0 012-2zM2 12V5a2 2 0 012-2h9a1 1 0 00-1-1H2a1 1 0 00-1 1v8a1 1 0 001 1m1-4v5a1 1 0 001 1h10a1 1 0 001-1V8zm12-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v2z"/>',
    },
});
