/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'dash-square-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2zm2.5 7.5h7a.5.5 0 010 1h-7a.5.5 0 010-1"/>',
    },
});
