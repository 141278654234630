/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    wallet2: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.136.326A1.5 1.5 0 0114 1.78V3h.5A1.5 1.5 0 0116 4.5v9a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 010 13.5v-9a1.5 1.5 0 011.432-1.499zM5.562 3H13V1.78a.5.5 0 00-.621-.484zM1.5 4a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5z"/>',
    },
});
