/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-wink': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 15A7 7 0 118 1a7 7 0 010 14m0 1A8 8 0 108 0a8 8 0 000 16"/><path pid="1" d="M4.285 9.567a.5.5 0 01.683.183A3.5 3.5 0 008 11.5a3.5 3.5 0 003.032-1.75.5.5 0 11.866.5A4.5 4.5 0 018 12.5a4.5 4.5 0 01-3.898-2.25.5.5 0 01.183-.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m1.757-.437a.5.5 0 01.68.194.93.93 0 00.813.493c.339 0 .645-.19.813-.493a.5.5 0 11.874.486A1.93 1.93 0 0110.25 7.75c-.73 0-1.356-.412-1.687-1.007a.5.5 0 01.194-.68"/>',
    },
});
