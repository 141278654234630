/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    feather2: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.5 1.063v9.556L6 8.819V3a2 2 0 011.5-1.937M8 0a3 3 0 00-3 3v6a.5.5 0 00.116.32L7.5 12.181V15.5a.5.5 0 001 0v-3.319l2.384-2.86A.5.5 0 0011 9V3a3 3 0 00-3-3m.5 1.063A2 2 0 0110 3v.293l-1.5 1.5zM10 4.707V8.82l-1.5 1.8V6.207z"/>',
    },
});
