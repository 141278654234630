/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'reply-all': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.098 5.013a.144.144 0 01.202.134V6.3a.5.5 0 00.5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.7 8.7 0 00-1.921-.306 7 7 0 00-.798.008h-.013l-.005.001h-.001L8.8 9.9l-.05-.498a.5.5 0 00-.45.498v1.153c0 .108-.11.176-.202.134L4.114 8.254l-.042-.028a.147.147 0 010-.252l.042-.028zM9.3 10.386q.102 0 .223.006c.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 00.933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 00-1.767-.96l-3.994 2.94a1.147 1.147 0 000 1.946l3.994 2.94a1.144 1.144 0 001.767-.96z"/><path pid="1" d="M5.232 4.293a.5.5 0 00-.7-.106L.54 7.127a1.147 1.147 0 000 1.946l3.994 2.94a.5.5 0 10.593-.805L1.114 8.254l-.042-.028a.147.147 0 010-.252l.042-.028 4.012-2.954a.5.5 0 00.106-.699"/>',
    },
});
