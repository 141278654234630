/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'bus-front-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 7a1 1 0 01-1 1v3.5c0 .818-.393 1.544-1 2v2a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V14H5v1.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a2.5 2.5 0 01-1-2V8a1 1 0 01-1-1V5a1 1 0 011-1V2.64C1 1.452 1.845.408 3.064.268A44 44 0 018 0c2.1 0 3.792.136 4.936.268C14.155.408 15 1.452 15 2.64V4a1 1 0 011 1zM3.552 3.22A43 43 0 018 3c1.837 0 3.353.107 4.448.22a.5.5 0 00.104-.994A44 44 0 008 2c-1.876 0-3.426.109-4.552.226a.5.5 0 10.104.994M8 4c-1.876 0-3.426.109-4.552.226A.5.5 0 003 4.723v3.554a.5.5 0 00.448.497C4.574 8.891 6.124 9 8 9s3.426-.109 4.552-.226A.5.5 0 0013 8.277V4.723a.5.5 0 00-.448-.497A44 44 0 008 4m-3 7a1 1 0 10-2 0 1 1 0 002 0m8 0a1 1 0 10-2 0 1 1 0 002 0m-7 0a1 1 0 001 1h2a1 1 0 100-2H7a1 1 0 00-1 1"/>',
    },
});
