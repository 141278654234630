/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'dice-2-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 3a3 3 0 013-3h10a3 3 0 013 3v10a3 3 0 01-3 3H3a3 3 0 01-3-3zm5.5 1a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0m6.5 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3"/>',
    },
});
