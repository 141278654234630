/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'device-hdd': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12 2.5a.5.5 0 11-1 0 .5.5 0 011 0m0 11a.5.5 0 11-1 0 .5.5 0 011 0m-7.5.5a.5.5 0 100-1 .5.5 0 000 1M5 2.5a.5.5 0 11-1 0 .5.5 0 011 0M8 8a1 1 0 100-2 1 1 0 000 2"/><path pid="1" d="M12 7a4 4 0 01-3.937 4c-.537.813-1.02 1.515-1.181 1.677a1.102 1.102 0 01-1.56-1.559c.1-.098.396-.314.795-.588A4 4 0 018 3a4 4 0 014 4m-1 0a3 3 0 10-3.891 2.865c.667-.44 1.396-.91 1.955-1.268.224-.144.483.115.34.34l-.62.96A3 3 0 0011 7"/><path pid="2" d="M2 2a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2zm2-1a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V2a1 1 0 00-1-1z"/>',
    },
});
