/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'card-image': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.002 5.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0"/><path pid="1" d="M1.5 2A1.5 1.5 0 000 3.5v9A1.5 1.5 0 001.5 14h13a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0014.5 2zm13 1a.5.5 0 01.5.5v6l-3.775-1.947a.5.5 0 00-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 00-.63.062L1.002 12v.54L1 12.5v-9a.5.5 0 01.5-.5z"/>',
    },
});
