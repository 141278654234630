/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'tablet-landscape-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 14a2 2 0 01-2-2V4a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2zm11-7a1 1 0 100 2 1 1 0 000-2"/>',
    },
});
