/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'capsule-pill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11.02 5.364a3 3 0 00-4.242-4.243L1.121 6.778a3 3 0 104.243 4.243l5.657-5.657zm-6.413-.657l2.878-2.879a2 2 0 112.829 2.829L7.435 7.536zM12 8a4 4 0 110 8 4 4 0 010-8m-.5 1.042a3 3 0 000 5.917zm1 5.917a3 3 0 000-5.917z"/>',
    },
});
