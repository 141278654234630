/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'brightness-alt-low-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5 5.5a.5.5 0 11-1 0 .5.5 0 011 0m5 6a.5.5 0 110-1 .5.5 0 010 1M2 11a.5.5 0 101 0 .5.5 0 00-1 0m10.243-3.536a.5.5 0 11-.707-.707.5.5 0 01.707.707m-8.486-.707a.5.5 0 10.707.707.5.5 0 00-.707-.707M8 7a4 4 0 00-4 4 .5.5 0 00.5.5h7a.5.5 0 00.5-.5 4 4 0 00-4-4"/>',
    },
});
