/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'skip-end': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.5 4a.5.5 0 00-1 0v3.248L5.233 3.612C4.713 3.31 4 3.655 4 4.308v7.384c0 .653.713.998 1.233.696L11.5 8.752V12a.5.5 0 001 0zM5 4.633L10.804 8 5 11.367z"/>',
    },
});
