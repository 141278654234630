/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'menu-up': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.646 15.854a.5.5 0 00.708 0L10.207 14H14a2 2 0 002-2V3a2 2 0 00-2-2H2a2 2 0 00-2 2v9a2 2 0 002 2h3.793zM1 9V6h14v3zm14 1v2a1 1 0 01-1 1h-3.793a1 1 0 00-.707.293l-1.5 1.5-1.5-1.5A1 1 0 005.793 13H2a1 1 0 01-1-1v-2zm0-5H1V3a1 1 0 011-1h12a1 1 0 011 1zM2 11.5a.5.5 0 00.5.5h8a.5.5 0 000-1h-8a.5.5 0 00-.5.5m0-4a.5.5 0 00.5.5h11a.5.5 0 000-1h-11a.5.5 0 00-.5.5m0-4a.5.5 0 00.5.5h6a.5.5 0 000-1h-6a.5.5 0 00-.5.5"/>',
    },
});
