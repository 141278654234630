/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'envelope-paper-heart-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M3 7.5l3.5 2L8 8.75l1.5.75 3.5-2v-6A1.5 1.5 0 0011.5 0h-7A1.5 1.5 0 003 1.5zM2 3.133l-.941.502A2 2 0 000 5.4v.313l2 1.173zm12 3.753l2-1.173V5.4a2 2 0 00-1.059-1.765L14 3.133zm-3.693 3.324L16 6.873v6.5zm5.634 4.274L8 10.072.059 14.484A2 2 0 002 16h12a2 2 0 001.941-1.516M5.693 10.21L0 13.372v-6.5zM8 1.982C9.664.309 13.825 3.236 8 7 2.175 3.236 6.336.31 8 1.982"/>',
    },
});
