/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-ruled': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M14 14V4.5L9.5 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2M9.5 3A1.5 1.5 0 0011 4.5h2V9H3V2a1 1 0 011-1h5.5zM3 12v-2h2v2zm0 1h2v2H4a1 1 0 01-1-1zm3 2v-2h7v1a1 1 0 01-1 1zm7-3H6v-2h7z"/>',
    },
});
