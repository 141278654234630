/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'stickies-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 1.5V13a1 1 0 001 1V1.5a.5.5 0 01.5-.5H14a1 1 0 00-1-1H1.5A1.5 1.5 0 000 1.5"/><path pid="1" d="M3.5 2A1.5 1.5 0 002 3.5v11A1.5 1.5 0 003.5 16h6.086a1.5 1.5 0 001.06-.44l4.915-4.914A1.5 1.5 0 0016 9.586V3.5A1.5 1.5 0 0014.5 2zm6 8.5a1 1 0 011-1h4.396a.25.25 0 01.177.427l-5.146 5.146a.25.25 0 01-.427-.177z"/>',
    },
});
