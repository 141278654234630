/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'layout-text-window-reverse': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M13 6.5a.5.5 0 00-.5-.5h-5a.5.5 0 000 1h5a.5.5 0 00.5-.5m0 3a.5.5 0 00-.5-.5h-5a.5.5 0 000 1h5a.5.5 0 00.5-.5m-.5 2.5a.5.5 0 010 1h-5a.5.5 0 010-1z"/><path pid="1" d="M14 0a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2zM2 1a1 1 0 00-1 1v1h14V2a1 1 0 00-1-1zM1 4v10a1 1 0 001 1h2V4zm4 0v11h9a1 1 0 001-1V4z"/>',
    },
});
