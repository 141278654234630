/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'align-middle': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6 13a1 1 0 001 1h2a1 1 0 001-1V3a1 1 0 00-1-1H7a1 1 0 00-1 1zM1 8a.5.5 0 00.5.5H6v-1H1.5A.5.5 0 001 8m14 0a.5.5 0 01-.5.5H10v-1h4.5a.5.5 0 01.5.5"/>',
    },
});
