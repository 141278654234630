/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'thunderbolt-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1 3a1 1 0 00-1 1v7.293A1 1 0 00.293 12L2 13.707a1 1 0 00.707.293h10.586a1 1 0 00.707-.293L15.707 12a1 1 0 00.293-.707V4a1 1 0 00-1-1zm2.5 3h9a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5"/>',
    },
});
