/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    palette2: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 .5A.5.5 0 01.5 0h5a.5.5 0 01.5.5v5.277l4.147-4.131a.5.5 0 01.707 0l3.535 3.536a.5.5 0 010 .708L10.261 10H15.5a.5.5 0 01.5.5v5a.5.5 0 01-.5.5H3a3 3 0 01-2.121-.879A3 3 0 010 13.044m6-.21l7.328-7.3-2.829-2.828L6 7.188zM4.5 13a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0M15 15v-4H9.258l-4.015 4zM0 .5v12.495zm0 12.495V13z"/>',
    },
});
