/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-medical-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2M8.5 4.5v.634l.549-.317a.5.5 0 11.5.866L9 6l.549.317a.5.5 0 11-.5.866L8.5 6.866V7.5a.5.5 0 01-1 0v-.634l-.549.317a.5.5 0 11-.5-.866L7 6l-.549-.317a.5.5 0 01.5-.866l.549.317V4.5a.5.5 0 111 0M5.5 9h5a.5.5 0 010 1h-5a.5.5 0 010-1m0 2h5a.5.5 0 010 1h-5a.5.5 0 010-1"/>',
    },
});
