/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    android: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.76 3.061a.5.5 0 01.679.2l1.283 2.352A8.9 8.9 0 018 5a8.9 8.9 0 013.278.613l1.283-2.352a.5.5 0 11.878.478l-1.252 2.295C14.475 7.266 16 9.477 16 12H0c0-2.523 1.525-4.734 3.813-5.966L2.56 3.74a.5.5 0 01.2-.678zM5 10a1 1 0 100-2 1 1 0 000 2m6 0a1 1 0 100-2 1 1 0 000 2"/>',
    },
});
