/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-excel': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.884 6.68a.5.5 0 10-.768.64L7.349 10l-2.233 2.68a.5.5 0 00.768.64L8 10.781l2.116 2.54a.5.5 0 00.768-.641L8.651 10l2.233-2.68a.5.5 0 00-.768-.64L8 9.219l-2.116-2.54z"/><path pid="1" d="M14 14V4.5L9.5 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2M9.5 3A1.5 1.5 0 0011 4.5h2V14a1 1 0 01-1 1H4a1 1 0 01-1-1V2a1 1 0 011-1h5.5z"/>',
    },
});
