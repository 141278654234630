/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-doc': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2v-1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zm-7.839 9.166v.522q0 .384-.117.641a.86.86 0 01-.322.387.9.9 0 01-.469.126.9.9 0 01-.471-.126.87.87 0 01-.32-.386 1.55 1.55 0 01-.117-.642v-.522q0-.386.117-.641a.87.87 0 01.32-.387.87.87 0 01.471-.129q.264 0 .469.13a.86.86 0 01.322.386q.117.255.117.641m.803.519v-.513q0-.565-.205-.972a1.46 1.46 0 00-.589-.63q-.381-.22-.917-.22-.533 0-.92.22a1.44 1.44 0 00-.589.627q-.204.406-.205.975v.513q0 .563.205.973.205.406.59.627.386.216.92.216.535 0 .916-.216.383-.22.59-.627.204-.41.204-.973M0 11.926v4h1.459q.603 0 .999-.238a1.45 1.45 0 00.595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 00-.59-.68q-.395-.234-1.004-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 01.354.454q.118.302.118.753a2.3 2.3 0 01-.068.592 1.1 1.1 0 01-.196.422.8.8 0 01-.334.252 1.3 1.3 0 01-.483.082H.79V12.57zm7.422.483a1.7 1.7 0 00-.103.633v.495q0 .369.103.627a.83.83 0 00.298.393.85.85 0 00.478.131.9.9 0 00.401-.088.7.7 0 00.273-.248.8.8 0 00.117-.364h.765v.076a1.27 1.27 0 01-.226.674q-.205.29-.55.454a1.8 1.8 0 01-.786.164q-.54 0-.914-.216a1.4 1.4 0 01-.571-.627q-.194-.408-.194-.976v-.498q0-.568.197-.978.195-.411.571-.633.378-.223.911-.223.328 0 .607.097.28.093.489.272a1.33 1.33 0 01.466.964v.073H9.78a.85.85 0 00-.12-.38.7.7 0 00-.273-.261.8.8 0 00-.398-.097.8.8 0 00-.475.138.87.87 0 00-.301.398"/>',
    },
});
