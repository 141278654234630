/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'calendar-x': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.146 7.146a.5.5 0 01.708 0L8 8.293l1.146-1.147a.5.5 0 11.708.708L8.707 9l1.147 1.146a.5.5 0 01-.708.708L8 9.707l-1.146 1.147a.5.5 0 01-.708-.708L7.293 9 6.146 7.854a.5.5 0 010-.708"/><path pid="1" d="M3.5 0a.5.5 0 01.5.5V1h8V.5a.5.5 0 011 0V1h1a2 2 0 012 2v11a2 2 0 01-2 2H2a2 2 0 01-2-2V3a2 2 0 012-2h1V.5a.5.5 0 01.5-.5M1 4v10a1 1 0 001 1h12a1 1 0 001-1V4z"/>',
    },
});
