/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-vcard': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 8a2 2 0 100-4 2 2 0 000 4m4-2.5a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5M9 8a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4A.5.5 0 019 8m1 2.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5"/><path pid="1" d="M2 2a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2zM1 4a1 1 0 011-1h12a1 1 0 011 1v8a1 1 0 01-1 1H8.96q.04-.245.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 011 12z"/>',
    },
});
