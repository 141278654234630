/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sunrise-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.646 1.146a.5.5 0 01.708 0l1.5 1.5a.5.5 0 01-.708.708L8.5 2.707V4.5a.5.5 0 01-1 0V2.707l-.646.647a.5.5 0 11-.708-.708zM2.343 4.343a.5.5 0 01.707 0l1.414 1.414a.5.5 0 01-.707.707L2.343 5.05a.5.5 0 010-.707m11.314 0a.5.5 0 010 .707l-1.414 1.414a.5.5 0 11-.707-.707l1.414-1.414a.5.5 0 01.707 0M11.709 11.5a4 4 0 10-7.418 0H.5a.5.5 0 000 1h15a.5.5 0 000-1h-3.79zM0 10a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2A.5.5 0 010 10m13 0a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5"/>',
    },
});
