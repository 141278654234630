/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'thermometer-half': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.5 12.5a1.5 1.5 0 11-2-1.415V6.5a.5.5 0 011 0v4.585a1.5 1.5 0 011 1.415"/><path pid="1" d="M5.5 2.5a2.5 2.5 0 015 0v7.55a3.5 3.5 0 11-5 0zM8 1a1.5 1.5 0 00-1.5 1.5v7.987l-.167.15a2.5 2.5 0 103.333 0l-.166-.15V2.5A1.5 1.5 0 008 1"/>',
    },
});
