/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'dpad-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.5 0A1.5 1.5 0 005 1.5v3a.5.5 0 01-.5.5h-3A1.5 1.5 0 000 6.5v3A1.5 1.5 0 001.5 11h3a.5.5 0 01.5.5v3A1.5 1.5 0 006.5 16h3a1.5 1.5 0 001.5-1.5v-3a.5.5 0 01.5-.5h3A1.5 1.5 0 0016 9.5v-3A1.5 1.5 0 0014.5 5h-3a.5.5 0 01-.5-.5v-3A1.5 1.5 0 009.5 0zm1.288 2.34a.25.25 0 01.424 0l.799 1.278A.25.25 0 018.799 4H7.201a.25.25 0 01-.212-.382zm0 11.32l-.799-1.277A.25.25 0 017.201 12H8.8a.25.25 0 01.212.383l-.799 1.278a.25.25 0 01-.424 0zm-4.17-4.65l-1.279-.798a.25.25 0 010-.424l1.279-.799A.25.25 0 014 7.201V8.8a.25.25 0 01-.382.212zm10.043-.798l-1.278.799A.25.25 0 0112 8.799V7.2a.25.25 0 01.383-.212l1.278.799a.25.25 0 010 .424z"/>',
    },
});
