/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    reddit: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.167 8a.83.83 0 00-.83.83c0 .459.372.84.83.831a.831.831 0 000-1.661m1.843 3.647c.315 0 1.403-.038 1.976-.611a.23.23 0 000-.306.213.213 0 00-.306 0c-.353.363-1.126.487-1.67.487-.545 0-1.308-.124-1.671-.487a.213.213 0 00-.306 0 .213.213 0 000 .306c.564.563 1.652.61 1.977.61zm.992-2.807c0 .458.373.83.831.83s.83-.381.83-.83a.831.831 0 00-1.66 0z"/><path pid="1" d="M16 8A8 8 0 110 8a8 8 0 0116 0m-3.828-1.165c-.315 0-.602.124-.812.325-.801-.573-1.9-.945-3.121-.993l.534-2.501 1.738.372a.83.83 0 10.83-.869.83.83 0 00-.744.468l-1.938-.41a.2.2 0 00-.153.028.2.2 0 00-.086.134l-.592 2.788c-1.24.038-2.358.41-3.17.992-.21-.2-.496-.324-.81-.324a1.163 1.163 0 00-.478 2.224q-.03.17-.029.353c0 1.795 2.091 3.256 4.669 3.256s4.668-1.451 4.668-3.256c0-.114-.01-.238-.029-.353.401-.181.688-.592.688-1.069 0-.65-.525-1.165-1.165-1.165"/>',
    },
});
