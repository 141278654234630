/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'blockquote-left': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.5 3a.5.5 0 000 1h11a.5.5 0 000-1zm5 3a.5.5 0 000 1h6a.5.5 0 000-1zm0 3a.5.5 0 000 1h6a.5.5 0 000-1zm-5 3a.5.5 0 000 1h11a.5.5 0 000-1zm.79-5.373q.168-.117.444-.275L3.524 6q-.183.111-.452.287-.27.176-.51.428a2.4 2.4 0 00-.398.562Q2 7.587 2 7.969q0 .54.217.873.217.328.72.328.322 0 .504-.211a.7.7 0 00.188-.463q0-.345-.211-.521-.205-.182-.568-.182h-.282q.036-.305.123-.498a1.4 1.4 0 01.252-.37 2 2 0 01.346-.298zm2.167 0q.17-.117.445-.275L5.692 6q-.183.111-.452.287-.27.176-.51.428a2.4 2.4 0 00-.398.562q-.165.31-.164.692 0 .54.217.873.217.328.72.328.322 0 .504-.211a.7.7 0 00.188-.463q0-.345-.211-.521-.205-.182-.568-.182h-.282a1.8 1.8 0 01.118-.492q.087-.194.257-.375a2 2 0 01.346-.3z"/>',
    },
});
