/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'hand-index-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5 4.466V1.75a1.75 1.75 0 10-3.5 0v5.34l-1.2.24a1.5 1.5 0 00-1.196 1.636l.345 3.106a2.5 2.5 0 00.405 1.11l1.433 2.15A1.5 1.5 0 006.035 16h6.385a1.5 1.5 0 001.302-.756l1.395-2.441a3.5 3.5 0 00.444-1.389l.271-2.715a2 2 0 00-1.99-2.199h-.581a5 5 0 00-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 00-.56-.642 2.6 2.6 0 00-.738-.288c-.31-.062-.739-.058-1.05-.046z"/>',
    },
});
