/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-down': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.5 9a3.5 3.5 0 110 7 3.5 3.5 0 010-7m.354 5.854l1.5-1.5a.5.5 0 00-.708-.708l-.646.647V10.5a.5.5 0 00-1 0v2.793l-.646-.647a.5.5 0 00-.708.708l1.5 1.5a.5.5 0 00.708 0M11 5a3 3 0 11-6 0 3 3 0 016 0M8 7a2 2 0 100-4 2 2 0 000 4"/><path pid="1" d="M8.256 14a4.5 4.5 0 01-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/>',
    },
});
