/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'envelope-arrow-down-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M.05 3.555A2 2 0 012 2h12a2 2 0 011.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zm.192 8.159l6.57-4.027L8 9.586l1.239-.757.367.225A4.49 4.49 0 008 12.5c0 .526.09 1.03.256 1.5H2a2 2 0 01-1.808-1.144M16 4.697v4.974A4.5 4.5 0 0012.5 8a4.5 4.5 0 00-1.965.45l-.338-.207z"/><path pid="1" d="M12.5 16a3.5 3.5 0 100-7 3.5 3.5 0 000 7m.354-1.646a.5.5 0 01-.722-.016l-1.149-1.25a.5.5 0 11.737-.676l.28.305V11a.5.5 0 011 0v1.793l.396-.397a.5.5 0 01.708.708z"/>',
    },
});
