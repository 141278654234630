/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'arrows-vertical': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.354 14.854a.5.5 0 01-.708 0l-2-2a.5.5 0 01.708-.708L7.5 13.293V2.707L6.354 3.854a.5.5 0 11-.708-.708l2-2a.5.5 0 01.708 0l2 2a.5.5 0 01-.708.708L8.5 2.707v10.586l1.146-1.147a.5.5 0 01.708.708z"/>',
    },
});
