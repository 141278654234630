/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'fast-forward-circle-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 16A8 8 0 108 0a8 8 0 000 16M4.79 5.093L8 7.386V5.5a.5.5 0 01.79-.407l3.5 2.5a.5.5 0 010 .814l-3.5 2.5A.5.5 0 018 10.5V8.614l-3.21 2.293A.5.5 0 014 10.5v-5a.5.5 0 01.79-.407"/>',
    },
});
