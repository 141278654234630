/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'house-lock-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.707 1.5a1 1 0 00-1.414 0L.646 8.146a.5.5 0 00.708.708L8 2.207l6.646 6.647a.5.5 0 00.708-.708L13 5.793V2.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v1.293z"/><path pid="1" d="M8 3.293l4.72 4.72a3 3 0 00-2.709 3.248A2 2 0 009 13v2H3.5A1.5 1.5 0 012 13.5V9.293z"/><path pid="2" d="M13 9a2 2 0 00-2 2v1a1 1 0 00-1 1v2a1 1 0 001 1h4a1 1 0 001-1v-2a1 1 0 00-1-1v-1a2 2 0 00-2-2m0 1a1 1 0 011 1v1h-2v-1a1 1 0 011-1"/>',
    },
});
