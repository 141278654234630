/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'exclamation-circle-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 8A8 8 0 110 8a8 8 0 0116 0M8 4a.905.905 0 00-.9.995l.35 3.507a.552.552 0 001.1 0l.35-3.507A.905.905 0 008 4m.002 6a1 1 0 100 2 1 1 0 000-2"/>',
    },
});
