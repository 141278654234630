/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'pin-map-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M3.1 11.2a.5.5 0 01.4-.2H6a.5.5 0 010 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 010-1h2.5a.5.5 0 01.4.2l3 4a.5.5 0 01-.4.8H.5a.5.5 0 01-.4-.8z"/><path pid="1" fill-rule="evenodd" d="M4 4a4 4 0 114.5 3.969V13.5a.5.5 0 01-1 0V7.97A4 4 0 014 3.999z"/>',
    },
});
