/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cloudy-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M13.405 7.027a5.001 5.001 0 00-9.499-1.004A3.5 3.5 0 103.5 13H13a3 3 0 00.405-5.973"/>',
    },
});
