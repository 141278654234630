/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    pentagon: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.685 1.545a.5.5 0 01.63 0l6.263 5.088a.5.5 0 01.161.539l-2.362 7.479a.5.5 0 01-.476.349H4.099a.5.5 0 01-.476-.35L1.26 7.173a.5.5 0 01.161-.54l6.263-5.087zm8.213 5.28a.5.5 0 00-.162-.54L8.316.257a.5.5 0 00-.631 0L.264 6.286a.5.5 0 00-.162.538l2.788 8.827a.5.5 0 00.476.349h9.268a.5.5 0 00.476-.35l2.788-8.826z"/>',
    },
});
