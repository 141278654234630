/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'door-open-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1.5 15a.5.5 0 000 1h13a.5.5 0 000-1H13V2.5A1.5 1.5 0 0011.5 1H11V.5a.5.5 0 00-.57-.495l-7 1A.5.5 0 003 1.5V15zM11 2h.5a.5.5 0 01.5.5V15h-1zm-2.5 8c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1"/>',
    },
});
