/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'ticket-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1.5 3A1.5 1.5 0 000 4.5V6a.5.5 0 00.5.5 1.5 1.5 0 110 3 .5.5 0 00-.5.5v1.5A1.5 1.5 0 001.5 13h13a1.5 1.5 0 001.5-1.5V10a.5.5 0 00-.5-.5 1.5 1.5 0 010-3A.5.5 0 0016 6V4.5A1.5 1.5 0 0014.5 3z"/>',
    },
});
