/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'music-note-beamed': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6 13c0 1.105-1.12 2-2.5 2S1 14.105 1 13s1.12-2 2.5-2 2.5.896 2.5 2m9-2c0 1.105-1.12 2-2.5 2s-2.5-.895-2.5-2 1.12-2 2.5-2 2.5.895 2.5 2"/><path pid="1" fill-rule="evenodd" d="M14 11V2h1v9zM6 3v10H5V3z"/><path pid="2" d="M5 2.905a1 1 0 01.9-.995l8-.8a1 1 0 011.1.995V3L5 4z"/>',
    },
});
