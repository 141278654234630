/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    vinyl: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 15A7 7 0 118 1a7 7 0 010 14m0 1A8 8 0 108 0a8 8 0 000 16"/><path pid="1" d="M8 6a2 2 0 100 4 2 2 0 000-4M4 8a4 4 0 118 0 4 4 0 01-8 0"/><path pid="2" d="M9 8a1 1 0 11-2 0 1 1 0 012 0"/>',
    },
});
