/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-easel': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5 5a.5.5 0 10-1 0h-2A1.5 1.5 0 004 6.5v2A1.5 1.5 0 005.5 10h.473l-.447 1.342a.5.5 0 10.948.316L7.027 10H7.5v1a.5.5 0 001 0v-1h.473l.553 1.658a.5.5 0 10.948-.316L10.027 10h.473A1.5 1.5 0 0012 8.5v-2A1.5 1.5 0 0010.5 5zM5 6.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5z"/><path pid="1" d="M2 2a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2zm10-1H4a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V2a1 1 0 00-1-1"/>',
    },
});
