/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-php': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2h-1v-1h1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 00.161-.677q0-.375-.158-.677a1.2 1.2 0 00-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 01-.085.38.57.57 0 01-.238.241.8.8 0 01-.375.082H.788V12.48h.66q.327 0 .512.181.185.182.185.522m4.48 2.666V11.85h-.79v1.626H4.153V11.85h-.79v3.999h.79v-1.714h1.682v1.714zm.703-3.999h1.6q.433 0 .732.179.3.175.46.477.158.302.158.677t-.161.677q-.159.299-.463.474a1.45 1.45 0 01-.733.173H8.12v1.342h-.791zm2.06 1.714a.8.8 0 00.084-.381q0-.34-.184-.521-.184-.182-.513-.182h-.66v1.406h.66a.8.8 0 00.375-.082.57.57 0 00.237-.24z"/>',
    },
});
