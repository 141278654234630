/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    modem: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.5 1.5A1.5 1.5 0 017 0h2a1.5 1.5 0 011.5 1.5v11a1.5 1.5 0 01-1.404 1.497c.35.305.872.678 1.628 1.056A.5.5 0 0110.5 16h-5a.5.5 0 01-.224-.947c.756-.378 1.277-.75 1.628-1.056A1.5 1.5 0 015.5 12.5zM7 1a.5.5 0 00-.5.5v11a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-11A.5.5 0 009 1z"/><path pid="1" d="M8.5 2.5a.5.5 0 11-1 0 .5.5 0 011 0m0 2a.5.5 0 11-1 0 .5.5 0 011 0m0 2a.5.5 0 11-1 0 .5.5 0 011 0m0 2a.5.5 0 11-1 0 .5.5 0 011 0"/>',
    },
});
