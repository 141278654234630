/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'skip-end-circle-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 8A8 8 0 110 8a8 8 0 0116 0M6.79 5.093A.5.5 0 006 5.5v5a.5.5 0 00.79.407L9.5 8.972V10.5a.5.5 0 001 0v-5a.5.5 0 00-1 0v1.528z"/>',
    },
});
