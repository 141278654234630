/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'compass-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M15.5 8.516a7.5 7.5 0 11-9.462-7.24A1 1 0 017 0h2a1 1 0 01.962 1.276 7.5 7.5 0 015.538 7.24m-3.61-3.905L6.94 7.439 4.11 12.39l4.95-2.828 2.828-4.95z"/>',
    },
});
