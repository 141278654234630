/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'list-ul': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M5 11.5a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5m0-4a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5m0-4a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5m-3 1a1 1 0 100-2 1 1 0 000 2m0 4a1 1 0 100-2 1 1 0 000 2m0 4a1 1 0 100-2 1 1 0 000 2"/>',
    },
});
