/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'basket3-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.757 1.071a.5.5 0 01.172.686L3.383 6h9.234L10.07 1.757a.5.5 0 11.858-.514L13.783 6H15.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5v-1A.5.5 0 01.5 6h1.717L5.07 1.243a.5.5 0 01.686-.172zM2.468 15.426L.943 9h14.114l-1.525 6.426a.75.75 0 01-.729.574H3.197a.75.75 0 01-.73-.574z"/>',
    },
});
