/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'envelope-open-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.941.435a2 2 0 00-1.882 0l-6 3.2A2 2 0 000 5.4v.314l6.709 3.932L8 8.928l1.291.718L16 5.714V5.4a2 2 0 00-1.059-1.765zM16 6.873l-5.693 3.337L16 13.372v-6.5zm-.059 7.611L8 10.072.059 14.484A2 2 0 002 16h12a2 2 0 001.941-1.516M0 13.373l5.693-3.163L0 6.873z"/>',
    },
});
