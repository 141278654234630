/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'camera-video-off': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M10.961 12.365a2 2 0 00.522-1.103l3.11 1.382A1 1 0 0016 11.731V4.269a1 1 0 00-1.406-.913l-3.111 1.382A2 2 0 009.5 3H4.272l.714 1H9.5a1 1 0 011 1v6a1 1 0 01-.144.518zM1.428 4.18A1 1 0 001 5v6a1 1 0 001 1h5.014l.714 1H2a2 2 0 01-2-2V5c0-.675.334-1.272.847-1.634zM15 11.73l-3.5-1.555v-4.35L15 4.269zm-4.407 3.56l-10-14 .814-.58 10 14z"/>',
    },
});
