/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'fast-forward-btn-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 4v8a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2H2a2 2 0 00-2 2m4.271 1.055a.5.5 0 01.52.038L8 7.386V5.5a.5.5 0 01.79-.407l3.5 2.5a.5.5 0 010 .814l-3.5 2.5A.5.5 0 018 10.5V8.614l-3.21 2.293A.5.5 0 014 10.5v-5a.5.5 0 01.271-.445"/>',
    },
});
