/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-smile-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 16A8 8 0 108 0a8 8 0 000 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5M4.285 9.567a.5.5 0 01.683.183A3.5 3.5 0 008 11.5a3.5 3.5 0 003.032-1.75.5.5 0 11.866.5A4.5 4.5 0 018 12.5a4.5 4.5 0 01-3.898-2.25.5.5 0 01.183-.683M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8"/>',
    },
});
