/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'clipboard2-pulse-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10 .5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5.5.5 0 01-.5.5.5.5 0 00-.5.5V2a.5.5 0 00.5.5h5A.5.5 0 0011 2v-.5a.5.5 0 00-.5-.5.5.5 0 01-.5-.5"/><path pid="1" d="M4.085 1H3.5A1.5 1.5 0 002 2.5v12A1.5 1.5 0 003.5 16h9a1.5 1.5 0 001.5-1.5v-12A1.5 1.5 0 0012.5 1h-.585q.084.236.085.5V2a1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 014 2v-.5q.001-.264.085-.5M9.98 5.356L11.372 10h.128a.5.5 0 010 1H11a.5.5 0 01-.479-.356l-.94-3.135-1.092 5.096a.5.5 0 01-.968.039L6.383 8.85l-.936 1.873A.5.5 0 015 11h-.5a.5.5 0 010-1h.191l1.362-2.724a.5.5 0 01.926.08l.94 3.135 1.092-5.096a.5.5 0 01.968-.039z"/>',
    },
});
