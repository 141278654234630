/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'house-gear': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.293 1.5a1 1 0 011.414 0L11 3.793V2.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v3.293l2.354 2.353a.5.5 0 01-.708.708L8 2.207l-5 5V13.5a.5.5 0 00.5.5h4a.5.5 0 010 1h-4A1.5 1.5 0 012 13.5V8.207l-.646.647a.5.5 0 11-.708-.708z"/><path pid="1" d="M11.886 9.46c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 00.921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 00.382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 00-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 00-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 00-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 00-.382-.92l-.148-.044c-.613-.181-.613-1.049 0-1.23l.148-.043a.64.64 0 00.382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 00.92-.382zM14 12.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0"/>',
    },
});
