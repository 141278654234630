/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-break': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 10.5a.5.5 0 01.5-.5h15a.5.5 0 010 1H.5a.5.5 0 01-.5-.5M12 0H4a2 2 0 00-2 2v7h1V2a1 1 0 011-1h8a1 1 0 011 1v7h1V2a2 2 0 00-2-2m2 12h-1v2a1 1 0 01-1 1H4a1 1 0 01-1-1v-2H2v2a2 2 0 002 2h8a2 2 0 002-2z"/>',
    },
});
