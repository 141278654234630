/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-rb': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2H8v-1h4a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM0 11.85h1.597q.446 0 .758.158.315.155.478.44.167.284.167.668a1.18 1.18 0 01-.727 1.122l.803 1.611h-.885l-.7-1.491H.782v1.491H0zm.782.621v1.292h.689q.327 0 .518-.158.195-.159.194-.475 0-.32-.194-.489a.74.74 0 00-.507-.17zm4.426 3.378H3.544V11.85h1.67q.536 0 .858.26.322.262.322.724a.94.94 0 01-.09.422.8.8 0 01-.244.293 1 1 0 01-.351.161v.035q.243.024.445.141a.85.85 0 01.322.325 1 1 0 01.123.51q0 .357-.178.61-.18.25-.492.386a1.9 1.9 0 01-.721.132m-.179-3.404h-.7v1.07h.521q.267 0 .434-.065a.5.5 0 00.249-.185.5.5 0 00.082-.296.49.49 0 00-.155-.384q-.153-.14-.43-.14zm.05 1.62h-.75v1.19h.589q.466 0 .67-.147a.5.5 0 00.206-.434.6.6 0 00-.082-.325.5.5 0 00-.24-.21.95.95 0 00-.393-.074"/>',
    },
});
