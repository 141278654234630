/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'symmetry-horizontal': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M13.5 7a.5.5 0 00.24-.939l-11-6A.5.5 0 002 .5v6a.5.5 0 00.5.5zm.485 2.376a.5.5 0 01-.246.563l-11 6A.5.5 0 012 15.5v-6a.5.5 0 01.5-.5h11a.5.5 0 01.485.376M11.539 10H3v4.658z"/>',
    },
});
