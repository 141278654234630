/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'vinyl-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 6a2 2 0 100 4 2 2 0 000-4m0 3a1 1 0 110-2 1 1 0 010 2"/><path pid="1" d="M16 8A8 8 0 110 8a8 8 0 0116 0M4 8a4 4 0 108 0 4 4 0 00-8 0"/>',
    },
});
