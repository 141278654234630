/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-wheelchair': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12 3a1.5 1.5 0 100-3 1.5 1.5 0 000 3m-.663 2.146a1.5 1.5 0 00-.47-2.115l-2.5-1.508a1.5 1.5 0 00-1.676.086l-2.329 1.75a.866.866 0 001.051 1.375L7.361 3.37l.922.71-2.038 2.445A4.73 4.73 0 002.628 7.67l1.064 1.065a3.25 3.25 0 014.574 4.574l1.064 1.063a4.73 4.73 0 001.09-3.998l1.043-.292-.187 2.991a.872.872 0 101.741.098l.206-4.121A1 1 0 0012.224 8h-2.79zM3.023 9.48a3.25 3.25 0 004.496 4.496l1.077 1.077a4.75 4.75 0 01-6.65-6.65z"/>',
    },
});
