/* eslint-disable */
require('./0-circle-fill');
require('./0-circle');
require('./0-square-fill');
require('./0-square');
require('./1-circle-fill');
require('./1-circle');
require('./1-square-fill');
require('./1-square');
require('./123');
require('./2-circle-fill');
require('./2-circle');
require('./2-square-fill');
require('./2-square');
require('./3-circle-fill');
require('./3-circle');
require('./3-square-fill');
require('./3-square');
require('./4-circle-fill');
require('./4-circle');
require('./4-square-fill');
require('./4-square');
require('./5-circle-fill');
require('./5-circle');
require('./5-square-fill');
require('./5-square');
require('./6-circle-fill');
require('./6-circle');
require('./6-square-fill');
require('./6-square');
require('./7-circle-fill');
require('./7-circle');
require('./7-square-fill');
require('./7-square');
require('./8-circle-fill');
require('./8-circle');
require('./8-square-fill');
require('./8-square');
require('./9-circle-fill');
require('./9-circle');
require('./9-square-fill');
require('./9-square');
require('./activity');
require('./airplane-engines-fill');
require('./airplane-engines');
require('./airplane-fill');
require('./airplane');
require('./alarm-fill');
require('./alarm');
require('./alexa');
require('./align-bottom');
require('./align-center');
require('./align-end');
require('./align-middle');
require('./align-start');
require('./align-top');
require('./alipay');
require('./alphabet-uppercase');
require('./alphabet');
require('./alt');
require('./amazon');
require('./amd');
require('./android');
require('./android2');
require('./app-indicator');
require('./app');
require('./apple');
require('./archive-fill');
require('./archive');
require('./arrow-90deg-down');
require('./arrow-90deg-left');
require('./arrow-90deg-right');
require('./arrow-90deg-up');
require('./arrow-bar-down');
require('./arrow-bar-left');
require('./arrow-bar-right');
require('./arrow-bar-up');
require('./arrow-clockwise');
require('./arrow-counterclockwise');
require('./arrow-down-circle-fill');
require('./arrow-down-circle');
require('./arrow-down-left-circle-fill');
require('./arrow-down-left-circle');
require('./arrow-down-left-square-fill');
require('./arrow-down-left-square');
require('./arrow-down-left');
require('./arrow-down-right-circle-fill');
require('./arrow-down-right-circle');
require('./arrow-down-right-square-fill');
require('./arrow-down-right-square');
require('./arrow-down-right');
require('./arrow-down-short');
require('./arrow-down-square-fill');
require('./arrow-down-square');
require('./arrow-down-up');
require('./arrow-down');
require('./arrow-left-circle-fill');
require('./arrow-left-circle');
require('./arrow-left-right');
require('./arrow-left-short');
require('./arrow-left-square-fill');
require('./arrow-left-square');
require('./arrow-left');
require('./arrow-repeat');
require('./arrow-return-left');
require('./arrow-return-right');
require('./arrow-right-circle-fill');
require('./arrow-right-circle');
require('./arrow-right-short');
require('./arrow-right-square-fill');
require('./arrow-right-square');
require('./arrow-right');
require('./arrow-through-heart-fill');
require('./arrow-through-heart');
require('./arrow-up-circle-fill');
require('./arrow-up-circle');
require('./arrow-up-left-circle-fill');
require('./arrow-up-left-circle');
require('./arrow-up-left-square-fill');
require('./arrow-up-left-square');
require('./arrow-up-left');
require('./arrow-up-right-circle-fill');
require('./arrow-up-right-circle');
require('./arrow-up-right-square-fill');
require('./arrow-up-right-square');
require('./arrow-up-right');
require('./arrow-up-short');
require('./arrow-up-square-fill');
require('./arrow-up-square');
require('./arrow-up');
require('./arrows-angle-contract');
require('./arrows-angle-expand');
require('./arrows-collapse-vertical');
require('./arrows-collapse');
require('./arrows-expand-vertical');
require('./arrows-expand');
require('./arrows-fullscreen');
require('./arrows-move');
require('./arrows-vertical');
require('./arrows');
require('./aspect-ratio-fill');
require('./aspect-ratio');
require('./asterisk');
require('./at');
require('./award-fill');
require('./award');
require('./back');
require('./backpack-fill');
require('./backpack');
require('./backpack2-fill');
require('./backpack2');
require('./backpack3-fill');
require('./backpack3');
require('./backpack4-fill');
require('./backpack4');
require('./backspace-fill');
require('./backspace-reverse-fill');
require('./backspace-reverse');
require('./backspace');
require('./badge-3d-fill');
require('./badge-3d');
require('./badge-4k-fill');
require('./badge-4k');
require('./badge-8k-fill');
require('./badge-8k');
require('./badge-ad-fill');
require('./badge-ad');
require('./badge-ar-fill');
require('./badge-ar');
require('./badge-cc-fill');
require('./badge-cc');
require('./badge-hd-fill');
require('./badge-hd');
require('./badge-sd-fill');
require('./badge-sd');
require('./badge-tm-fill');
require('./badge-tm');
require('./badge-vo-fill');
require('./badge-vo');
require('./badge-vr-fill');
require('./badge-vr');
require('./badge-wc-fill');
require('./badge-wc');
require('./bag-check-fill');
require('./bag-check');
require('./bag-dash-fill');
require('./bag-dash');
require('./bag-fill');
require('./bag-heart-fill');
require('./bag-heart');
require('./bag-plus-fill');
require('./bag-plus');
require('./bag-x-fill');
require('./bag-x');
require('./bag');
require('./balloon-fill');
require('./balloon-heart-fill');
require('./balloon-heart');
require('./balloon');
require('./ban-fill');
require('./ban');
require('./bandaid-fill');
require('./bandaid');
require('./bank');
require('./bank2');
require('./bar-chart-fill');
require('./bar-chart-line-fill');
require('./bar-chart-line');
require('./bar-chart-steps');
require('./bar-chart');
require('./basket-fill');
require('./basket');
require('./basket2-fill');
require('./basket2');
require('./basket3-fill');
require('./basket3');
require('./battery-charging');
require('./battery-full');
require('./battery-half');
require('./battery');
require('./behance');
require('./bell-fill');
require('./bell-slash-fill');
require('./bell-slash');
require('./bell');
require('./bezier');
require('./bezier2');
require('./bicycle');
require('./bing');
require('./binoculars-fill');
require('./binoculars');
require('./blockquote-left');
require('./blockquote-right');
require('./bluetooth');
require('./body-text');
require('./book-fill');
require('./book-half');
require('./book');
require('./bookmark-check-fill');
require('./bookmark-check');
require('./bookmark-dash-fill');
require('./bookmark-dash');
require('./bookmark-fill');
require('./bookmark-heart-fill');
require('./bookmark-heart');
require('./bookmark-plus-fill');
require('./bookmark-plus');
require('./bookmark-star-fill');
require('./bookmark-star');
require('./bookmark-x-fill');
require('./bookmark-x');
require('./bookmark');
require('./bookmarks-fill');
require('./bookmarks');
require('./bookshelf');
require('./boombox-fill');
require('./boombox');
require('./bootstrap-fill');
require('./bootstrap-icons');
require('./bootstrap-reboot');
require('./bootstrap');
require('./border-all');
require('./border-bottom');
require('./border-center');
require('./border-inner');
require('./border-left');
require('./border-middle');
require('./border-outer');
require('./border-right');
require('./border-style');
require('./border-top');
require('./border-width');
require('./border');
require('./bounding-box-circles');
require('./bounding-box');
require('./box-arrow-down-left');
require('./box-arrow-down-right');
require('./box-arrow-down');
require('./box-arrow-in-down-left');
require('./box-arrow-in-down-right');
require('./box-arrow-in-down');
require('./box-arrow-in-left');
require('./box-arrow-in-right');
require('./box-arrow-in-up-left');
require('./box-arrow-in-up-right');
require('./box-arrow-in-up');
require('./box-arrow-left');
require('./box-arrow-right');
require('./box-arrow-up-left');
require('./box-arrow-up-right');
require('./box-arrow-up');
require('./box-fill');
require('./box-seam-fill');
require('./box-seam');
require('./box');
require('./box2-fill');
require('./box2-heart-fill');
require('./box2-heart');
require('./box2');
require('./boxes');
require('./braces-asterisk');
require('./braces');
require('./bricks');
require('./briefcase-fill');
require('./briefcase');
require('./brightness-alt-high-fill');
require('./brightness-alt-high');
require('./brightness-alt-low-fill');
require('./brightness-alt-low');
require('./brightness-high-fill');
require('./brightness-high');
require('./brightness-low-fill');
require('./brightness-low');
require('./brilliance');
require('./broadcast-pin');
require('./broadcast');
require('./browser-chrome');
require('./browser-edge');
require('./browser-firefox');
require('./browser-safari');
require('./brush-fill');
require('./brush');
require('./bucket-fill');
require('./bucket');
require('./bug-fill');
require('./bug');
require('./building-add');
require('./building-check');
require('./building-dash');
require('./building-down');
require('./building-exclamation');
require('./building-fill-add');
require('./building-fill-check');
require('./building-fill-dash');
require('./building-fill-down');
require('./building-fill-exclamation');
require('./building-fill-gear');
require('./building-fill-lock');
require('./building-fill-slash');
require('./building-fill-up');
require('./building-fill-x');
require('./building-fill');
require('./building-gear');
require('./building-lock');
require('./building-slash');
require('./building-up');
require('./building-x');
require('./building');
require('./buildings-fill');
require('./buildings');
require('./bullseye');
require('./bus-front-fill');
require('./bus-front');
require('./c-circle-fill');
require('./c-circle');
require('./c-square-fill');
require('./c-square');
require('./cake-fill');
require('./cake');
require('./cake2-fill');
require('./cake2');
require('./calculator-fill');
require('./calculator');
require('./calendar-check-fill');
require('./calendar-check');
require('./calendar-date-fill');
require('./calendar-date');
require('./calendar-day-fill');
require('./calendar-day');
require('./calendar-event-fill');
require('./calendar-event');
require('./calendar-fill');
require('./calendar-heart-fill');
require('./calendar-heart');
require('./calendar-minus-fill');
require('./calendar-minus');
require('./calendar-month-fill');
require('./calendar-month');
require('./calendar-plus-fill');
require('./calendar-plus');
require('./calendar-range-fill');
require('./calendar-range');
require('./calendar-week-fill');
require('./calendar-week');
require('./calendar-x-fill');
require('./calendar-x');
require('./calendar');
require('./calendar2-check-fill');
require('./calendar2-check');
require('./calendar2-date-fill');
require('./calendar2-date');
require('./calendar2-day-fill');
require('./calendar2-day');
require('./calendar2-event-fill');
require('./calendar2-event');
require('./calendar2-fill');
require('./calendar2-heart-fill');
require('./calendar2-heart');
require('./calendar2-minus-fill');
require('./calendar2-minus');
require('./calendar2-month-fill');
require('./calendar2-month');
require('./calendar2-plus-fill');
require('./calendar2-plus');
require('./calendar2-range-fill');
require('./calendar2-range');
require('./calendar2-week-fill');
require('./calendar2-week');
require('./calendar2-x-fill');
require('./calendar2-x');
require('./calendar2');
require('./calendar3-event-fill');
require('./calendar3-event');
require('./calendar3-fill');
require('./calendar3-range-fill');
require('./calendar3-range');
require('./calendar3-week-fill');
require('./calendar3-week');
require('./calendar3');
require('./calendar4-event');
require('./calendar4-range');
require('./calendar4-week');
require('./calendar4');
require('./camera-fill');
require('./camera-reels-fill');
require('./camera-reels');
require('./camera-video-fill');
require('./camera-video-off-fill');
require('./camera-video-off');
require('./camera-video');
require('./camera');
require('./camera2');
require('./capslock-fill');
require('./capslock');
require('./capsule-pill');
require('./capsule');
require('./car-front-fill');
require('./car-front');
require('./card-checklist');
require('./card-heading');
require('./card-image');
require('./card-list');
require('./card-text');
require('./caret-down-fill');
require('./caret-down-square-fill');
require('./caret-down-square');
require('./caret-down');
require('./caret-left-fill');
require('./caret-left-square-fill');
require('./caret-left-square');
require('./caret-left');
require('./caret-right-fill');
require('./caret-right-square-fill');
require('./caret-right-square');
require('./caret-right');
require('./caret-up-fill');
require('./caret-up-square-fill');
require('./caret-up-square');
require('./caret-up');
require('./cart-check-fill');
require('./cart-check');
require('./cart-dash-fill');
require('./cart-dash');
require('./cart-fill');
require('./cart-plus-fill');
require('./cart-plus');
require('./cart-x-fill');
require('./cart-x');
require('./cart');
require('./cart2');
require('./cart3');
require('./cart4');
require('./cash-coin');
require('./cash-stack');
require('./cash');
require('./cassette-fill');
require('./cassette');
require('./cast');
require('./cc-circle-fill');
require('./cc-circle');
require('./cc-square-fill');
require('./cc-square');
require('./chat-dots-fill');
require('./chat-dots');
require('./chat-fill');
require('./chat-heart-fill');
require('./chat-heart');
require('./chat-left-dots-fill');
require('./chat-left-dots');
require('./chat-left-fill');
require('./chat-left-heart-fill');
require('./chat-left-heart');
require('./chat-left-quote-fill');
require('./chat-left-quote');
require('./chat-left-text-fill');
require('./chat-left-text');
require('./chat-left');
require('./chat-quote-fill');
require('./chat-quote');
require('./chat-right-dots-fill');
require('./chat-right-dots');
require('./chat-right-fill');
require('./chat-right-heart-fill');
require('./chat-right-heart');
require('./chat-right-quote-fill');
require('./chat-right-quote');
require('./chat-right-text-fill');
require('./chat-right-text');
require('./chat-right');
require('./chat-square-dots-fill');
require('./chat-square-dots');
require('./chat-square-fill');
require('./chat-square-heart-fill');
require('./chat-square-heart');
require('./chat-square-quote-fill');
require('./chat-square-quote');
require('./chat-square-text-fill');
require('./chat-square-text');
require('./chat-square');
require('./chat-text-fill');
require('./chat-text');
require('./chat');
require('./check-all');
require('./check-circle-fill');
require('./check-circle');
require('./check-lg');
require('./check-square-fill');
require('./check-square');
require('./check');
require('./check2-all');
require('./check2-circle');
require('./check2-square');
require('./check2');
require('./chevron-bar-contract');
require('./chevron-bar-down');
require('./chevron-bar-expand');
require('./chevron-bar-left');
require('./chevron-bar-right');
require('./chevron-bar-up');
require('./chevron-compact-down');
require('./chevron-compact-left');
require('./chevron-compact-right');
require('./chevron-compact-up');
require('./chevron-contract');
require('./chevron-double-down');
require('./chevron-double-left');
require('./chevron-double-right');
require('./chevron-double-up');
require('./chevron-down');
require('./chevron-expand');
require('./chevron-left');
require('./chevron-right');
require('./chevron-up');
require('./circle-fill');
require('./circle-half');
require('./circle-square');
require('./circle');
require('./clipboard-check-fill');
require('./clipboard-check');
require('./clipboard-data-fill');
require('./clipboard-data');
require('./clipboard-fill');
require('./clipboard-heart-fill');
require('./clipboard-heart');
require('./clipboard-minus-fill');
require('./clipboard-minus');
require('./clipboard-plus-fill');
require('./clipboard-plus');
require('./clipboard-pulse');
require('./clipboard-x-fill');
require('./clipboard-x');
require('./clipboard');
require('./clipboard2-check-fill');
require('./clipboard2-check');
require('./clipboard2-data-fill');
require('./clipboard2-data');
require('./clipboard2-fill');
require('./clipboard2-heart-fill');
require('./clipboard2-heart');
require('./clipboard2-minus-fill');
require('./clipboard2-minus');
require('./clipboard2-plus-fill');
require('./clipboard2-plus');
require('./clipboard2-pulse-fill');
require('./clipboard2-pulse');
require('./clipboard2-x-fill');
require('./clipboard2-x');
require('./clipboard2');
require('./clock-fill');
require('./clock-history');
require('./clock');
require('./cloud-arrow-down-fill');
require('./cloud-arrow-down');
require('./cloud-arrow-up-fill');
require('./cloud-arrow-up');
require('./cloud-check-fill');
require('./cloud-check');
require('./cloud-download-fill');
require('./cloud-download');
require('./cloud-drizzle-fill');
require('./cloud-drizzle');
require('./cloud-fill');
require('./cloud-fog-fill');
require('./cloud-fog');
require('./cloud-fog2-fill');
require('./cloud-fog2');
require('./cloud-hail-fill');
require('./cloud-hail');
require('./cloud-haze-fill');
require('./cloud-haze');
require('./cloud-haze2-fill');
require('./cloud-haze2');
require('./cloud-lightning-fill');
require('./cloud-lightning-rain-fill');
require('./cloud-lightning-rain');
require('./cloud-lightning');
require('./cloud-minus-fill');
require('./cloud-minus');
require('./cloud-moon-fill');
require('./cloud-moon');
require('./cloud-plus-fill');
require('./cloud-plus');
require('./cloud-rain-fill');
require('./cloud-rain-heavy-fill');
require('./cloud-rain-heavy');
require('./cloud-rain');
require('./cloud-slash-fill');
require('./cloud-slash');
require('./cloud-sleet-fill');
require('./cloud-sleet');
require('./cloud-snow-fill');
require('./cloud-snow');
require('./cloud-sun-fill');
require('./cloud-sun');
require('./cloud-upload-fill');
require('./cloud-upload');
require('./cloud');
require('./clouds-fill');
require('./clouds');
require('./cloudy-fill');
require('./cloudy');
require('./code-slash');
require('./code-square');
require('./code');
require('./coin');
require('./collection-fill');
require('./collection-play-fill');
require('./collection-play');
require('./collection');
require('./columns-gap');
require('./columns');
require('./command');
require('./compass-fill');
require('./compass');
require('./cone-striped');
require('./cone');
require('./controller');
require('./cookie');
require('./copy');
require('./cpu-fill');
require('./cpu');
require('./credit-card-2-back-fill');
require('./credit-card-2-back');
require('./credit-card-2-front-fill');
require('./credit-card-2-front');
require('./credit-card-fill');
require('./credit-card');
require('./crop');
require('./crosshair');
require('./crosshair2');
require('./cup-fill');
require('./cup-hot-fill');
require('./cup-hot');
require('./cup-straw');
require('./cup');
require('./currency-bitcoin');
require('./currency-dollar');
require('./currency-euro');
require('./currency-exchange');
require('./currency-pound');
require('./currency-rupee');
require('./currency-yen');
require('./cursor-fill');
require('./cursor-text');
require('./cursor');
require('./dash-circle-dotted');
require('./dash-circle-fill');
require('./dash-circle');
require('./dash-lg');
require('./dash-square-dotted');
require('./dash-square-fill');
require('./dash-square');
require('./dash');
require('./database-add');
require('./database-check');
require('./database-dash');
require('./database-down');
require('./database-exclamation');
require('./database-fill-add');
require('./database-fill-check');
require('./database-fill-dash');
require('./database-fill-down');
require('./database-fill-exclamation');
require('./database-fill-gear');
require('./database-fill-lock');
require('./database-fill-slash');
require('./database-fill-up');
require('./database-fill-x');
require('./database-fill');
require('./database-gear');
require('./database-lock');
require('./database-slash');
require('./database-up');
require('./database-x');
require('./database');
require('./device-hdd-fill');
require('./device-hdd');
require('./device-ssd-fill');
require('./device-ssd');
require('./diagram-2-fill');
require('./diagram-2');
require('./diagram-3-fill');
require('./diagram-3');
require('./diamond-fill');
require('./diamond-half');
require('./diamond');
require('./dice-1-fill');
require('./dice-1');
require('./dice-2-fill');
require('./dice-2');
require('./dice-3-fill');
require('./dice-3');
require('./dice-4-fill');
require('./dice-4');
require('./dice-5-fill');
require('./dice-5');
require('./dice-6-fill');
require('./dice-6');
require('./disc-fill');
require('./disc');
require('./discord');
require('./display-fill');
require('./display');
require('./displayport-fill');
require('./displayport');
require('./distribute-horizontal');
require('./distribute-vertical');
require('./door-closed-fill');
require('./door-closed');
require('./door-open-fill');
require('./door-open');
require('./dot');
require('./download');
require('./dpad-fill');
require('./dpad');
require('./dribbble');
require('./dropbox');
require('./droplet-fill');
require('./droplet-half');
require('./droplet');
require('./duffle-fill');
require('./duffle');
require('./ear-fill');
require('./ear');
require('./earbuds');
require('./easel-fill');
require('./easel');
require('./easel2-fill');
require('./easel2');
require('./easel3-fill');
require('./easel3');
require('./egg-fill');
require('./egg-fried');
require('./egg');
require('./eject-fill');
require('./eject');
require('./emoji-angry-fill');
require('./emoji-angry');
require('./emoji-astonished-fill');
require('./emoji-astonished');
require('./emoji-dizzy-fill');
require('./emoji-dizzy');
require('./emoji-expressionless-fill');
require('./emoji-expressionless');
require('./emoji-frown-fill');
require('./emoji-frown');
require('./emoji-grimace-fill');
require('./emoji-grimace');
require('./emoji-grin-fill');
require('./emoji-grin');
require('./emoji-heart-eyes-fill');
require('./emoji-heart-eyes');
require('./emoji-kiss-fill');
require('./emoji-kiss');
require('./emoji-laughing-fill');
require('./emoji-laughing');
require('./emoji-neutral-fill');
require('./emoji-neutral');
require('./emoji-smile-fill');
require('./emoji-smile-upside-down-fill');
require('./emoji-smile-upside-down');
require('./emoji-smile');
require('./emoji-sunglasses-fill');
require('./emoji-sunglasses');
require('./emoji-surprise-fill');
require('./emoji-surprise');
require('./emoji-tear-fill');
require('./emoji-tear');
require('./emoji-wink-fill');
require('./emoji-wink');
require('./envelope-arrow-down-fill');
require('./envelope-arrow-down');
require('./envelope-arrow-up-fill');
require('./envelope-arrow-up');
require('./envelope-at-fill');
require('./envelope-at');
require('./envelope-check-fill');
require('./envelope-check');
require('./envelope-dash-fill');
require('./envelope-dash');
require('./envelope-exclamation-fill');
require('./envelope-exclamation');
require('./envelope-fill');
require('./envelope-heart-fill');
require('./envelope-heart');
require('./envelope-open-fill');
require('./envelope-open-heart-fill');
require('./envelope-open-heart');
require('./envelope-open');
require('./envelope-paper-fill');
require('./envelope-paper-heart-fill');
require('./envelope-paper-heart');
require('./envelope-paper');
require('./envelope-plus-fill');
require('./envelope-plus');
require('./envelope-slash-fill');
require('./envelope-slash');
require('./envelope-x-fill');
require('./envelope-x');
require('./envelope');
require('./eraser-fill');
require('./eraser');
require('./escape');
require('./ethernet');
require('./ev-front-fill');
require('./ev-front');
require('./ev-station-fill');
require('./ev-station');
require('./exclamation-circle-fill');
require('./exclamation-circle');
require('./exclamation-diamond-fill');
require('./exclamation-diamond');
require('./exclamation-lg');
require('./exclamation-octagon-fill');
require('./exclamation-octagon');
require('./exclamation-square-fill');
require('./exclamation-square');
require('./exclamation-triangle-fill');
require('./exclamation-triangle');
require('./exclamation');
require('./exclude');
require('./explicit-fill');
require('./explicit');
require('./exposure');
require('./eye-fill');
require('./eye-slash-fill');
require('./eye-slash');
require('./eye');
require('./eyedropper');
require('./eyeglasses');
require('./facebook');
require('./fan');
require('./fast-forward-btn-fill');
require('./fast-forward-btn');
require('./fast-forward-circle-fill');
require('./fast-forward-circle');
require('./fast-forward-fill');
require('./fast-forward');
require('./feather');
require('./feather2');
require('./file-arrow-down-fill');
require('./file-arrow-down');
require('./file-arrow-up-fill');
require('./file-arrow-up');
require('./file-bar-graph-fill');
require('./file-bar-graph');
require('./file-binary-fill');
require('./file-binary');
require('./file-break-fill');
require('./file-break');
require('./file-check-fill');
require('./file-check');
require('./file-code-fill');
require('./file-code');
require('./file-diff-fill');
require('./file-diff');
require('./file-earmark-arrow-down-fill');
require('./file-earmark-arrow-down');
require('./file-earmark-arrow-up-fill');
require('./file-earmark-arrow-up');
require('./file-earmark-bar-graph-fill');
require('./file-earmark-bar-graph');
require('./file-earmark-binary-fill');
require('./file-earmark-binary');
require('./file-earmark-break-fill');
require('./file-earmark-break');
require('./file-earmark-check-fill');
require('./file-earmark-check');
require('./file-earmark-code-fill');
require('./file-earmark-code');
require('./file-earmark-diff-fill');
require('./file-earmark-diff');
require('./file-earmark-easel-fill');
require('./file-earmark-easel');
require('./file-earmark-excel-fill');
require('./file-earmark-excel');
require('./file-earmark-fill');
require('./file-earmark-font-fill');
require('./file-earmark-font');
require('./file-earmark-image-fill');
require('./file-earmark-image');
require('./file-earmark-lock-fill');
require('./file-earmark-lock');
require('./file-earmark-lock2-fill');
require('./file-earmark-lock2');
require('./file-earmark-medical-fill');
require('./file-earmark-medical');
require('./file-earmark-minus-fill');
require('./file-earmark-minus');
require('./file-earmark-music-fill');
require('./file-earmark-music');
require('./file-earmark-pdf-fill');
require('./file-earmark-pdf');
require('./file-earmark-person-fill');
require('./file-earmark-person');
require('./file-earmark-play-fill');
require('./file-earmark-play');
require('./file-earmark-plus-fill');
require('./file-earmark-plus');
require('./file-earmark-post-fill');
require('./file-earmark-post');
require('./file-earmark-ppt-fill');
require('./file-earmark-ppt');
require('./file-earmark-richtext-fill');
require('./file-earmark-richtext');
require('./file-earmark-ruled-fill');
require('./file-earmark-ruled');
require('./file-earmark-slides-fill');
require('./file-earmark-slides');
require('./file-earmark-spreadsheet-fill');
require('./file-earmark-spreadsheet');
require('./file-earmark-text-fill');
require('./file-earmark-text');
require('./file-earmark-word-fill');
require('./file-earmark-word');
require('./file-earmark-x-fill');
require('./file-earmark-x');
require('./file-earmark-zip-fill');
require('./file-earmark-zip');
require('./file-earmark');
require('./file-easel-fill');
require('./file-easel');
require('./file-excel-fill');
require('./file-excel');
require('./file-fill');
require('./file-font-fill');
require('./file-font');
require('./file-image-fill');
require('./file-image');
require('./file-lock-fill');
require('./file-lock');
require('./file-lock2-fill');
require('./file-lock2');
require('./file-medical-fill');
require('./file-medical');
require('./file-minus-fill');
require('./file-minus');
require('./file-music-fill');
require('./file-music');
require('./file-pdf-fill');
require('./file-pdf');
require('./file-person-fill');
require('./file-person');
require('./file-play-fill');
require('./file-play');
require('./file-plus-fill');
require('./file-plus');
require('./file-post-fill');
require('./file-post');
require('./file-ppt-fill');
require('./file-ppt');
require('./file-richtext-fill');
require('./file-richtext');
require('./file-ruled-fill');
require('./file-ruled');
require('./file-slides-fill');
require('./file-slides');
require('./file-spreadsheet-fill');
require('./file-spreadsheet');
require('./file-text-fill');
require('./file-text');
require('./file-word-fill');
require('./file-word');
require('./file-x-fill');
require('./file-x');
require('./file-zip-fill');
require('./file-zip');
require('./file');
require('./files-alt');
require('./files');
require('./filetype-aac');
require('./filetype-ai');
require('./filetype-bmp');
require('./filetype-cs');
require('./filetype-css');
require('./filetype-csv');
require('./filetype-doc');
require('./filetype-docx');
require('./filetype-exe');
require('./filetype-gif');
require('./filetype-heic');
require('./filetype-html');
require('./filetype-java');
require('./filetype-jpg');
require('./filetype-js');
require('./filetype-json');
require('./filetype-jsx');
require('./filetype-key');
require('./filetype-m4p');
require('./filetype-md');
require('./filetype-mdx');
require('./filetype-mov');
require('./filetype-mp3');
require('./filetype-mp4');
require('./filetype-otf');
require('./filetype-pdf');
require('./filetype-php');
require('./filetype-png');
require('./filetype-ppt');
require('./filetype-pptx');
require('./filetype-psd');
require('./filetype-py');
require('./filetype-raw');
require('./filetype-rb');
require('./filetype-sass');
require('./filetype-scss');
require('./filetype-sh');
require('./filetype-sql');
require('./filetype-svg');
require('./filetype-tiff');
require('./filetype-tsx');
require('./filetype-ttf');
require('./filetype-txt');
require('./filetype-wav');
require('./filetype-woff');
require('./filetype-xls');
require('./filetype-xlsx');
require('./filetype-xml');
require('./filetype-yml');
require('./film');
require('./filter-circle-fill');
require('./filter-circle');
require('./filter-left');
require('./filter-right');
require('./filter-square-fill');
require('./filter-square');
require('./filter');
require('./fingerprint');
require('./fire');
require('./flag-fill');
require('./flag');
require('./floppy-fill');
require('./floppy');
require('./floppy2-fill');
require('./floppy2');
require('./flower1');
require('./flower2');
require('./flower3');
require('./folder-check');
require('./folder-fill');
require('./folder-minus');
require('./folder-plus');
require('./folder-symlink-fill');
require('./folder-symlink');
require('./folder-x');
require('./folder');
require('./folder2-open');
require('./folder2');
require('./fonts');
require('./forward-fill');
require('./forward');
require('./front');
require('./fuel-pump-diesel-fill');
require('./fuel-pump-diesel');
require('./fuel-pump-fill');
require('./fuel-pump');
require('./fullscreen-exit');
require('./fullscreen');
require('./funnel-fill');
require('./funnel');
require('./gear-fill');
require('./gear-wide-connected');
require('./gear-wide');
require('./gear');
require('./gem');
require('./gender-ambiguous');
require('./gender-female');
require('./gender-male');
require('./gender-neuter');
require('./gender-trans');
require('./geo-alt-fill');
require('./geo-alt');
require('./geo-fill');
require('./geo');
require('./gift-fill');
require('./gift');
require('./git');
require('./github');
require('./gitlab');
require('./globe-americas');
require('./globe-asia-australia');
require('./globe-central-south-asia');
require('./globe-europe-africa');
require('./globe');
require('./globe2');
require('./google-play');
require('./google');
require('./gpu-card');
require('./graph-down-arrow');
require('./graph-down');
require('./graph-up-arrow');
require('./graph-up');
require('./grid-1x2-fill');
require('./grid-1x2');
require('./grid-3x2-gap-fill');
require('./grid-3x2-gap');
require('./grid-3x2');
require('./grid-3x3-gap-fill');
require('./grid-3x3-gap');
require('./grid-3x3');
require('./grid-fill');
require('./grid');
require('./grip-horizontal');
require('./grip-vertical');
require('./h-circle-fill');
require('./h-circle');
require('./h-square-fill');
require('./h-square');
require('./hammer');
require('./hand-index-fill');
require('./hand-index-thumb-fill');
require('./hand-index-thumb');
require('./hand-index');
require('./hand-thumbs-down-fill');
require('./hand-thumbs-down');
require('./hand-thumbs-up-fill');
require('./hand-thumbs-up');
require('./handbag-fill');
require('./handbag');
require('./hash');
require('./hdd-fill');
require('./hdd-network-fill');
require('./hdd-network');
require('./hdd-rack-fill');
require('./hdd-rack');
require('./hdd-stack-fill');
require('./hdd-stack');
require('./hdd');
require('./hdmi-fill');
require('./hdmi');
require('./headphones');
require('./headset-vr');
require('./headset');
require('./heart-arrow');
require('./heart-fill');
require('./heart-half');
require('./heart-pulse-fill');
require('./heart-pulse');
require('./heart');
require('./heartbreak-fill');
require('./heartbreak');
require('./hearts');
require('./heptagon-fill');
require('./heptagon-half');
require('./heptagon');
require('./hexagon-fill');
require('./hexagon-half');
require('./hexagon');
require('./highlighter');
require('./highlights');
require('./hospital-fill');
require('./hospital');
require('./hourglass-bottom');
require('./hourglass-split');
require('./hourglass-top');
require('./hourglass');
require('./house-add-fill');
require('./house-add');
require('./house-check-fill');
require('./house-check');
require('./house-dash-fill');
require('./house-dash');
require('./house-door-fill');
require('./house-door');
require('./house-down-fill');
require('./house-down');
require('./house-exclamation-fill');
require('./house-exclamation');
require('./house-fill');
require('./house-gear-fill');
require('./house-gear');
require('./house-heart-fill');
require('./house-heart');
require('./house-lock-fill');
require('./house-lock');
require('./house-slash-fill');
require('./house-slash');
require('./house-up-fill');
require('./house-up');
require('./house-x-fill');
require('./house-x');
require('./house');
require('./houses-fill');
require('./houses');
require('./hr');
require('./hurricane');
require('./hypnotize');
require('./image-alt');
require('./image-fill');
require('./image');
require('./images');
require('./inbox-fill');
require('./inbox');
require('./inboxes-fill');
require('./inboxes');
require('./incognito');
require('./indent');
require('./infinity');
require('./info-circle-fill');
require('./info-circle');
require('./info-lg');
require('./info-square-fill');
require('./info-square');
require('./info');
require('./input-cursor-text');
require('./input-cursor');
require('./instagram');
require('./intersect');
require('./journal-album');
require('./journal-arrow-down');
require('./journal-arrow-up');
require('./journal-bookmark-fill');
require('./journal-bookmark');
require('./journal-check');
require('./journal-code');
require('./journal-medical');
require('./journal-minus');
require('./journal-plus');
require('./journal-richtext');
require('./journal-text');
require('./journal-x');
require('./journal');
require('./journals');
require('./joystick');
require('./justify-left');
require('./justify-right');
require('./justify');
require('./kanban-fill');
require('./kanban');
require('./key-fill');
require('./key');
require('./keyboard-fill');
require('./keyboard');
require('./ladder');
require('./lamp-fill');
require('./lamp');
require('./laptop-fill');
require('./laptop');
require('./layer-backward');
require('./layer-forward');
require('./layers-fill');
require('./layers-half');
require('./layers');
require('./layout-sidebar-inset-reverse');
require('./layout-sidebar-inset');
require('./layout-sidebar-reverse');
require('./layout-sidebar');
require('./layout-split');
require('./layout-text-sidebar-reverse');
require('./layout-text-sidebar');
require('./layout-text-window-reverse');
require('./layout-text-window');
require('./layout-three-columns');
require('./layout-wtf');
require('./life-preserver');
require('./lightbulb-fill');
require('./lightbulb-off-fill');
require('./lightbulb-off');
require('./lightbulb');
require('./lightning-charge-fill');
require('./lightning-charge');
require('./lightning-fill');
require('./lightning');
require('./line');
require('./link-45deg');
require('./link');
require('./linkedin');
require('./list-check');
require('./list-columns-reverse');
require('./list-columns');
require('./list-nested');
require('./list-ol');
require('./list-stars');
require('./list-task');
require('./list-ul');
require('./list');
require('./lock-fill');
require('./lock');
require('./luggage-fill');
require('./luggage');
require('./lungs-fill');
require('./lungs');
require('./magic');
require('./magnet-fill');
require('./magnet');
require('./mailbox-flag');
require('./mailbox');
require('./mailbox2-flag');
require('./mailbox2');
require('./map-fill');
require('./map');
require('./markdown-fill');
require('./markdown');
require('./marker-tip');
require('./mask');
require('./mastodon');
require('./medium');
require('./megaphone-fill');
require('./megaphone');
require('./memory');
require('./menu-app-fill');
require('./menu-app');
require('./menu-button-fill');
require('./menu-button-wide-fill');
require('./menu-button-wide');
require('./menu-button');
require('./menu-down');
require('./menu-up');
require('./messenger');
require('./meta');
require('./mic-fill');
require('./mic-mute-fill');
require('./mic-mute');
require('./mic');
require('./microsoft-teams');
require('./microsoft');
require('./minecart-loaded');
require('./minecart');
require('./modem-fill');
require('./modem');
require('./moisture');
require('./moon-fill');
require('./moon-stars-fill');
require('./moon-stars');
require('./moon');
require('./mortarboard-fill');
require('./mortarboard');
require('./motherboard-fill');
require('./motherboard');
require('./mouse-fill');
require('./mouse');
require('./mouse2-fill');
require('./mouse2');
require('./mouse3-fill');
require('./mouse3');
require('./music-note-beamed');
require('./music-note-list');
require('./music-note');
require('./music-player-fill');
require('./music-player');
require('./newspaper');
require('./nintendo-switch');
require('./node-minus-fill');
require('./node-minus');
require('./node-plus-fill');
require('./node-plus');
require('./noise-reduction');
require('./nut-fill');
require('./nut');
require('./nvidia');
require('./nvme-fill');
require('./nvme');
require('./octagon-fill');
require('./octagon-half');
require('./octagon');
require('./opencollective');
require('./optical-audio-fill');
require('./optical-audio');
require('./option');
require('./outlet');
require('./p-circle-fill');
require('./p-circle');
require('./p-square-fill');
require('./p-square');
require('./paint-bucket');
require('./palette-fill');
require('./palette');
require('./palette2');
require('./paperclip');
require('./paragraph');
require('./pass-fill');
require('./pass');
require('./passport-fill');
require('./passport');
require('./patch-check-fill');
require('./patch-check');
require('./patch-exclamation-fill');
require('./patch-exclamation');
require('./patch-minus-fill');
require('./patch-minus');
require('./patch-plus-fill');
require('./patch-plus');
require('./patch-question-fill');
require('./patch-question');
require('./pause-btn-fill');
require('./pause-btn');
require('./pause-circle-fill');
require('./pause-circle');
require('./pause-fill');
require('./pause');
require('./paypal');
require('./pc-display-horizontal');
require('./pc-display');
require('./pc-horizontal');
require('./pc');
require('./pci-card-network');
require('./pci-card-sound');
require('./pci-card');
require('./peace-fill');
require('./peace');
require('./pen-fill');
require('./pen');
require('./pencil-fill');
require('./pencil-square');
require('./pencil');
require('./pentagon-fill');
require('./pentagon-half');
require('./pentagon');
require('./people-fill');
require('./people');
require('./percent');
require('./person-add');
require('./person-arms-up');
require('./person-badge-fill');
require('./person-badge');
require('./person-bounding-box');
require('./person-check-fill');
require('./person-check');
require('./person-circle');
require('./person-dash-fill');
require('./person-dash');
require('./person-down');
require('./person-exclamation');
require('./person-fill-add');
require('./person-fill-check');
require('./person-fill-dash');
require('./person-fill-down');
require('./person-fill-exclamation');
require('./person-fill-gear');
require('./person-fill-lock');
require('./person-fill-slash');
require('./person-fill-up');
require('./person-fill-x');
require('./person-fill');
require('./person-gear');
require('./person-heart');
require('./person-hearts');
require('./person-lines-fill');
require('./person-lock');
require('./person-plus-fill');
require('./person-plus');
require('./person-raised-hand');
require('./person-rolodex');
require('./person-slash');
require('./person-square');
require('./person-standing-dress');
require('./person-standing');
require('./person-up');
require('./person-vcard-fill');
require('./person-vcard');
require('./person-video');
require('./person-video2');
require('./person-video3');
require('./person-walking');
require('./person-wheelchair');
require('./person-workspace');
require('./person-x-fill');
require('./person-x');
require('./person');
require('./phone-fill');
require('./phone-flip');
require('./phone-landscape-fill');
require('./phone-landscape');
require('./phone-vibrate-fill');
require('./phone-vibrate');
require('./phone');
require('./pie-chart-fill');
require('./pie-chart');
require('./piggy-bank-fill');
require('./piggy-bank');
require('./pin-angle-fill');
require('./pin-angle');
require('./pin-fill');
require('./pin-map-fill');
require('./pin-map');
require('./pin');
require('./pinterest');
require('./pip-fill');
require('./pip');
require('./play-btn-fill');
require('./play-btn');
require('./play-circle-fill');
require('./play-circle');
require('./play-fill');
require('./play');
require('./playstation');
require('./plug-fill');
require('./plug');
require('./plugin');
require('./plus-circle-dotted');
require('./plus-circle-fill');
require('./plus-circle');
require('./plus-lg');
require('./plus-slash-minus');
require('./plus-square-dotted');
require('./plus-square-fill');
require('./plus-square');
require('./plus');
require('./postage-fill');
require('./postage-heart-fill');
require('./postage-heart');
require('./postage');
require('./postcard-fill');
require('./postcard-heart-fill');
require('./postcard-heart');
require('./postcard');
require('./power');
require('./prescription');
require('./prescription2');
require('./printer-fill');
require('./printer');
require('./projector-fill');
require('./projector');
require('./puzzle-fill');
require('./puzzle');
require('./qr-code-scan');
require('./qr-code');
require('./question-circle-fill');
require('./question-circle');
require('./question-diamond-fill');
require('./question-diamond');
require('./question-lg');
require('./question-octagon-fill');
require('./question-octagon');
require('./question-square-fill');
require('./question-square');
require('./question');
require('./quora');
require('./quote');
require('./r-circle-fill');
require('./r-circle');
require('./r-square-fill');
require('./r-square');
require('./radar');
require('./radioactive');
require('./rainbow');
require('./receipt-cutoff');
require('./receipt');
require('./reception-0');
require('./reception-1');
require('./reception-2');
require('./reception-3');
require('./reception-4');
require('./record-btn-fill');
require('./record-btn');
require('./record-circle-fill');
require('./record-circle');
require('./record-fill');
require('./record');
require('./record2-fill');
require('./record2');
require('./recycle');
require('./reddit');
require('./regex');
require('./repeat-1');
require('./repeat');
require('./reply-all-fill');
require('./reply-all');
require('./reply-fill');
require('./reply');
require('./rewind-btn-fill');
require('./rewind-btn');
require('./rewind-circle-fill');
require('./rewind-circle');
require('./rewind-fill');
require('./rewind');
require('./robot');
require('./rocket-fill');
require('./rocket-takeoff-fill');
require('./rocket-takeoff');
require('./rocket');
require('./router-fill');
require('./router');
require('./rss-fill');
require('./rss');
require('./rulers');
require('./safe-fill');
require('./safe');
require('./safe2-fill');
require('./safe2');
require('./save-fill');
require('./save');
require('./save2-fill');
require('./save2');
require('./scissors');
require('./scooter');
require('./screwdriver');
require('./sd-card-fill');
require('./sd-card');
require('./search-heart-fill');
require('./search-heart');
require('./search');
require('./segmented-nav');
require('./send-arrow-down-fill');
require('./send-arrow-down');
require('./send-arrow-up-fill');
require('./send-arrow-up');
require('./send-check-fill');
require('./send-check');
require('./send-dash-fill');
require('./send-dash');
require('./send-exclamation-fill');
require('./send-exclamation');
require('./send-fill');
require('./send-plus-fill');
require('./send-plus');
require('./send-slash-fill');
require('./send-slash');
require('./send-x-fill');
require('./send-x');
require('./send');
require('./server');
require('./shadows');
require('./share-fill');
require('./share');
require('./shield-check');
require('./shield-exclamation');
require('./shield-fill-check');
require('./shield-fill-exclamation');
require('./shield-fill-minus');
require('./shield-fill-plus');
require('./shield-fill-x');
require('./shield-fill');
require('./shield-lock-fill');
require('./shield-lock');
require('./shield-minus');
require('./shield-plus');
require('./shield-shaded');
require('./shield-slash-fill');
require('./shield-slash');
require('./shield-x');
require('./shield');
require('./shift-fill');
require('./shift');
require('./shop-window');
require('./shop');
require('./shuffle');
require('./sign-dead-end-fill');
require('./sign-dead-end');
require('./sign-do-not-enter-fill');
require('./sign-do-not-enter');
require('./sign-intersection-fill');
require('./sign-intersection-side-fill');
require('./sign-intersection-side');
require('./sign-intersection-t-fill');
require('./sign-intersection-t');
require('./sign-intersection-y-fill');
require('./sign-intersection-y');
require('./sign-intersection');
require('./sign-merge-left-fill');
require('./sign-merge-left');
require('./sign-merge-right-fill');
require('./sign-merge-right');
require('./sign-no-left-turn-fill');
require('./sign-no-left-turn');
require('./sign-no-parking-fill');
require('./sign-no-parking');
require('./sign-no-right-turn-fill');
require('./sign-no-right-turn');
require('./sign-railroad-fill');
require('./sign-railroad');
require('./sign-stop-fill');
require('./sign-stop-lights-fill');
require('./sign-stop-lights');
require('./sign-stop');
require('./sign-turn-left-fill');
require('./sign-turn-left');
require('./sign-turn-right-fill');
require('./sign-turn-right');
require('./sign-turn-slight-left-fill');
require('./sign-turn-slight-left');
require('./sign-turn-slight-right-fill');
require('./sign-turn-slight-right');
require('./sign-yield-fill');
require('./sign-yield');
require('./signal');
require('./signpost-2-fill');
require('./signpost-2');
require('./signpost-fill');
require('./signpost-split-fill');
require('./signpost-split');
require('./signpost');
require('./sim-fill');
require('./sim-slash-fill');
require('./sim-slash');
require('./sim');
require('./sina-weibo');
require('./skip-backward-btn-fill');
require('./skip-backward-btn');
require('./skip-backward-circle-fill');
require('./skip-backward-circle');
require('./skip-backward-fill');
require('./skip-backward');
require('./skip-end-btn-fill');
require('./skip-end-btn');
require('./skip-end-circle-fill');
require('./skip-end-circle');
require('./skip-end-fill');
require('./skip-end');
require('./skip-forward-btn-fill');
require('./skip-forward-btn');
require('./skip-forward-circle-fill');
require('./skip-forward-circle');
require('./skip-forward-fill');
require('./skip-forward');
require('./skip-start-btn-fill');
require('./skip-start-btn');
require('./skip-start-circle-fill');
require('./skip-start-circle');
require('./skip-start-fill');
require('./skip-start');
require('./skype');
require('./slack');
require('./slash-circle-fill');
require('./slash-circle');
require('./slash-lg');
require('./slash-square-fill');
require('./slash-square');
require('./slash');
require('./sliders');
require('./sliders2-vertical');
require('./sliders2');
require('./smartwatch');
require('./snapchat');
require('./snow');
require('./snow2');
require('./snow3');
require('./sort-alpha-down-alt');
require('./sort-alpha-down');
require('./sort-alpha-up-alt');
require('./sort-alpha-up');
require('./sort-down-alt');
require('./sort-down');
require('./sort-numeric-down-alt');
require('./sort-numeric-down');
require('./sort-numeric-up-alt');
require('./sort-numeric-up');
require('./sort-up-alt');
require('./sort-up');
require('./soundwave');
require('./sourceforge');
require('./speaker-fill');
require('./speaker');
require('./speedometer');
require('./speedometer2');
require('./spellcheck');
require('./spotify');
require('./square-fill');
require('./square-half');
require('./square');
require('./stack-overflow');
require('./stack');
require('./star-fill');
require('./star-half');
require('./star');
require('./stars');
require('./steam');
require('./stickies-fill');
require('./stickies');
require('./sticky-fill');
require('./sticky');
require('./stop-btn-fill');
require('./stop-btn');
require('./stop-circle-fill');
require('./stop-circle');
require('./stop-fill');
require('./stop');
require('./stoplights-fill');
require('./stoplights');
require('./stopwatch-fill');
require('./stopwatch');
require('./strava');
require('./stripe');
require('./subscript');
require('./substack');
require('./subtract');
require('./suit-club-fill');
require('./suit-club');
require('./suit-diamond-fill');
require('./suit-diamond');
require('./suit-heart-fill');
require('./suit-heart');
require('./suit-spade-fill');
require('./suit-spade');
require('./suitcase-fill');
require('./suitcase-lg-fill');
require('./suitcase-lg');
require('./suitcase');
require('./suitcase2-fill');
require('./suitcase2');
require('./sun-fill');
require('./sun');
require('./sunglasses');
require('./sunrise-fill');
require('./sunrise');
require('./sunset-fill');
require('./sunset');
require('./superscript');
require('./symmetry-horizontal');
require('./symmetry-vertical');
require('./table');
require('./tablet-fill');
require('./tablet-landscape-fill');
require('./tablet-landscape');
require('./tablet');
require('./tag-fill');
require('./tag');
require('./tags-fill');
require('./tags');
require('./taxi-front-fill');
require('./taxi-front');
require('./telegram');
require('./telephone-fill');
require('./telephone-forward-fill');
require('./telephone-forward');
require('./telephone-inbound-fill');
require('./telephone-inbound');
require('./telephone-minus-fill');
require('./telephone-minus');
require('./telephone-outbound-fill');
require('./telephone-outbound');
require('./telephone-plus-fill');
require('./telephone-plus');
require('./telephone-x-fill');
require('./telephone-x');
require('./telephone');
require('./tencent-qq');
require('./terminal-dash');
require('./terminal-fill');
require('./terminal-plus');
require('./terminal-split');
require('./terminal-x');
require('./terminal');
require('./text-center');
require('./text-indent-left');
require('./text-indent-right');
require('./text-left');
require('./text-paragraph');
require('./text-right');
require('./text-wrap');
require('./textarea-resize');
require('./textarea-t');
require('./textarea');
require('./thermometer-half');
require('./thermometer-high');
require('./thermometer-low');
require('./thermometer-snow');
require('./thermometer-sun');
require('./thermometer');
require('./threads-fill');
require('./threads');
require('./three-dots-vertical');
require('./three-dots');
require('./thunderbolt-fill');
require('./thunderbolt');
require('./ticket-detailed-fill');
require('./ticket-detailed');
require('./ticket-fill');
require('./ticket-perforated-fill');
require('./ticket-perforated');
require('./ticket');
require('./tiktok');
require('./toggle-off');
require('./toggle-on');
require('./toggle2-off');
require('./toggle2-on');
require('./toggles');
require('./toggles2');
require('./tools');
require('./tornado');
require('./train-freight-front-fill');
require('./train-freight-front');
require('./train-front-fill');
require('./train-front');
require('./train-lightrail-front-fill');
require('./train-lightrail-front');
require('./translate');
require('./transparency');
require('./trash-fill');
require('./trash');
require('./trash2-fill');
require('./trash2');
require('./trash3-fill');
require('./trash3');
require('./tree-fill');
require('./tree');
require('./trello');
require('./triangle-fill');
require('./triangle-half');
require('./triangle');
require('./trophy-fill');
require('./trophy');
require('./tropical-storm');
require('./truck-flatbed');
require('./truck-front-fill');
require('./truck-front');
require('./truck');
require('./tsunami');
require('./tv-fill');
require('./tv');
require('./twitch');
require('./twitter-x');
require('./twitter');
require('./type-bold');
require('./type-h1');
require('./type-h2');
require('./type-h3');
require('./type-h4');
require('./type-h5');
require('./type-h6');
require('./type-italic');
require('./type-strikethrough');
require('./type-underline');
require('./type');
require('./ubuntu');
require('./ui-checks-grid');
require('./ui-checks');
require('./ui-radios-grid');
require('./ui-radios');
require('./umbrella-fill');
require('./umbrella');
require('./unindent');
require('./union');
require('./unity');
require('./universal-access-circle');
require('./universal-access');
require('./unlock-fill');
require('./unlock');
require('./upc-scan');
require('./upc');
require('./upload');
require('./usb-c-fill');
require('./usb-c');
require('./usb-drive-fill');
require('./usb-drive');
require('./usb-fill');
require('./usb-micro-fill');
require('./usb-micro');
require('./usb-mini-fill');
require('./usb-mini');
require('./usb-plug-fill');
require('./usb-plug');
require('./usb-symbol');
require('./usb');
require('./valentine');
require('./valentine2');
require('./vector-pen');
require('./view-list');
require('./view-stacked');
require('./vignette');
require('./vimeo');
require('./vinyl-fill');
require('./vinyl');
require('./virus');
require('./virus2');
require('./voicemail');
require('./volume-down-fill');
require('./volume-down');
require('./volume-mute-fill');
require('./volume-mute');
require('./volume-off-fill');
require('./volume-off');
require('./volume-up-fill');
require('./volume-up');
require('./vr');
require('./wallet-fill');
require('./wallet');
require('./wallet2');
require('./watch');
require('./water');
require('./webcam-fill');
require('./webcam');
require('./wechat');
require('./whatsapp');
require('./wifi-1');
require('./wifi-2');
require('./wifi-off');
require('./wifi');
require('./wikipedia');
require('./wind');
require('./window-dash');
require('./window-desktop');
require('./window-dock');
require('./window-fullscreen');
require('./window-plus');
require('./window-sidebar');
require('./window-split');
require('./window-stack');
require('./window-x');
require('./window');
require('./windows');
require('./wordpress');
require('./wrench-adjustable-circle-fill');
require('./wrench-adjustable-circle');
require('./wrench-adjustable');
require('./wrench');
require('./x-circle-fill');
require('./x-circle');
require('./x-diamond-fill');
require('./x-diamond');
require('./x-lg');
require('./x-octagon-fill');
require('./x-octagon');
require('./x-square-fill');
require('./x-square');
require('./x');
require('./xbox');
require('./yelp');
require('./yin-yang');
require('./youtube');
require('./zoom-in');
require('./zoom-out');
