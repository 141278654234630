/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'dice-3-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3 0a3 3 0 00-3 3v10a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm2.5 4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0m8 8a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0M8 9.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3"/>',
    },
});
