/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-astonished': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 15A7 7 0 118 1a7 7 0 010 14m0 1A8 8 0 108 0a8 8 0 000 16"/><path pid="1" d="M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5M4.884 4.022a2 2 0 011.458-.048.5.5 0 00.316-.948 3 3 0 00-2.167.077 3.1 3.1 0 00-.773.478q-.036.03-.07.064l-.002.001a.5.5 0 00.707.708l-.001.002.001-.002a2 2 0 01.122-.1 2 2 0 01.41-.232zm6.232 0a2 2 0 00-1.458-.048.5.5 0 11-.316-.948 3 3 0 012.168.077 3 3 0 01.773.478l.07.064v.001a.5.5 0 01-.706.708l.002.002-.002-.002a2 2 0 00-.122-.1 2 2 0 00-.41-.232zM8 10c-.998 0-1.747.623-2.247 1.246-.383.478.08 1.06.687.98q1.56-.202 3.12 0c.606.08 1.07-.502.687-.98C9.747 10.623 8.998 10 8 10"/>',
    },
});
