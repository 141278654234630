/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'egg-fried': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 11a3 3 0 100-6 3 3 0 000 6"/><path pid="1" d="M13.997 5.17a5 5 0 00-8.101-4.09A5 5 0 001.28 9.342a5 5 0 008.336 5.109 3.5 3.5 0 005.201-4.065 3.001 3.001 0 00-.822-5.216zm-1-.034a1 1 0 00.668.977 2.001 2.001 0 01.547 3.478 1 1 0 00-.341 1.113 2.5 2.5 0 01-3.715 2.905 1 1 0 00-1.262.152 4 4 0 01-6.67-4.087 1 1 0 00-.2-1 4 4 0 013.693-6.61 1 1 0 00.8-.2 4 4 0 016.48 3.273z"/>',
    },
});
