/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    basket3: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.757 1.071a.5.5 0 01.172.686L3.383 6h9.234L10.07 1.757a.5.5 0 11.858-.514L13.783 6H15.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5v-1A.5.5 0 01.5 6h1.717L5.07 1.243a.5.5 0 01.686-.172zM3.394 15l-1.48-6h-.97l1.525 6.426a.75.75 0 00.729.574h9.606a.75.75 0 00.73-.574L15.056 9h-.972l-1.479 6z"/>',
    },
});
