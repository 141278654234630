/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-gif': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2H9v-1h3a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM3.278 13.124a1.4 1.4 0 00-.14-.492 1.3 1.3 0 00-.314-.407 1.5 1.5 0 00-.48-.275 1.9 1.9 0 00-.636-.1q-.542 0-.926.229a1.5 1.5 0 00-.583.632 2.1 2.1 0 00-.199.95v.506q0 .408.105.745.105.336.32.58.213.243.533.377.323.132.753.132.402 0 .697-.111a1.29 1.29 0 00.788-.77q.097-.261.097-.551v-.797H1.717v.589h.823v.255q0 .199-.09.363a.67.67 0 01-.273.264 1 1 0 01-.457.096.87.87 0 01-.519-.146.9.9 0 01-.305-.413 1.8 1.8 0 01-.096-.615v-.499q0-.547.234-.85.237-.3.665-.301a1 1 0 01.3.044q.136.044.236.126a.7.7 0 01.17.19.8.8 0 01.097.25zm1.353 2.801v-3.999H3.84v4h.79zm1.493-1.59v1.59h-.791v-3.999H7.88v.653H6.124v1.117h1.605v.638z"/>',
    },
});
