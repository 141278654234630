/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'fuel-pump-diesel-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4.974 9.806h.692q.459 0 .75.19.297.191.437.568.144.377.144.941 0 .425-.083.74-.08.315-.241.528a1 1 0 01-.412.315 1.6 1.6 0 01-.595.103h-.692z"/><path pid="1" d="M1 2a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 012 2v.5a.5.5 0 001 0V8h-.5a.5.5 0 01-.5-.5V4.375a.5.5 0 01.5-.5h1.495c-.011-.476-.053-.894-.201-1.222a.97.97 0 00-.394-.458c-.184-.11-.464-.195-.9-.195a.5.5 0 010-1q.846-.002 1.412.336c.383.228.634.551.794.907.295.655.294 1.465.294 2.081V7.5a.5.5 0 01-.5.5H15v4.5a1.5 1.5 0 01-3 0V12a1 1 0 00-1-1v4h.5a.5.5 0 010 1H.5a.5.5 0 010-1H1zm2 .5v5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5M4 9v5h1.796q.744 0 1.23-.297.49-.296.732-.86T8 11.487q0-.788-.242-1.344a1.78 1.78 0 00-.725-.85Q6.547 9 5.796 9z"/>',
    },
});
