/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'door-closed': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3 2a1 1 0 011-1h8a1 1 0 011 1v13h1.5a.5.5 0 010 1h-13a.5.5 0 010-1H3zm1 13h8V2H4z"/><path pid="1" d="M9 9a1 1 0 102 0 1 1 0 00-2 0"/>',
    },
});
