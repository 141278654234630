/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-lines-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6 8a3 3 0 100-6 3 3 0 000 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5m.5 2.5a.5.5 0 000 1h4a.5.5 0 000-1zm2 3a.5.5 0 000 1h2a.5.5 0 000-1zm0 3a.5.5 0 000 1h2a.5.5 0 000-1z"/>',
    },
});
