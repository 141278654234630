/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'skip-backward-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M.5 3.5A.5.5 0 000 4v8a.5.5 0 001 0V8.753l6.267 3.636c.54.313 1.233-.066 1.233-.697v-2.94l6.267 3.636c.54.314 1.233-.065 1.233-.696V4.308c0-.63-.693-1.01-1.233-.696L8.5 7.248v-2.94c0-.63-.692-1.01-1.233-.696L1 7.248V4a.5.5 0 00-.5-.5"/>',
    },
});
