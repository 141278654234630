/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'optical-audio-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 6a3 3 0 110 6 3 3 0 010-6m1 3a1 1 0 10-2 0 1 1 0 002 0"/><path pid="1" d="M2.5 15a.5.5 0 01-.5-.5v-3.05a2.5 2.5 0 010-4.9V4.5a.5.5 0 01.146-.354l2-2A.5.5 0 014.5 2h7a.5.5 0 01.354.146l2 2A.5.5 0 0114 4.5v2.05a2.5 2.5 0 010 4.9v3.05a.5.5 0 01-.5.5zM8 5a4 4 0 100 8 4 4 0 000-8"/>',
    },
});
