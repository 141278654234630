/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'reply-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.921 11.9L1.353 8.62a.72.72 0 010-1.238L5.921 4.1A.716.716 0 017 4.719V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z"/>',
    },
});
