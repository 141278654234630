/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cloud-sun-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11.473 11a4.5 4.5 0 00-8.72-.99A3 3 0 003 16h8.5a2.5 2.5 0 000-5z"/><path pid="1" d="M10.5 1.5a.5.5 0 00-1 0v1a.5.5 0 001 0zm3.743 1.964a.5.5 0 10-.707-.707l-.708.707a.5.5 0 00.708.708zm-7.779-.707a.5.5 0 00-.707.707l.707.708a.5.5 0 10.708-.708zm1.734 3.374a2 2 0 113.296 2.198q.3.423.516.898a3 3 0 10-4.84-3.225q.529.017 1.028.129m4.484 4.074c.6.215 1.125.59 1.522 1.072a.5.5 0 00.039-.742l-.707-.707a.5.5 0 00-.854.377M14.5 6.5a.5.5 0 000 1h1a.5.5 0 000-1z"/>',
    },
});
