/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    clock: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 3.5a.5.5 0 00-1 0V9a.5.5 0 00.252.434l3.5 2a.5.5 0 00.496-.868L8 8.71z"/><path pid="1" d="M8 16A8 8 0 108 0a8 8 0 000 16m7-8A7 7 0 111 8a7 7 0 0114 0"/>',
    },
});
