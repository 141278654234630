/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'ear-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5 0A5.5 5.5 0 003 5.5v7.047a3.453 3.453 0 006.687 1.212l.51-1.363a4.6 4.6 0 01.67-1.197l2.008-2.581A5.34 5.34 0 008.66 0zM7 5.5v2.695q.168-.09.332-.192c.327-.208.577-.44.72-.727a.5.5 0 11.895.448c-.256.513-.673.865-1.079 1.123A9 9 0 017 9.313V11.5a.5.5 0 01-1 0v-6a2.5 2.5 0 015 0V6a.5.5 0 01-1 0v-.5a1.5 1.5 0 10-3 0"/>',
    },
});
