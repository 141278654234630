/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'hdd-network': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4.5 5a.5.5 0 100-1 .5.5 0 000 1M3 4.5a.5.5 0 11-1 0 .5.5 0 011 0"/><path pid="1" d="M0 4a2 2 0 012-2h12a2 2 0 012 2v1a2 2 0 01-2 2H8.5v3a1.5 1.5 0 011.5 1.5h5.5a.5.5 0 010 1H10A1.5 1.5 0 018.5 14h-1A1.5 1.5 0 016 12.5H.5a.5.5 0 010-1H6A1.5 1.5 0 017.5 10V7H2a2 2 0 01-2-2zm1 0v1a1 1 0 001 1h12a1 1 0 001-1V4a1 1 0 00-1-1H2a1 1 0 00-1 1m6 7.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5"/>',
    },
});
