/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    cart3: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 1.5A.5.5 0 01.5 1H2a.5.5 0 01.485.379L2.89 3H14.5a.5.5 0 01.49.598l-1 5a.5.5 0 01-.465.401l-9.397.472L4.415 11H13a.5.5 0 010 1H4a.5.5 0 01-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 01-.5-.5M3.102 4l.84 4.479 9.144-.459L13.89 4zM5 12a2 2 0 100 4 2 2 0 000-4m7 0a2 2 0 100 4 2 2 0 000-4m-7 1a1 1 0 110 2 1 1 0 010-2m7 0a1 1 0 110 2 1 1 0 010-2"/>',
    },
});
