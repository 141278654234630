/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sd-card-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.5 0H5.914a1.5 1.5 0 00-1.06.44L2.439 2.853A1.5 1.5 0 002 3.914V14.5A1.5 1.5 0 003.5 16h9a1.5 1.5 0 001.5-1.5v-13A1.5 1.5 0 0012.5 0m-7 2.75a.75.75 0 01.75.75v2a.75.75 0 01-1.5 0v-2a.75.75 0 01.75-.75m2 0a.75.75 0 01.75.75v2a.75.75 0 01-1.5 0v-2a.75.75 0 01.75-.75m2.75.75v2a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0m1.25-.75a.75.75 0 01.75.75v2a.75.75 0 01-1.5 0v-2a.75.75 0 01.75-.75"/>',
    },
});
