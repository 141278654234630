/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'bookmark-plus-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M2 15.5V2a2 2 0 012-2h8a2 2 0 012 2v13.5a.5.5 0 01-.74.439L8 13.069l-5.26 2.87A.5.5 0 012 15.5m6.5-11a.5.5 0 00-1 0V6H6a.5.5 0 000 1h1.5v1.5a.5.5 0 001 0V7H10a.5.5 0 000-1H8.5z"/>',
    },
});
