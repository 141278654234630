/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'train-freight-front-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.736 0a1.5 1.5 0 00-.67.158L1.828 1.776A1.5 1.5 0 001 3.118v5.51l2-.6V5a1 1 0 011-1h8a1 1 0 011 1v3.028l2 .6v-5.51a1.5 1.5 0 00-.83-1.342L10.936.158A1.5 1.5 0 0010.264 0zM15 9.672l-5.503-1.65A.5.5 0 009.353 8H8.5v8h4a2.5 2.5 0 002.5-2.5zM7.5 16V8h-.853a.5.5 0 00-.144.021L1 9.672V13.5A2.5 2.5 0 003.5 16zm-1-14h3a.5.5 0 010 1h-3a.5.5 0 010-1M12 5v2.728l-2.216-.665A1.5 1.5 0 009.354 7H8.5V5zM7.5 5v2h-.853a1.5 1.5 0 00-.431.063L4 7.728V5zm-4 5a.5.5 0 110 1 .5.5 0 010-1m9 0a.5.5 0 110 1 .5.5 0 010-1M5 13a1 1 0 11-2 0 1 1 0 012 0m7 1a1 1 0 110-2 1 1 0 010 2"/>',
    },
});
