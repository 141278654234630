/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'chat-heart': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M2.965 12.695a1 1 0 00-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6-3.004 6-7 6a8 8 0 01-2.088-.272 1 1 0 00-.711.074c-.387.196-1.24.57-2.634.893a11 11 0 00.398-2m-.8 3.108l.02-.004c1.83-.363 2.948-.842 3.468-1.105A9 9 0 008 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.4 10.4 0 01-.524 2.318l-.003.011a11 11 0 01-.244.637c-.079.186.074.394.273.362a22 22 0 00.693-.125M8 5.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132"/>',
    },
});
