/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    pin: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4.146.146A.5.5 0 014.5 0h7a.5.5 0 01.5.5c0 .68-.342 1.174-.646 1.479-.126.125-.25.224-.354.298v4.431l.078.048c.203.127.476.314.751.555C12.36 7.775 13 8.527 13 9.5a.5.5 0 01-.5.5h-4v4.5c0 .276-.224 1.5-.5 1.5s-.5-1.224-.5-1.5V10h-4a.5.5 0 01-.5-.5c0-.973.64-1.725 1.17-2.189A6 6 0 015 6.708V2.277a3 3 0 01-.354-.298C4.342 1.674 4 1.179 4 .5a.5.5 0 01.146-.354m1.58 1.408l-.002-.001zm-.002-.001l.002.001A.5.5 0 016 2v5a.5.5 0 01-.276.447h-.002l-.012.007-.054.03a5 5 0 00-.827.58c-.318.278-.585.596-.725.936h7.792c-.14-.34-.407-.658-.725-.936a5 5 0 00-.881-.61l-.012-.006h-.002A.5.5 0 0110 7V2a.5.5 0 01.295-.458 1.8 1.8 0 00.351-.271c.08-.08.155-.17.214-.271H5.14q.091.15.214.271a1.8 1.8 0 00.37.282"/>',
    },
});
