/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-dizzy-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 16A8 8 0 108 0a8 8 0 000 16M4.146 5.146a.5.5 0 01.708 0l.646.647.646-.647a.5.5 0 11.708.708l-.647.646.647.646a.5.5 0 11-.708.708L5.5 7.207l-.646.647a.5.5 0 11-.708-.708l.647-.646-.647-.646a.5.5 0 010-.708m5 0a.5.5 0 01.708 0l.646.647.646-.647a.5.5 0 01.708.708l-.647.646.647.646a.5.5 0 01-.708.708l-.646-.647-.646.647a.5.5 0 11-.708-.708l.647-.646-.647-.646a.5.5 0 010-.708M8 13a2 2 0 110-4 2 2 0 010 4"/>',
    },
});
