/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'suitcase-lg-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7 0a2 2 0 00-2 2H1.5A1.5 1.5 0 000 3.5v9A1.5 1.5 0 001.5 14H2a.5.5 0 001 0h10a.5.5 0 001 0h.5a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0014.5 2H11a2 2 0 00-2-2zM6 2a1 1 0 011-1h2a1 1 0 011 1zM3 13V3h1v10zm9 0V3h1v10z"/>',
    },
});
