/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'laptop-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.5 2A1.5 1.5 0 001 3.5V12h14V3.5A1.5 1.5 0 0013.5 2zM0 12.5h16a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 010 12.5"/>',
    },
});
