/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    wind: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.5 2A2.5 2.5 0 0010 4.5a.5.5 0 01-1 0A3.5 3.5 0 1112.5 8H.5a.5.5 0 010-1h12a2.5 2.5 0 000-5m-7 1a1 1 0 00-1 1 .5.5 0 01-1 0 2 2 0 112 2h-5a.5.5 0 010-1h5a1 1 0 000-2M0 9.5A.5.5 0 01.5 9h10.042a3 3 0 11-3 3 .5.5 0 011 0 2 2 0 102-2H.5a.5.5 0 01-.5-.5"/>',
    },
});
