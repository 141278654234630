/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'layout-sidebar': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 3a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H2a2 2 0 01-2-2zm5-1v12h9a1 1 0 001-1V3a1 1 0 00-1-1zM4 2H2a1 1 0 00-1 1v10a1 1 0 001 1h2z"/>',
    },
});
