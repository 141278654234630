/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'wifi-off': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10.706 3.294A12.6 12.6 0 008 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 00-.048.736.52.52 0 00.668.05A11.45 11.45 0 018 4q.946 0 1.852.148zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 00-.063.745.525.525 0 00.652.065 8.45 8.45 0 013.51-1.27zm2.596 1.404l.785-.785q.947.362 1.785.907a.482.482 0 01.063.745.525.525 0 01-.652.065 8.5 8.5 0 00-1.98-.932zM8 10l.933-.933a6.5 6.5 0 012.013.637c.285.145.326.524.1.75l-.015.015a.53.53 0 01-.611.09A5.5 5.5 0 008 10m4.905-4.905l.747-.747q.886.451 1.685 1.03a.485.485 0 01.047.737.52.52 0 01-.668.05 11.5 11.5 0 00-1.811-1.07M9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 01-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A2 2 0 018 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 01.75.75l-10.75 10.75a.53.53 0 01-.75-.75z"/>',
    },
});
