/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    bell: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 16a2 2 0 002-2H6a2 2 0 002 2M8 1.918l-.797.161A4 4 0 004 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 00-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 111.99 0A5 5 0 0113 6c0 .88.32 4.2 1.22 6"/>',
    },
});
