/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    bicycle: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 4.5a.5.5 0 01.5-.5H6a.5.5 0 010 1v.5h4.14l.386-1.158A.5.5 0 0111 4h1a.5.5 0 010 1h-.64l-.311.935.807 1.29a3 3 0 11-.848.53l-.508-.812-2.076 3.322A.5.5 0 018 10.5H5.959a3 3 0 11-1.815-3.274L5 5.856V5h-.5a.5.5 0 01-.5-.5m1.5 2.443l-.508.814c.5.444.85 1.054.967 1.743h1.139zM8 9.057L9.598 6.5H6.402zM4.937 9.5a2 2 0 00-.487-.877l-.548.877zM3.603 8.092A2 2 0 104.937 10.5H3a.5.5 0 01-.424-.765zm7.947.53a2 2 0 10.848-.53l1.026 1.643a.5.5 0 11-.848.53z"/>',
    },
});
