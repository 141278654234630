/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'webcam-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.644 11.094a.5.5 0 01.356-.15h2a.5.5 0 01.356.15c.175.177.39.347.603.496a7 7 0 00.752.456l.01.006h.003A.5.5 0 0110.5 13h-5a.5.5 0 01-.224-.947l.002-.001.01-.006a4 4 0 00.214-.116 8 8 0 00.539-.34c.214-.15.428-.319.603-.496M7 6.5a1 1 0 112 0 1 1 0 01-2 0"/><path pid="1" d="M2 3a2 2 0 00-2 2v3a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2zm6 1.5a2 2 0 110 4 2 2 0 010-4M12.5 7a.5.5 0 110-1 .5.5 0 010 1"/>',
    },
});
