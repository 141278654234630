/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'building-fill-gear': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 1a1 1 0 011-1h10a1 1 0 011 1v7.256A4.5 4.5 0 0012.5 8a4.5 4.5 0 00-3.59 1.787A.5.5 0 009 9.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.39-.187A4.5 4.5 0 008.027 12H6.5a.5.5 0 00-.5.5V16H3a1 1 0 01-1-1zm2 1.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5m3 0v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5m3.5-.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM4 5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5M7.5 5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm2.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5M4.5 8a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z"/><path pid="1" d="M11.886 9.46c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 00.921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 00.382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 00-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 00-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 00-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 00-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 00.382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 00.92-.382zM14 12.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0"/>',
    },
});
