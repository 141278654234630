/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-standing-dress': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 3a1.5 1.5 0 100-3 1.5 1.5 0 000 3m-.5 12.25V12h1v3.25a.75.75 0 001.5 0V12h1l-1-5v-.215a.285.285 0 01.56-.078l.793 2.777a.711.711 0 101.364-.405l-1.065-3.461A3 3 0 008.784 3.5H7.216a3 3 0 00-2.868 2.118L3.283 9.079a.711.711 0 101.365.405l.793-2.777a.285.285 0 01.56.078V7l-1 5h1v3.25a.75.75 0 001.5 0z"/>',
    },
});
