/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-lock': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11 5a3 3 0 11-6 0 3 3 0 016 0M8 7a2 2 0 100-4 2 2 0 000 4m0 5.996V14H3s-1 0-1-1 1-4 6-4q.845.002 1.544.107a4.5 4.5 0 00-.803.918A11 11 0 008 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664zM9 13a1 1 0 011-1v-1a2 2 0 114 0v1a1 1 0 011 1v2a1 1 0 01-1 1h-4a1 1 0 01-1-1zm3-3a1 1 0 00-1 1v1h2v-1a1 1 0 00-1-1"/>',
    },
});
