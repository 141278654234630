/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-x': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.854 7.146a.5.5 0 10-.708.708L7.293 9l-1.147 1.146a.5.5 0 00.708.708L8 9.707l1.146 1.147a.5.5 0 00.708-.708L8.707 9l1.147-1.146a.5.5 0 00-.708-.708L8 8.293z"/><path pid="1" d="M14 14V4.5L9.5 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2M9.5 3A1.5 1.5 0 0011 4.5h2V14a1 1 0 01-1 1H4a1 1 0 01-1-1V2a1 1 0 011-1h5.5z"/>',
    },
});
