/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'toggle-off': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11 4a4 4 0 010 8H8a5 5 0 002-4 5 5 0 00-2-4zm-6 8a4 4 0 110-8 4 4 0 010 8M0 8a5 5 0 005 5h6a5 5 0 000-10H5a5 5 0 00-5 5"/>',
    },
});
