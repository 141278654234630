/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    bank: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 0l6.61 3h.89a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H15v7a.5.5 0 01.485.38l.5 2a.498.498 0 01-.485.62H.5a.498.498 0 01-.485-.62l.5-2A.5.5 0 011 13V6H.5a.5.5 0 01-.5-.5v-2A.5.5 0 01.5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72z"/>',
    },
});
