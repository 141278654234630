/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'exclamation-square-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2zm6 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 01-1.1 0L7.1 4.995A.905.905 0 018 4m.002 6a1 1 0 110 2 1 1 0 010-2"/>',
    },
});
