/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'clipboard-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M10 1.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h3a.5.5 0 00.5-.5zm-5 0A1.5 1.5 0 016.5 0h3A1.5 1.5 0 0111 1.5v1A1.5 1.5 0 019.5 4h-3A1.5 1.5 0 015 2.5zm-2 0h1v1A2.5 2.5 0 006.5 5h3A2.5 2.5 0 0012 2.5v-1h1a2 2 0 012 2V14a2 2 0 01-2 2H3a2 2 0 01-2-2V3.5a2 2 0 012-2"/>',
    },
});
