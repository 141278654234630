/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'brightness-low-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12 8a4 4 0 11-8 0 4 4 0 018 0M8.5 2.5a.5.5 0 11-1 0 .5.5 0 011 0m0 11a.5.5 0 11-1 0 .5.5 0 011 0m5-5a.5.5 0 110-1 .5.5 0 010 1m-11 0a.5.5 0 110-1 .5.5 0 010 1m9.743-4.036a.5.5 0 11-.707-.707.5.5 0 01.707.707m-7.779 7.779a.5.5 0 11-.707-.707.5.5 0 01.707.707m7.072 0a.5.5 0 11.707-.707.5.5 0 01-.707.707M3.757 4.464a.5.5 0 11.707-.707.5.5 0 01-.707.707"/>',
    },
});
